//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//services
import {authService} from "../../../services/common/auth.service";

//actions
import {setUser} from "../user";

//types
import {ILogin} from "../../../services/types";

//const
import {cookieToken} from "../../../constants";

//helpers
import {deleteCookie} from "../../../helpers/utils";

//actions
import {getCsrfToken, getPermission} from "../configuration";
import { gaEvents } from "../../../helpers/gaEvents";

export type authSliceState = {
  data: any | null,
  error: any | null,
  isFetching: boolean,
};

const initialState: authSliceState = {
  data: null,
  error: null,
  isFetching: false,
};

export const loginUser: any = createAsyncThunk(
  'auth/loginUser',
  async (payload: ILogin, { rejectWithValue, dispatch }) => {
    try {
      const response = await authService.login(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      if (data.login === 'success' && data.hasOwnProperty('two_factor_complete') && !data.two_factor_complete) {
        return data;
      } else {
        localStorage.setItem('user', JSON.stringify(data));

        gaEvents.setLogin();
        gaEvents.setUserProperty({
          userId: data.user.id,
          platform: data.user.platform.name,
        });

        dispatch(setUser(data));
        dispatch(getPermission());

        /*if (data.user.namespace === 'admin') {
          //window.location.href = adminRedirectUrl;
          localStorage.setItem('user', JSON.stringify(data));
          dispatch(setUser(data));
        } else {
          localStorage.setItem('user', JSON.stringify(data));
          dispatch(setUser(data));
        }*/

      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const loginWithEmailData: any = createAsyncThunk(
  'auth/loginWithEmailData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await authService.loginWithEmail(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      localStorage.setItem('user', JSON.stringify(data));

      gaEvents.setLogin();
      gaEvents.setUserProperty({
        userId: data.user.id,
        platform: data.user.platform.name,
      });

      dispatch(setUser(data));
      dispatch(getPermission());

      /*if (data.user.namespace === 'admin') {
        //window.location.href = adminRedirectUrl;
        localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUser(data));
      } else {
        localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUser(data));
      }*/

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const logoutUser: any = createAsyncThunk(
  'auth/logoutUser',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await authService.logout();

      if (!response.ok) {
        return rejectWithValue({error: 'Logout error'})
      }

      localStorage.removeItem('user');
      localStorage.removeItem('adm_creatives_filter');
      localStorage.removeItem('adm_creatives_order');
      localStorage.removeItem('adm_creatives_page');
      localStorage.removeItem('adm_creatives_rows');
      dispatch(getCsrfToken());
      //deleteCookie(cookieToken);
      dispatch(setUser(null));
      gaEvents.setUserId(null);
      gaEvents.setLogout();

      return {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const resendConfirmEmailPasswordData: any = createAsyncThunk(
  'auth/resendConfirmEmailPasswordData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await authService.resendEmailCode(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


//slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true
    },
    stopFetching(state) {
      state.isFetching = false
    },
    setFetchingError(state, action) {
      state.error = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<authSliceState>>) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isFetching = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.data = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    });

    builder.addCase(logoutUser.pending, (state) => {
      state.isFetching = true;
      state.error = null;
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.isFetching = false;
      state.error = null;
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    });

    builder.addCase(loginWithEmailData.pending, (state) => {
      state.isFetching = true;
      state.error = null;
    });
    builder.addCase(loginWithEmailData.fulfilled, (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.data = action.payload;
    });
    builder.addCase(loginWithEmailData.rejected, (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    });
  },
});

export default authSlice.reducer;

export const {
  startFetching,
  stopFetching,
  setFetchingError,
} = authSlice.actions;
