import React, {useCallback, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { endOfDay, startOfDay } from "date-fns";
import arePropsEqual from "react-fast-compare";
import {
  Box,
  Button,
  SelectChangeEvent, TextField,
  useMediaQuery,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//utils
import { getDataIds, getGroupByOptions, getPeriod, PeriodTypes } from "../../../../../utils";

//selectors
import { selectOptionsData } from "../../../../../../../../store/admin/reports/creative_report/selectors";

//hooks
import { useCreativeReport } from "../../../../../../../../store/admin/reports/creative_report/useCreativeReport";
import { useRegistration } from "../../../../../../../../store/common/registration/useRegistration";
import { usePermissions } from "../../../../../../../../store/common/configuration/usePermissions";

//context
import { GlobalContext } from "../../../../../../../../context/GlobalContext";

//constants
import { summaryPeriodOptions } from "../../../../../constant";
import { favoriteFilterKeys, reportStorageProps } from "../../../../constants";

//validation
import { validationTextField as validationTextFieldMax } from "../../../../../../../validation/textFieldMax.validation";

//components
import { IFilterData } from "../../../../types";
import MuiAutocompleteSelect from "../../../../../../../UI/Fields/MuiAutocompleteSelect";
import CSelect from "../../../../../../../UI/Fields/CSelect";
import MuiAutocompleteSelectLazy from "../../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import FavoriteCheckboxField from "../../../../../../../UI/Fields/FavoriteCheckboxField";
import GroupByField, { IItemOption } from "../../../GroupByField";
import CDateRangePicker from "../../../../../../../UI/Fields/CDateRangePicker";
import SaveFilterForm from "../../../../../../../common/FiltersComponents/SaveFilterForm";
//import {initialSummaryReportFilterData} from "../../../../../SummaryReportV2/components/DesktopReport";
import { initialFilterData } from "../../../DesktopReport";



interface IFilterProps {
  initialFilter: IFilterData;
  activeField: string;
  setUpdateFilter: (data: any) => void;
  setToggleDrawer: (data: boolean) => void;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  filterName?: string;
  onSave?: (data: object) => void;
  setSelectedFilterId?: React.Dispatch<React.SetStateAction<string>>;
}


const Filter: React.FC<IFilterProps> = ({
  initialFilter,
  activeField,
  setUpdateFilter,
  setToggleDrawer,
  setGenerateReport,
  editMode = false,
  filterName = '',
  onSave,
  setSelectedFilterId,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');
  const { hasPermissions } = usePermissions();

  const hasMyFilters = hasPermissions(["api3.admin.creativeclickreportnewcontroller.my_filters"]);

  const context = useContext(GlobalContext);

  const {
    getAffiliates,
    getTrackers,
    getDeals,
    getCreatives,
  } = useCreativeReport();
  const { countries } = useRegistration();

  const optionsData = useSelector(selectOptionsData);

  const [filter, setFilter] = useState<IFilterData>(editMode ? initialFilter : context.admCreativesReportFilter);
  const [fieldName, setFieldName] = useState<string>(filterName);

  //state
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTypes>(initialFilter.period);
  const [startDate, setStartDate] = useState<Date | ''>(new Date(initialFilter.sent_from));
  const [endDate, setEndDate] = useState<Date | ''>(new Date(initialFilter.sent_to));
  const [groupBy, setGroupBy] = useState<IItemOption[]>(context.admCreativesReportFilter.group_by.fields);
  const [dataCountries, setDataCountries] = useState<any | null>(null);

  //favorite fields state
  const [favCRAffiliateIds, setFavCRAffiliateIds] = useLocalStorage<boolean>(favoriteFilterKeys.affiliateIds, false);
  const [favCRDealTypes, setFavCRDealTypes] = useLocalStorage<boolean>(favoriteFilterKeys.dealTypes, false);
  const [favCRTrackerIds, setFavCRTrackerIds] = useLocalStorage<boolean>(favoriteFilterKeys.trackerIds, false);
  const [favCRCreativeIds, setFavCRCreativeIds] = useLocalStorage<boolean>(favoriteFilterKeys.creativeIds, false);
  const [favCRGeo, setFavCRGeo] = useLocalStorage<boolean>(favoriteFilterKeys.geo, false);

  useEffect(() => {
    if (!!selectedPeriod) {
      const period = getPeriod(selectedPeriod);

      if (!!period.start && !!period.end) {
        setStartDate(period.start);
        setEndDate(period.end);
        setFilter((prevState: any) => {
          return {
            ...prevState,
            period: selectedPeriod,
            sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
            sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
          }
        })
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    setSelectedPeriod(filter.period);
  }, [filter.period]);

  useEffect(() => {
    context.setAdmCreativesReportFilter(filter);
  }, [filter]);

  useEffect(() => {
    if (!!countries.data) {
      const data = countries.data.reduce((acc: any[], item: any) => {
        acc.push({
          id: item.code,
          value: t(`common.components.creative.list_geolocales.${item.code}`),
        });
        return acc;
      }, []);
      setDataCountries({
        list: data,
        total_entries: data.length,
      });
    }
  }, [countries]);

  useEffect(() => {
    setFilter((prevState) => {
      return {
        ...prevState,
        group_by: {
          fields: groupBy,
        },
      }
    })
  }, [groupBy]);

  const handleChangeDates = (start: Date, end: Date) => {
    const noEndDate = !end;

    setStartDate(start);
    setEndDate(noEndDate ? start : end);
    setFilter((prevState: any) => {
      return {
        ...prevState,
        period: '',
        sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
        sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
      }
    });
  };

  const handleChangePeriodSelect = (event: SelectChangeEvent) => {
    //@ts-ignore
    const value: PeriodTypes = event.target.value;
    setSelectedPeriod(value);
  };

  const handleApplyFilter = useCallback(() => {
    setUpdateFilter(filter);
    setToggleDrawer(false);

    if (!editMode) {
      if (typeof setSelectedFilterId === 'function') {
        setSelectedFilterId('');
      }
      setTimeout(() => {
        setGenerateReport((prevState) => {
          return !prevState
        });
      }, 120);
    }
  }, [filter]);

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldTrackerIdsPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      affiliateIds: !!filter.affiliateIds.length ? getDataIds(filter.affiliateIds) : null,
    }
  };

  const validateName = validationTextFieldMax(fieldName, 255, {
    required: t("common.validation.required_field"),
    maxLength: t("common.validation.max_length"),
  });

  const handleSave = useCallback(() => {
    if (typeof onSave === 'function') {
      onSave({
        name: fieldName,
        filter: filter,
      })
    }
  }, [filter, fieldName]);

  return (<>
    {!!dataCountries && !!optionsData && (<>
      <Box className={styles.wrapper}>
        <Scrollbars
          className={styles.customScroll}
          style={{ overflow: "hidden" }}
          hideTracksWhenNotNeeded={true}
          renderView={props => <div {...props} className="view"/>}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        >
          {editMode && (
            <Box className={styles.box}>
              <TextField
                className={styles.fieldFull}
                size="small"
                id="filter_name"
                name="filter_name"
                type="text"
                label={t(`common.forms.fields.filter_name`)}
                value={fieldName}
                error={!!validateName}
                helperText={!!validateName ? validateName : ''}
                onChange={(event) => {
                  setFieldName(event.target.value);
                }}
              />
            </Box>
          )}

          {(<>
            {mobile && (<>
              <Box className={styles.box}>
                <CSelect
                  name="period"
                  label={t("admin.reports.filter.period")}
                  value={selectedPeriod}
                  options={summaryPeriodOptions}
                  onChange={handleChangePeriodSelect}
                  prefix="common.components.periods."
                />
              </Box>

              <Box className={`${styles.box} noMargin`}>
                <CDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleChangeDates}
                />
              </Box>

              <Box className={`${styles.box} noMargin`}>
                <GroupByField
                  name="groupBy"
                  value={groupBy}
                  options={getGroupByOptions(optionsData.group_by)}
                  setFilter={setGroupBy}
                  prefix={"admin.reports.filter.group_by_select."}
                />
              </Box>
            </>)}

            <Box className={styles.box}>
              <Box className={styles.fieldWrapper}>
                {!editMode && !mobile && (
                  <Box className={styles.fieldFavorite}>
                    <FavoriteCheckboxField
                      checked={favCRAffiliateIds}
                      setChecked={setFavCRAffiliateIds}
                      fieldName={t(`admin.reports.filter.aff_id`)}
                    />
                  </Box>
                )}
                <Box className={styles.field}>
                  <MuiAutocompleteSelectLazy
                    keyValue="value"
                    keyId="id"
                    id="affiliateIds"
                    name="affiliateIds"
                    label={t(`admin.reports.filter.aff_id`)}
                    value={filter.affiliateIds}
                    multiple={true}
                    showSelectAll={false}
                    disabled={false}
                    getOptions={getAffiliates}
                    payloadData={fieldPayload()}
                    labelWithId={false}
                    onChange={setFilter}
                    disableCloseOnSelect={true}
                    autoFocus={activeField === 'affiliateIds'}
                    showLabelCounter={true}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={styles.box}>
              <Box className={styles.fieldWrapper}>
                {!editMode && !mobile && (
                  <Box className={styles.fieldFavorite}>
                    <FavoriteCheckboxField
                      checked={favCRDealTypes}
                      setChecked={setFavCRDealTypes}
                      fieldName={t(`admin.reports.filter.deal_type`)}
                    />
                  </Box>
                )}
                <Box className={styles.field}>
                  <MuiAutocompleteSelectLazy
                    keyValue="value"
                    keyId="id"
                    id="dealTypes"
                    name="dealTypes"
                    label={t(`admin.reports.filter.deal_type`)}
                    value={filter.dealTypes}
                    multiple={true}
                    showSelectAll={false}
                    disabled={false}
                    getOptions={getDeals}
                    payloadData={fieldPayload()}
                    labelWithId={false}
                    onChange={setFilter}
                    disableCloseOnSelect={true}
                    autoFocus={activeField === 'dealTypes'}
                    showLabelCounter={true}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={styles.box}>
              <Box className={styles.fieldWrapper}>
                {!editMode && !mobile && (
                  <Box className={styles.fieldFavorite}>
                    <FavoriteCheckboxField
                      checked={favCRTrackerIds}
                      setChecked={setFavCRTrackerIds}
                      fieldName={t(`admin.reports.filter.tracker_id`)}
                    />
                  </Box>
                )}
                <Box className={styles.field}>
                  <MuiAutocompleteSelectLazy
                    keyValue="value"
                    keyId="id"
                    id="trackerIds"
                    name="trackerIds"
                    label={t(`admin.reports.filter.tracker_id`)}
                    value={filter.trackerIds}
                    multiple={true}
                    showSelectAll={false}
                    disabled={false}
                    getOptions={getTrackers}
                    payloadData={fieldTrackerIdsPayload()}
                    labelWithId={false}
                    onChange={setFilter}
                    disableCloseOnSelect={true}
                    autoFocus={activeField === 'trackerIds'}
                    showLabelCounter={true}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={styles.box}>
              <Box className={styles.fieldWrapper}>
                {!editMode && !mobile && (
                  <Box className={styles.fieldFavorite}>
                    <FavoriteCheckboxField
                      checked={favCRCreativeIds}
                      setChecked={setFavCRCreativeIds}
                      fieldName={t(`admin.reports.filter.creative_id`)}
                    />
                  </Box>
                )}
                <Box className={styles.field}>
                  <MuiAutocompleteSelectLazy
                    keyValue="value"
                    keyId="id"
                    id="creativeIds"
                    name="creativeIds"
                    label={t(`admin.reports.filter.creative_id`)}
                    value={filter.creativeIds}
                    multiple={true}
                    showSelectAll={false}
                    disabled={false}
                    getOptions={getCreatives}
                    payloadData={fieldPayload()}
                    labelWithId={false}
                    onChange={setFilter}
                    disableCloseOnSelect={true}
                    autoFocus={activeField === 'creativeIds'}
                    showLabelCounter={true}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={styles.box}>
              <Box className={styles.fieldWrapper}>
                {!editMode && !mobile && (
                  <Box className={styles.fieldFavorite}>
                    <FavoriteCheckboxField
                      checked={favCRGeo}
                      setChecked={setFavCRGeo}
                      fieldName={t(`admin.reports.filter.geo`)}
                    />
                  </Box>
                )}
                <Box className={styles.field}>
                  <MuiAutocompleteSelect
                    keyValue="value"
                    keyId="id"
                    id="geo"
                    name="geo"
                    label={t(`admin.reports.filter.geo`)}
                    value={filter.geo}
                    multiple={true}
                    showSelectAll={false}
                    disabled={false}
                    staticOptions={dataCountries.list}
                    onChange={setFilter}
                    disableCloseOnSelect={true}
                    autoFocus={activeField === 'geo'}
                    showLabelCounter={true}
                  />
                </Box>
              </Box>
            </Box>

            {!editMode && hasMyFilters && (
              <SaveFilterForm
                filter={filter}
                type={reportStorageProps.type}
                target={reportStorageProps.target}
                exceptions={reportStorageProps.exceptions}
                exceptionsIds={reportStorageProps.exceptionsIds}
                disabled={
                  arePropsEqual(
                    {
                      ...initialFilterData,
                      sent_from: format(new Date(initialFilterData.sent_from), "yyyy-MM-dd HH:mm:ss"),
                      sent_to: format(new Date(initialFilterData.sent_to), "yyyy-MM-dd HH:mm:ss"),
                    },
                    {
                      ...filter,
                      sent_from: format(new Date(filter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                      sent_to: format(new Date(filter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                    }
                  )
                }
              />
            )}
          </>)}
        </Scrollbars>
      </Box>
      <Box className={styles.actions}>
        <Box className={styles.actionsWrapper}>
          {editMode ? (
            <Button
              className={styles.button}
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              onClick={handleSave}
              disabled={
                arePropsEqual({
                  name: filterName,
                  ...initialFilter,
                  sent_from: format(new Date(initialFilter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                  sent_to: format(new Date(initialFilter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                }, {
                  name: fieldName,
                  ...filter,
                  sent_from: format(new Date(filter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                  sent_to: format(new Date(filter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                }) || !!validateName
              }
            >
              {t("common.buttons.save")}
            </Button>
          ) : (
            <Button
              className={styles.button}
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              onClick={handleApplyFilter}
              disabled={arePropsEqual(initialFilter, filter)}
            >
              {t("common.buttons.apply")}
            </Button>
          )}

          <Button
            className={styles.button}
            color="secondary"
            disableElevation
            type="button"
            variant="outlined"
            onClick={() => setToggleDrawer(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </Box>
    </>)}
  </>);
};

export default Filter;
