import React from "react";
import { useTranslation } from "react-i18next";

//components
import DateTimeRange from "../../../../../../../../../../../common/TableData/components/DateTimeRange";

interface IPaymentEditedProps {
  data: any;
}

const PaymentEdited: React.FC<IPaymentEditedProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();

  const first = <>
    {t("admin.reports.detailed_fin_report.logs.actions.payment.edited.first")} <DateTimeRange value={data.paymentDate} fullDate />{`, `}{t("admin.reports.detailed_fin_report.logs.actions.payment.amount")}{`: `}{data.amount} {t("admin.reports.detailed_fin_report.logs.actions.payment.edited.second")}
  </>;

  const date = data.from.paymentDate !== data.to.paymentDate && <>
    {t("admin.reports.detailed_fin_report.logs.actions.payment.date_from")} <DateTimeRange value={data.from.paymentDate} fullDate /> {t("admin.reports.detailed_fin_report.logs.actions.payment.to")} <DateTimeRange value={data.to.paymentDate} fullDate />
  </>;

  const amount = data.from.amount !== data.to.amount && `${t("admin.reports.detailed_fin_report.logs.actions.payment.amount_from")
    .replace('{from}', `${data.from.amount}`)
    .replace('{to}', `${data.to.amount}`)}`;

  const comment = data.from.comment !== data.to.comment && `${t("admin.reports.detailed_fin_report.logs.actions.payment.comment_from")
    .replace('{from}', `${data.from.comment !== null ? data.from.comment : '-'}`)
    .replace('{to}', `${data.to.comment !== null ? data.to.comment : '-'}`)}`;

  return (
    <>
      {first} {!!date ? date : ''}{!!date && (!!amount || !!comment) ? ', ' : ''}{!!amount ? amount : ''}{!!amount && !!comment ? ', ' : ''}{!!comment ? comment : ''}
    </>
  );
};

export default PaymentEdited;
