//images
import en from "./../assets/images/flags/gb.svg";
import r from "./../assets/images/flags/r.svg";
import ua from "./../assets/images/flags/ua.svg";

export interface ILanguageItem {
  lang: string;
  label: string;
  code: string;
  flag: any;
};

export const REACT_APP_LANGUAGES: string = process.env.REACT_APP_LANGUAGES || "[]";
export const availableBrandLanguages = JSON.parse(REACT_APP_LANGUAGES);

export const languages: ILanguageItem[] = [
  {
    lang: 'en',
    label: 'English',
    code: 'en_US',
    flag: en,
  },
  {
    lang: 'uk',
    label: 'Ukrainian',
    code: 'uk_UA',
    flag: ua,
  },
  {
    lang: 'ru',
    label: 'Russian',
    code: 'ru_RU',
    flag: r,
  },
];

export const availableLanguages = languages.reduce((acc: ILanguageItem[], item: ILanguageItem) => {
  if (availableBrandLanguages.includes(item.lang)) {
    acc.push(item);
  }
  return acc;
}, []);
