import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

const getTrackers = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.trackers.list : api.affiliate.trackers.list;

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackerById = (id: string) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.trackers.details : api.affiliate.trackers.details;

  const url = `${endpoint}/${id}/show_condition${isSupervision ? '?supervision=true' : ''}`;

  return fetch(
    `${url}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateTrackerName = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.trackers.editName : api.affiliate.trackers.editName;

  const url = `${endpoint}${data.tracker.id}`;

  const payload = {
    ...data,
    ...(isSupervision ? { supervision: true } : {}),
  };

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createTracker = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.trackers.create : api.affiliate.trackers.create;

  const payload = {
    ...data,
    ...(isSupervision ? { supervision: true } : {}),
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getDefaultCondition = () => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.trackers.defaultCondition : api.affiliate.trackers.defaultCondition;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const archiveTracker = (id: string | number) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.trackers.archive : api.affiliate.trackers.archive;

  const url = endpoint.replace('{trackerId}', id.toString());

  const payload = {
    authenticity_token: authenticity_token,
    ...(isSupervision ? { supervision: true } : {}),
  }

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const unArchiveTracker = (id: string | number) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.trackers.unarchive : api.affiliate.trackers.unarchive;

  const url = endpoint.replace('{trackerId}', id.toString());

  const payload = {
    authenticity_token: authenticity_token,
    ...(isSupervision ? { supervision: true } : {}),
  }

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackingLinksFilter = () => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.trackers.trackingLinks.filter
    : api.affiliate.trackers.trackingLinks.filter;

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackerCreativesList = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.trackers.trackingLinks.list
    : api.affiliate.trackers.trackingLinks.list;

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackerCreativesMyList = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.trackers.trackingLinks.myList
    : api.affiliate.trackers.trackingLinks.myList;

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCreateTrackingLink = () => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.trackers.trackingLinks.show_create
    : api.affiliate.trackers.trackingLinks.show_create;

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getEditTrackingLink = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.trackers.trackingLinks.editTrackingLink.replace('{id}', data.id)
    : api.affiliate.trackers.trackingLinks.editTrackingLink.replace('{id}', data.id);

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteTrackingLink = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.trackers.trackingLinks.deleteTrackingLink.replace('{id}', data.id)
    : api.affiliate.trackers.trackingLinks.deleteTrackingLink.replace('{id}', data.id);

  const authenticity_token = getCookie(cookieToken);

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('DELETE', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const saveTrackingLink = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.trackers.trackingLinks.saveTrackingLinks
    : api.affiliate.trackers.trackingLinks.saveTrackingLinks;

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const trackersService = {
  getTrackers,
  getTrackerById,
  updateTrackerName,
  createTracker,
  getDefaultCondition,
  archiveTracker,
  unArchiveTracker,
  getTrackingLinksFilter,
  getTrackerCreativesList,
  getTrackerCreativesMyList,
  getCreateTrackingLink,
  getEditTrackingLink,
  saveTrackingLink,
  deleteTrackingLink,
};
