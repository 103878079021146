export const favoriteFilterKeys = {
  productIds: 'favAffSRProductIds',
  platformIds: 'favAffSRPlatformIds',
  managerIds: 'favAffSRManagerIds',
  affiliateIds: 'favAffSRAffiliateIds',
  trackerIds: 'favAffSRTrackerIds',
  creativeIds: 'favAffSRCreativeIds',
  brands: 'favAffSRBrands',
  currencies: 'favAffSRCurrencies',
  dealTypes: 'favAffSRDealTypes',
  registrationDates: 'favAffSRRegDates',
  firstDepositDates: 'favAffSRFirstDepDates',
}

export const reportStorageProps = {
  type: 'player-and-commission-report',
  target: 'filters',
  tableSettingsTarget: 'table-settings',
  exceptions: ['group_by', 'period', 'sent_from', 'sent_to'],
  exceptionsIds: ['productIds', 'platformIds', 'managerIds', 'affiliateIds', 'trackerIds', 'brands', 'currencies', 'dealTypes'],
}
