//core
import React, {useEffect, useState, useCallback} from "react";
import arePropsEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import {TextField, FormControl, Box} from "@mui/material";
import ruLocale from "date-fns/locale/ru";
import ukLocale from "date-fns/locale/uk";
import enLocale from "date-fns/locale/en-US";

//styles
import { useStyles } from "./styles/styles";

//types
interface IMuiFieldProps {
  id?: string;
  name: string;
  autoComplete?: string;
  label: string;
  value?: any;
  secondValue?: any;
  maxDate?: any;
  minDate?: any;
  hideError?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  paperClass?: string;
  helperText?: string;
  dataId?: string;
  InputProps?: any;
  error?: boolean | string;
  validation?: (value: any) => any;
  onChange: (value: Date) => any;
  noMB?: boolean;
  extraSmall?: boolean;
  onCloseCallback?: () => void;
  views?: any;
  inputFormat?: string;
}

const localeMap = {
  en: enLocale,
  ru: ruLocale,
  uk: ukLocale,
};

const CSingleDatePicker = (props: IMuiFieldProps) => {
  const { i18n } = useTranslation();
  const styles = useStyles();

  const {
    id,
    name,
    autoComplete,
    label,
    value,
    secondValue,
    disabled = false,
    maxDate,
    minDate,
    error = '',
    hideError = false,
    className = '',
    paperClass = '',
    noMB = false,
    extraSmall = false,
    inputFormat = 'dd/MM/yyyy',
    onChange,
    onCloseCallback,
    views,
  } = props;

  const [open, setOpen] = useState(false);
  const [locale, setLocale] = useState<object>(localeMap.en);

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale(localeMap.ru);
    }

    if (i18n.language === 'en') {
      setLocale(localeMap.en);
    }

    if (i18n.language === 'uk') {
      setLocale(localeMap.uk);
    }

  }, [i18n.language]);

  const handleChange = useCallback(
    (newValue: Date | null) => {
      if (newValue !== null) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  return (
    <LocalizationProvider
      //@ts-ignore
      dateAdapter={AdapterDateFns} locale={locale}>
      <Box className={styles.formField} sx={{ marginBottom: noMB ? '0' : '24px' }}>
        <FormControl onClick={() => setOpen(true)} fullWidth>
          <DesktopDatePicker
            PaperProps={{
              classes: {
                root: paperClass,
              }
            }}
            className={className}
            open={disabled ? false : open}
            onOpen={() => setOpen(true)}
            onClose={() => {
              setOpen(false);
              if (typeof onCloseCallback === 'function') {
                onCloseCallback();
              }
            }}
            label={label}
            disabled={disabled}
            value={new Date(value)}
            inputFormat={inputFormat}
            {...(maxDate ? {maxDate: new Date(maxDate)} : {})}
            {...(minDate ? {minDate: new Date(minDate)} : {})}
            {...(views ? {views: views} : {})}
            // @ts-ignore
            onChange={handleChange}
            renderInput={(params) => <TextField
              id={id}
              name={name}
              className={`${styles.input} ${extraSmall && styles.small}`}
              autoComplete={autoComplete}
              size="small"
              InputProps={{
                readOnly: true,
                disabled: true,
              }}
              sx={{ pointerEvents: 'none' }}
              {...params}
              {...(hideError ? {error: false} : {})}
            />}
          />
          {/*{ meta.error && meta.error !== 'error' ? (
            <FormHelperText error>{meta.error}</FormHelperText>
          ) : null }*/}
        </FormControl>
      </Box>
    </LocalizationProvider>
  );
};

export default React.memo(CSingleDatePicker, arePropsEqual);
