//core
import React, {Dispatch, SetStateAction, useState} from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles/createTheme";
import { useTheme } from "@mui/styles";
import {
  Box,
  Typography,
  IconButton,
} from "@mui/material";

//icons
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//styles
import {
  useListItemStyles,
  ListItem,
} from "./styles";
import {getSymbolFromCode} from "currency-code-symbol-map";

interface ITableColumnItemProps {
  data: any;
  provided: DraggableProvided;
  setUpdateValue: Dispatch<SetStateAction<any>>;
  prefix: string;
  columnCurrency?: any;
}

const TableColumnItem: React.FC<ITableColumnItemProps> = ({
  data,
  provided,
  setUpdateValue,
  prefix,
  columnCurrency = null,
}): JSX.Element => {
  const theme: Theme = useTheme();
  const styles = useListItemStyles();
  const { t } = useTranslation();

  const { checked } = data;

  const handleChange = () => {
    setUpdateValue((prevState: any) => {
      return prevState.map((item: any) => {
        if (item.id === data.id) {
          return {
            ...item,
            checked: !checked,
          }
        } else {
          return item;
        }
      });
    })
  }

  return (
    <ListItem ref={provided.innerRef} {...provided.draggableProps} >
      <Box className={styles.group} {...provided.dragHandleProps}>
        <DragIndicatorIcon
          color={checked ? 'action' : 'disabled'}
          fontSize="small"
        />
        <Typography
          variant="subtitle1"
          sx={{ color: checked ? theme.palette.text.primary : theme.palette.text.disabled }}
        >
          {t(`${prefix}${data.value}`).replace('{currency}', (!!columnCurrency && !!columnCurrency.convert_currency && (!!getSymbolFromCode(columnCurrency.convert_currency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.convert_currency.id.toUpperCase()) : columnCurrency.convert_currency.id)) || '$')}
        </Typography>
      </Box>
      <IconButton
        className={styles.btn}
        size="small"
        color={checked ? 'primary' : 'secondary'}
        onClick={handleChange}
      >
        {checked
          ? <VisibilityIcon fontSize="small" />
          : <VisibilityOffIcon color="disabled" fontSize="small" />}
      </IconButton>
    </ListItem>
  );
};

export default TableColumnItem;
