//core
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import AdjustmentCRUD from "./view/AdjustmentAdded";
import AdjustmentEdited from "./view/AdjustmentEdited";
import DateTimeRange from "../../../../../../../../../../common/TableData/components/DateTimeRange";
import PaymentEdited from "./view/PaymentEdited";

interface ILogsItemProps {
  data: any;
  action: string;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit'
  }
});

const LogsItem = ({ data, action }: ILogsItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const adjustment_added = action === 'adjustment_added' &&
    `${t("admin.reports.detailed_fin_report.logs.actions.adjustment.adjustment_added")} ${!!data.reason ? `${t("admin.reports.detailed_fin_report.logs.actions.adjustment.reason")}: ${t(`admin.details_finance_report.adjustments.options.${data.reason}`)},` : ''} ${t("admin.reports.detailed_fin_report.logs.actions.adjustment.amount")}: ${data.amount}, ${t("admin.reports.detailed_fin_report.logs.actions.adjustment.comment")}: ${!!data.comment ? data.comment : '-'}, ${t("admin.reports.detailed_fin_report.logs.actions.adjustment.brand")}: ${!!data.brand ? data.brand: '-'}.`;

  const report_row_created_by_user = action === 'report_row_created_by_user' &&
    `${t("admin.reports.detailed_fin_report.logs.actions.report_row_created_by_user")
    .replace('{amount}', `${data.amount}`)}`;

  const report_row_status_changed = action === 'report_row_status_changed' &&
    `${t("admin.reports.detailed_fin_report.logs.actions.report_row_status_changed")
      .replace('{from}', `${t(`admin.details_finance_report.payment_statuses.${data.from.status}`)}`)
      .replace('{to}', `${t(`admin.details_finance_report.payment_statuses.${data.to.status}`)}`)}`

  const adjustment_removed = action === 'adjustment_removed' &&
    <AdjustmentCRUD data={data} label={t("admin.reports.detailed_fin_report.logs.actions.adjustment.adjustment_removed")} />;

  const adjustment_approved = action === 'adjustment_approved' &&
    <AdjustmentCRUD data={data} label={t("admin.reports.detailed_fin_report.logs.actions.adjustment.adjustment_approved")} />;

  const adjustment_edited = action === 'adjustment_edited' && <AdjustmentEdited data={data} />;

  const report_row_created = action === 'report_row_created' && `${t("admin.reports.detailed_fin_report.logs.actions.report_row_created")}`;

  const getPaymentMethodValue = (): string => {
    if (action === 'report_row_payment_method_data_changed') {
      const keys = Object.keys(data.to.paymentMethodValue);

      const val = keys.reduce((acc, item, i) => {
        acc = `${acc} ${item}: ${!!data.to.paymentMethodValue[item] ? data.to.paymentMethodValue[item] : ' - '}${i < keys.length-1 ? ', ' : ''}`

        return acc;
      }, '');

      return val;
    }

    return ''
  }
  const report_row_payment_method_data_changed = action === 'report_row_payment_method_data_changed' &&
    `${t("admin.reports.detailed_fin_report.logs.actions.wallet.changed")} ${t("admin.reports.detailed_fin_report.logs.actions.wallet.payment_method").replace('{method}', `${data.to.paymentMethodName}`)} ${t("admin.reports.detailed_fin_report.logs.actions.wallet.wallet_number")
      .replace('{number}', `${getPaymentMethodValue()}`)}`;

  const report_row_invoice_added = action === 'report_row_invoice_added' && `
    ${t("admin.reports.detailed_fin_report.logs.actions.invoice.uploaded")} ${t("admin.reports.detailed_fin_report.logs.actions.invoice.name").replace('{name}', `${data.invoiceFileName}`)}
  `;

  const report_row_invoice_deleted = action === 'report_row_invoice_deleted' && `
    ${t("admin.reports.detailed_fin_report.logs.actions.invoice.deleted")} ${t("admin.reports.detailed_fin_report.logs.actions.invoice.name").replace('{name}', `${data.invoiceFileName}`)}
  `;

  const report_row_comment_changed = action === 'report_row_comment_changed' && `
    ${t("admin.reports.detailed_fin_report.logs.actions.report_row_comment_changed")
    .replace('{from}', `${!!data.from && !!data.from.comment ? data.from.comment : '-'}`)
    .replace('{to}', `${!!data.to && !!data.to.comment ? data.to.comment : '-'}`)}
  `;

  const payment_added = action === 'payment_added' && <>
    {t("admin.reports.detailed_fin_report.logs.actions.payment.created")} {t("admin.reports.detailed_fin_report.logs.actions.payment.date")}: {<DateTimeRange value={data.paymentDate} fullDate />}, {t("admin.reports.detailed_fin_report.logs.actions.payment.amount")}: {data.amount}, {t("admin.reports.detailed_fin_report.logs.actions.payment.comment")}: {!!data.comment ? data.comment : '-'}
  </>;

  const payment_deleted = action === 'payment_deleted' && <>
    {t("admin.reports.detailed_fin_report.logs.actions.payment.deleted")} {t("admin.reports.detailed_fin_report.logs.actions.payment.date")}: {<DateTimeRange value={data.paymentDate} fullDate />}, {t("admin.reports.detailed_fin_report.logs.actions.payment.amount")}: {data.amount}, {t("admin.reports.detailed_fin_report.logs.actions.payment.comment")}: {!!data.comment ? data.comment : '-'}
  </>;

  const payment_edited = action === 'payment_edited' && <PaymentEdited data={data} />;

  const report_row_hold_updated = action === 'report_row_hold_updated' && `${t("admin.reports.detailed_fin_report.logs.actions.report_row_hold_updated")}`;

  return (
    <span className={styles.text}>
      { adjustment_added }
      { adjustment_edited }
      { adjustment_approved }
      { adjustment_removed }
      { payment_added }
      { payment_edited }
      { payment_deleted }
      { report_row_created }
      { report_row_created_by_user }
      { report_row_status_changed }
      { report_row_comment_changed }
      { report_row_payment_method_data_changed }
      { report_row_hold_updated }
      {/* invoice */}
      { report_row_invoice_added }
      { report_row_invoice_deleted }
    </span>
  );
};

export default LogsItem;
