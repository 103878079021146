//core
import React, {useState, useEffect, useContext} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, } from "react-redux";
import {
  Box,
  FormControlLabel,
  Radio,
  Collapse,
  IconButton,
  Tooltip,
  LinearProgress,
  useMediaQuery,
  Card,
} from "@mui/material";

//icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from "@mui/icons-material/Edit";

//styles
import { useStyles } from "./styles";

//hooks
import { setUser } from "../../../../../../store/common/user";
import { setGeneralSnackbarState } from "../../../../../../store/common/ui";

//service
import { storageService } from "../../../../../../services/common/storage.service";

//helpers
import { getCookie } from "../../../../../../helpers/utils";

//constants
import { cookieSupervision } from "../../../../../../constants";

//components
import SavedFieldsWrapper from "./components/SavedFieldsWrapper";
import { LabelsPayloadType, ListPayloadType } from "../../../../../../store/common/storage";
import CircularLoader from "../../../../../UI/CircularLoader";
import MuiDrawerWrapper from "../../../../../UI/MuiDrawerWrapper";
import {ThemeContext} from "../../../../../UI/MuiTheme/context";

interface ISavedFilterItemProps {
  data: any;
  selected: boolean;
  type: string;
  target: string;
  key?: string;
  getMyFilters: (payload: ListPayloadType) => void;
  getPayloadFilters: () => ListPayloadType;
  setSelectedMyFilter: React.Dispatch<React.SetStateAction<any | null>>;
  exceptions: string[];
  exceptionsIds: string[];
  fieldsLabelPrefix: string;
  EditComponent: any;
  defaultFilter: object;
  setSelectedMyFilterId: React.Dispatch<React.SetStateAction<string>>
  setSelectedFilterId: React.Dispatch<React.SetStateAction<string>>;
  fieldsPrefix?: any;
}

const SavedFilterItem: React.FC<ISavedFilterItemProps> = ({
  data,
  type,
  target,
  selected,
  exceptions,
  exceptionsIds,
  getMyFilters,
  getPayloadFilters,
  setSelectedMyFilter,
  fieldsLabelPrefix,
  EditComponent,
  defaultFilter,
  setSelectedMyFilterId,
  setSelectedFilterId,
  fieldsPrefix,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const mobile = useMediaQuery('(max-width: 767px)');
  const { darkMode } = useContext(ThemeContext);

  const dispatch = useDispatch();

  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [savedFieldsData, setSavedFieldsData] = useState<any | null>(null);

  //edit states
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [generateReport, setGenerateReport] = useState(false);
  const [filter, setFilter] = useState(null);

  const handleToggleShow = async () => {
    if (!show) {
      if (!savedFieldsData) {
        getActualFields();
      }
    }
    setShow(prevState => !prevState);
  };

  const handleSelectedFilter = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      if (!savedFieldsData) {
        getFilterById().then(data => {
          const filterData = {
            ...data,
            data: JSON.parse(data.data),
          };

          const labelsPayload = Object.keys(filterData.data).reduce((acc, item: string) => {
            if (Array.isArray(filterData.data[item])) {
              return {
                ...acc,
                [item]: filterData.data[item].map((item: any) => item.id),
              }
            } else {
              return acc;
            }
          }, {});

          getFilterLabels(labelsPayload).then((res) => {
            const actualFilterData = {
              ...filterData,
              data: {
                ...filterData.data,
                ...res,
              }
            }

            setSavedFieldsData(actualFilterData);
            setSelectedMyFilter(actualFilterData);
          });
        });
      } else {
        setSelectedMyFilter(savedFieldsData);
      }
    }
  };

  const handleDelete = async () => {
    try {
      const response = await storageService.remove({
        type: type,
        target: target,
        id: data.id,
      });
      const dataRes = await response.json();

      if (dataRes.hasOwnProperty('authorized') && dataRes.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        getMyFilters(getPayloadFilters());
        if (selected) {
          setSelectedFilterId('');
          setSelectedMyFilterId('');
        }
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: dataRes,
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdate = async (values: any) => {
    const payload = {
      type: type,
      target: target,
      id: data.id,
      payload: {
        name: values.name,
        data: JSON.stringify(getFilterDataToSave(values.filter)),
      }
    };

    try {
      const response = await storageService.update(payload);
      const dataRes = await response.json();

      if (dataRes.hasOwnProperty('authorized') && dataRes.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        getMyFilters(getPayloadFilters());
        if (selected) {
          setSelectedFilterId('');
          setSelectedMyFilterId('');
        }
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.updated',
          })
        );
        getActualFields(() => setOpenEditDrawer(false));
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: dataRes,
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleShowEdit = () => {
    if (!savedFieldsData) {
      getActualFields(() => setOpenEditDrawer(true))
    } else {
      setFilter({
        ...defaultFilter,
        ...savedFieldsData.data,
      });
      setOpenEditDrawer(true);
    }
  };

  const getFilterById = async () => {
    setLoading(true);
    try {
      const response = await storageService.getById({
        type: type,
        target: target,
        id: data.id,
      });

      setLoading(false);
      return response;
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getFilterLabels = async (filter: LabelsPayloadType) => {
    setLoading(true);
    try {
      const response = await storageService.getLabels({
        ...filter,
      });

      setLoading(false);
      return response;
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getActualFields = (callback?: () => void) => {
    getFilterById().then(data => {
      const filterData = {
        ...data,
        data: JSON.parse(data.data),
      };

      const labelsPayload = Object.keys(filterData.data).reduce((acc, item: string) => {
        if (Array.isArray(filterData.data[item])) {
          return {
            ...acc,
            [item]: filterData.data[item].map((item: any) => item.id),
          }
        } else {
          return acc;
        }
      }, {});

      getFilterLabels(labelsPayload).then((res) => {
        const actualFilterData = {
          ...filterData,
          data: {
            ...filterData.data,
            ...res,
          }
        }

        setSavedFieldsData(actualFilterData);
        setFilter({
          ...defaultFilter,
          ...filterData.data,
          ...res,
        });

        if (typeof callback === 'function') {
          callback();
        }
      });
    })
  };

  const getFilterDataToSave = (filter: any) => {
    return Object.keys(filter).reduce((acc: any, item: string) => {
      if (!exceptions.includes(item)) {
        //@ts-ignore
        if (exceptionsIds.includes(item)) {
          // get Ids
          acc[item] = filter[item];
        } else {
          acc[item] = filter[item];
        }
      }
      return acc;
    }, {})
  }

  return (
    <>
      <Box className={styles.block}>
        <FormControlLabel
          className={styles.radio}
          value={`${data.id}`}
          control={
            <Radio
              onChange={handleSelectedFilter}
            />
          }
          label=''
        />
        <Card elevation={5} className={`${styles.wrapper} ${darkMode ? 'dark' : 'light'}`}>
          <Box className={styles.header}>
            <Tooltip title={show ? t('common.buttons.hide_details') : t('common.buttons.view_details')}>
              <IconButton
                aria-label="collapse"
                className={styles.iconBtn}
                onClick={handleToggleShow}
              >
                {show
                  ? <KeyboardArrowUpIcon fontSize="small" />
                  : <KeyboardArrowDownIcon fontSize="small" />
                }
              </IconButton>
            </Tooltip>

            <Box className={`${styles.nameWrapper} ${isSupervision ? 'full' : ''}`}>
              <Box className={styles.fieldName}>
                <Box className={styles.name}>
                  {data.name}
                </Box>

                {!isSupervision && (
                  <Tooltip title={t('common.components.filters.edit_my_filters')}>
                    <IconButton
                      className={styles.iconBtn}
                      onClick={handleShowEdit}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>

            {!isSupervision && (<>
              <Tooltip title={t('common.buttons.delete_filter')}>
                <IconButton
                  className={styles.iconBtn}
                  onClick={handleDelete}
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>)}
          </Box>

          <Collapse in={show} collapsedSize={0}>
            <Box className={styles.collapseContent} sx={{ width: '100%' }}>
              {loading && <CircularLoader size={24} />}
              {!!savedFieldsData && (
                <SavedFieldsWrapper
                  data={savedFieldsData.data}
                  exceptions={exceptions}
                  fieldsLabelPrefix={fieldsLabelPrefix}
                  fieldsPrefix={fieldsPrefix}
                />
              )}
            </Box>
          </Collapse>

          {!show && loading && <LinearProgress className={styles.linearLoader} />}
        </Card>
      </Box>

      <MuiDrawerWrapper
        title={t("common.components.filters.edit_my_filters")}
        open={openEditDrawer}
        toggleDrawerState={setOpenEditDrawer}
      >
        {!!filter ? (
          <EditComponent
            editMode={true}
            activeField={''}
            initialFilter={filter}
            setUpdateFilter={setFilter}
            setToggleDrawer={setOpenEditDrawer}
            setGenerateReport={setGenerateReport}
            filterName={data.name}
            onSave={handleUpdate}
          />
        ) : (
          <CircularLoader size={24} />
        )}
      </MuiDrawerWrapper>
    </>
  );
};

export default SavedFilterItem;
