//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";

//CreateUpdate Communication
const createCommunicationStatusDataSelector = (state: RootState) => state.admin.affiliateProfile.communicationCreate.data;
const createCommunicationStatusErrorSelector = (state: RootState) => state.admin.affiliateProfile.communicationCreate.error;
const createCommunicationStatusFetchingSelector = (state: RootState) => state.admin.affiliateProfile.communicationCreate.isFetching;

export const selectCreateCommunicationStatusData = createSelector(createCommunicationStatusDataSelector, (data) => data);
export const selectCreateCommunicationStatusError = createSelector(createCommunicationStatusErrorSelector, (error) => error);
export const selectCreateCommunicationStatusIsFetching = createSelector(createCommunicationStatusFetchingSelector, (isFetching) => isFetching);

//Delete Communication
const communicationDeleteDataSelector = (state: RootState) => state.admin.affiliateProfile.communicationDelete.data;
const communicationDeleteErrorSelector = (state: RootState) => state.admin.affiliateProfile.communicationDelete.error;
const communicationDeleteFetchingSelector = (state: RootState) => state.admin.affiliateProfile.communicationDelete.isFetching;

export const selectCommunicationDeleteData = createSelector(communicationDeleteDataSelector, (data) => data);
export const selectCommunicationDeleteError = createSelector(communicationDeleteErrorSelector, (error) => error);
export const selectCommunicationDeleteIsFetching = createSelector(communicationDeleteFetchingSelector, (isFetching) => isFetching);

// Communication History
const communicationHistoryDataSelector = (state: RootState) => state.admin.affiliateProfile.communicationHistory.data;
const communicationHistoryErrorSelector = (state: RootState) => state.admin.affiliateProfile.communicationHistory.error;
const communicationHistoryFetchingSelector = (state: RootState) => state.admin.affiliateProfile.communicationHistory.isFetching;

export const selectCommunicationHistoryData = createSelector(communicationHistoryDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === 'communication_status') {
        return { ...item, type: 'communicationHistoryStatus' };
      }
      if (item.name === 'affiliate_id') {
        return { ...item, type: 'string' };
      }
      if (item.name === 'created_by') {
        return { ...item, type: 'createdBy' };
      }
      if (item.name === 'updated_by') {
        return { ...item, type: 'updatedBy' };
      }
      if (item.name === 'communication_date') {
        return { ...item, type: 'fulldatetime' };
      }
      if (item.name === 'notes') {
        return { ...item, type: 'notes' };
      }

      return item;
    });

    return { ...data, columns: columns };
  } else {
    return data;
  }
});
export const selectCommunicationHistoryError = createSelector(communicationHistoryErrorSelector, (error) => error);
export const selectCommunicationHistoryIsFetching = createSelector(communicationHistoryFetchingSelector, (isFetching) => isFetching);


//Delete Communication
const communicationUpdateDataSelector = (state: RootState) => state.admin.affiliateProfile.communicationUpdate.data;
const communicationUpdateErrorSelector = (state: RootState) => state.admin.affiliateProfile.communicationUpdate.error;
const communicationUpdateFetchingSelector = (state: RootState) => state.admin.affiliateProfile.communicationUpdate.isFetching;

export const selectCommunicationUpdateData = createSelector(communicationUpdateDataSelector, (data) => data);
export const selectCommunicationUpdateError = createSelector(communicationUpdateErrorSelector, (error) => error);
export const selectCommunicationUpdateIsFetching = createSelector(communicationUpdateFetchingSelector, (isFetching) => isFetching)


//Update traffic status
const trafficStatusUpdateDataSelector = (state: RootState) => state.admin.affiliateProfile.trafficStatusUpdate.data;
const trafficStatusUpdateErrorSelector = (state: RootState) => state.admin.affiliateProfile.trafficStatusUpdate.error;
const trafficStatusUpdateFetchingSelector = (state: RootState) => state.admin.affiliateProfile.trafficStatusUpdate.isFetching;

export const selectTrafficStatusUpdateData = createSelector(trafficStatusUpdateDataSelector, (data) => data);
export const selectTrafficStatusUpdateError = createSelector(trafficStatusUpdateErrorSelector, (error) => error);
export const selectTrafficStatusUpdateIsFetching = createSelector(trafficStatusUpdateFetchingSelector, (isFetching) => isFetching);


//Profile
const profileDataSelector = (state: RootState) => state.admin.affiliateProfile.profile.data;
const profileErrorSelector = (state: RootState) => state.admin.affiliateProfile.profile.error;
const profileFetchingSelector = (state: RootState) => state.admin.affiliateProfile.profile.isFetching;

export const selectProfileData = createSelector(profileDataSelector, (data) => data);
export const selectProfileError = createSelector(profileErrorSelector, (error) => error);
export const selectProfileIsFetching = createSelector(profileFetchingSelector, (isFetching) => isFetching);

//Update Profile
const updateProfileDataSelector = (state: RootState) => state.admin.affiliateProfile.updateProfile.data;
const updateProfileErrorSelector = (state: RootState) => state.admin.affiliateProfile.updateProfile.error;
const updateProfileFetchingSelector = (state: RootState) => state.admin.affiliateProfile.updateProfile.isFetching;

export const selectUpdateProfileData = createSelector(updateProfileDataSelector, (data) => data);
export const selectUpdateProfileError = createSelector(updateProfileErrorSelector, (error) => error);
export const selectUpdateProfileIsFetching = createSelector(updateProfileFetchingSelector, (isFetching) => isFetching);

//Update Chiefs
const updateChiefsDataSelector = (state: RootState) => state.admin.affiliateProfile.updateChiefs.data;
const updateChiefsErrorSelector = (state: RootState) => state.admin.affiliateProfile.updateChiefs.error;
const updateChiefsFetchingSelector = (state: RootState) => state.admin.affiliateProfile.updateChiefs.isFetching;

export const selectUpdateChiefsData = createSelector(updateChiefsDataSelector, (data) => data);
export const selectUpdateChiefsError = createSelector(updateChiefsErrorSelector, (error) => error);
export const selectUpdateChiefsIsFetching = createSelector(updateChiefsFetchingSelector, (isFetching) => isFetching);


//payment list
const paymentListDataSelector = (state: RootState) => state.admin.affiliateProfile.paymentList.data;
const paymentListErrorSelector = (state: RootState) => state.admin.affiliateProfile.paymentList.error;
const paymentListFetchingSelector = (state: RootState) => state.admin.affiliateProfile.paymentList.isFetching;
export const selectPaymentListData = createSelector(paymentListDataSelector, (data) => data);
export const selectPaymentListError = createSelector(paymentListErrorSelector, (error) => error);
export const selectPaymentListIsFetching = createSelector(paymentListFetchingSelector, (isFetching) => isFetching);


//payment Methods
const paymentMethodsDataSelector = (state: RootState) => state.admin.affiliateProfile.paymentMethods.data;
const paymentMethodsErrorSelector = (state: RootState) => state.admin.affiliateProfile.paymentMethods.error;
const paymentMethodsFetchingSelector = (state: RootState) => state.admin.affiliateProfile.paymentMethods.isFetching;
export const selectPaymentMethodsData = createSelector(paymentMethodsDataSelector, (data) => data);
export const selectPaymentMethodsError = createSelector(paymentMethodsErrorSelector, (error) => error);
export const selectPaymentMethodsIsFetching = createSelector(paymentMethodsFetchingSelector, (isFetching) => isFetching);


//Create payment Methods
const createPaymentMethodDataSelector = (state: RootState) => state.admin.affiliateProfile.createPaymentMethod.data;
const createPaymentMethodErrorSelector = (state: RootState) => state.admin.affiliateProfile.createPaymentMethod.error;
const createPaymentMethodFetchingSelector = (state: RootState) => state.admin.affiliateProfile.createPaymentMethod.isFetching;
export const selectCreatePaymentMethodData = createSelector(createPaymentMethodDataSelector, (data) => data);
export const selectCreatePaymentMethodError = createSelector(createPaymentMethodErrorSelector, (error) => error);
export const selectCreatePaymentMethodIsFetching = createSelector(createPaymentMethodFetchingSelector, (isFetching) => isFetching);


//Delete payment Methods
const deletePaymentMethodDataSelector = (state: RootState) => state.admin.affiliateProfile.deletePaymentMethod.data;
const deletePaymentMethodErrorSelector = (state: RootState) => state.admin.affiliateProfile.deletePaymentMethod.error;
const deletePaymentMethodFetchingSelector = (state: RootState) => state.admin.affiliateProfile.deletePaymentMethod.isFetching;
export const selectDeletePaymentMethodData = createSelector(deletePaymentMethodDataSelector, (data) => data);
export const selectDeletePaymentMethodError = createSelector(deletePaymentMethodErrorSelector, (error) => error);
export const selectDeletePaymentMethodIsFetching = createSelector(deletePaymentMethodFetchingSelector, (isFetching) => isFetching);


//Update payment Methods
const updatePaymentMethodDataSelector = (state: RootState) => state.admin.affiliateProfile.updatePaymentMethod.data;
const updatePaymentMethodErrorSelector = (state: RootState) => state.admin.affiliateProfile.updatePaymentMethod.error;
const updatePaymentMethodFetchingSelector = (state: RootState) => state.admin.affiliateProfile.updatePaymentMethod.isFetching;
export const selectUpdatePaymentMethodData = createSelector(updatePaymentMethodDataSelector, (data) => data);
export const selectUpdatePaymentMethodError = createSelector(updatePaymentMethodErrorSelector, (error) => error);
export const selectUpdatePaymentMethodIsFetching = createSelector(updatePaymentMethodFetchingSelector, (isFetching) => isFetching);


//payment history
const paymentHistoryDataSelector = (state: RootState) => state.admin.affiliateProfile.paymentHistory.data;
const paymentHistoryErrorSelector = (state: RootState) => state.admin.affiliateProfile.paymentHistory.error;
const paymentHistoryFetchingSelector = (state: RootState) => state.admin.affiliateProfile.paymentHistory.isFetching;
export const selectPaymentHistoryData = createSelector(paymentHistoryDataSelector, (data) => data);
export const selectPaymentHistoryError = createSelector(paymentHistoryErrorSelector, (error) => error);
export const selectPaymentHistoryIsFetching = createSelector(paymentHistoryFetchingSelector, (isFetching) => isFetching);
