//core
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/lab";
import { TextField, FormControl, FormHelperText } from "@mui/material";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

//styles
import styles from "./styles/styles.module.scss";

//types
interface IMuiFieldProps {
  id?: string;
  name: string;
  autoComplete?: string;
  label: string;
  value?: any;
  secondValue?: any;
  maxDate?: any,
  minDate?: any,
  //type?: React.InputHTMLAttributes<unknown>['type'];
  hideError?: boolean,
  placeholder?: string,
  disabled?: boolean,
  className?: string,
  helperText?: string,
  dataId?: string,
  InputProps?: any,
  error?: boolean | string,
  validation?: (value: any) => any,
  validate?: (value: any) => any,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  inputFormat?: string;
}

const localeMap = {
  en: enLocale,
  ru: ruLocale,
};

export const MuiSingleDateTimePicker = (props: IMuiFieldProps) => {
  const [field, meta] = useField(props);

  const { i18n } = useTranslation();

  const { setFieldValue } = useFormikContext();

  const {
    id,
    name,
    autoComplete,
    label,
    value,
    secondValue,
    disabled = false,
    maxDate,
    minDate,
    error = '',
    hideError = false,
    //views = [],
    inputFormat = 'dd/MM/yyyy HH:mm'
  } = props;


  const [fieldVal, setFieldVal] = React.useState<Date | null>(value);
  const [open, setOpen] = useState(false);
  const [locale, setLocale] = useState<object>(localeMap.en);

  useEffect(() => {
    setFieldVal(value)
  }, [value]);

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale(localeMap.ru);
    }

    if (i18n.language === 'en') {
      setLocale(localeMap.en);
    }

  }, [i18n.language]);

  useEffect(() => {
    if (fieldVal !== null) {
      if (name === 'at_end_show') {
        if (new Date(fieldVal) < new Date(secondValue)) {
          setFieldValue(field.name, secondValue);
          setFieldVal(secondValue);
        }
      }
      if (name === 'at_beginning_show') {
        if (new Date(fieldVal) > new Date(secondValue)) {
          setFieldValue('at_end_show', fieldVal);
          setFieldVal(fieldVal);
        }
      }
    }

  }, [fieldVal, minDate]);

  return (
    <LocalizationProvider
      //@ts-ignore
      dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <div className={styles.formField}>
        <FormControl fullWidth>
          <DesktopDateTimePicker
            views={["year", "day", "hours"]}
            ampm={false}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            label={label}
            hideTabs={true}
            disabled={disabled}
            //value={new Date(value)}
            value={fieldVal}
            inputFormat={i18n.language === 'en' ? 'MM/dd/yyyy HH:mm' : inputFormat}
            // @ts-ignore
            closeOnSelect={true}
            {...(maxDate ? {maxDate: new Date(maxDate)} : {})}
            {...(minDate ? {minDate: new Date(minDate)} : {})}
            // @ts-ignore
            onChange={(newValue) => {
              setFieldVal(newValue);
              setFieldValue(field.name, newValue);
            }}
            // @ts-ignore
            renderInput={(params) => <TextField
              {...field}
              id={id}
              name={name}
              className={`${styles.input} ${ !!(meta.touched && !!meta.error && meta.error !== 'error') ? styles.inputError : styles.inputErrorDef }`}
              autoComplete={autoComplete}
              size="small"
              onClick={() => setOpen(true)}
              onChange={() => {}}
              InputProps={{
                readOnly: true,
                // disabled: true,
              }}
              {...params}
              {...(hideError ? {error: false} : {})}
              {...( !!(meta.touched && meta.error) ? {color: 'error'} : {})}
            />}
          />
          { meta.touched && !!meta.error && meta.error !== 'error' ? (
            <FormHelperText error>{meta.error}</FormHelperText>
          ) : null }
        </FormControl>
      </div>
    </LocalizationProvider>
  );
};
