import {useDispatch} from "react-redux";

import {
  getOptionsData,
  getListData,
  setDownloadData,
  getAffiliatesData,
  getTrackersData,
  getDealsData,
  getCreativesData,
  getProductsData,
  getManagersData,
  getBrandsData,
  getCurrenciesData,
  getPlatformsData,
  getColumnsData,
  clearDownload,
  clearListState,
  clearColumnsState,
  clearAffiliatesState,
  clearTrackersState,
  reset,
} from "./index";

export const useSummaryReport = () => {
  const dispatch = useDispatch();

  const getOptions = () => dispatch(getOptionsData());
  const getColumns = () => dispatch(getColumnsData());
  const getList = (data: any) => dispatch(getListData(data));
  const getManagers = (data: any) => dispatch(getManagersData(data));
  const getAffiliates = (data: any) => dispatch(getAffiliatesData(data));
  const getProducts = (data: any) => dispatch(getProductsData(data));
  const getTrackers = (data: any) => dispatch(getTrackersData(data));
  const getCreatives = (data: any) => dispatch(getCreativesData(data));
  const getBrands = (data: any) => dispatch(getBrandsData(data));
  const getCurrencies = (data: any) => dispatch(getCurrenciesData(data));
  const getPlatforms = (data: any) => dispatch(getPlatformsData(data));
  const getDeals = () => dispatch(getDealsData());
  const setDownload = (data: any) => dispatch(setDownloadData(data));

  const clearListData = () => dispatch(clearListState());
  const resetState = () => dispatch(reset());
  const clearDownloadState = () => dispatch(clearDownload());
  const clearAffiliates = () => dispatch(clearAffiliatesState());
  const clearTrackers = () => dispatch(clearTrackersState());
  const clearColumns = () => dispatch(clearColumnsState());

  return {
    getOptions,
    getList,
    setDownload,
    clearDownloadState,
    clearListData,
    resetState,
    getAffiliates,
    clearAffiliates,
    getTrackers,
    clearTrackers,
    getDeals,
    getCreatives,
    getProducts,
    getManagers,
    getBrands,
    getCurrencies,
    getPlatforms,
    getColumns,
    clearColumns,
  }
}
