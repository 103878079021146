export const favoriteFilterKeys = {
  productIds: 'favAdmSRProductIds',
  platformIds: 'favAdmSRPlatformIds',
  managerIds: 'favAdmSRManagerIds',
  affiliateIds: 'favAdmSRAffiliateIds',
  trackerIds: 'favAdmSRTrackerIds',
  creativeIds: 'favAdmSRCreativeIds',
  brands: 'favAdmSRBrands',
  currencies: 'favAdmSRCurrencies',
  dealTypes: 'favAdmSRDealTypes',
  registrationDates: 'favAdmSRRegDates',
  firstDepositDates: 'favAdmSRFirstDepDates',
}

export const reportStorageProps = {
  type: 'player-and-commission-report',
  target: 'filters',
  tableSettingsTarget: 'table-settings',
  exceptions: ['group_by', 'period', 'sent_from', 'sent_to'],
  exceptionsIds: ['productIds', 'platformIds', 'managerIds', 'affiliateIds', 'trackerIds', 'brands', 'currencies', 'dealTypes'],
}
