//core
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Theme} from "@mui/material/styles/createTheme";
import {makeStyles, useTheme} from "@mui/styles";
import { Menu, MenuItem, Typography, IconButton, Tooltip, useMediaQuery } from "@mui/material";

//icon
import {
  Check as CheckIcon,
  SwapVert as SwapVertIcon,
  South as DescIcon,
  North as AscIcon,
} from "@mui/icons-material";

//types

type ColumnsItemData = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
};

type OrderType = {
  [key: string]: 'asc' | 'desc'
};

interface ISortMenuProps {
  order: OrderType,
  columns: ColumnsItemData[],
  prefix: string;
  setOrder: (order: OrderType) => void;
  noMargin?: boolean;
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    width: 42,
    height: 42,
    padding: 4,
    marginLeft: 4,
    position: 'relative',
    '@media (max-width: 767px)': {
      width: 32,
      height: 32,
      padding: 0,
    },
    '& svg': {
      width: 32,
      height: 32,
      '@media (max-width: 767px)': {
        width: 24,
        height: 24,
      }
    },
    '&.without-margin': {
      marginLeft: 0,
    }
  },
  menu: {
    //width: '288px',
  },
  menuItem: {
    whiteSpace: 'normal',
  },
}));

const SortMenu: React.FC<ISortMenuProps> = (props): JSX.Element => {
  const theme: Theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    order,
    columns,
    prefix,
    setOrder,
    color = 'secondary',
    noMargin = false,
  } = props;

  const mobile = useMediaQuery('(max-width:767px)');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  },[setAnchorEl]);

  const handleSelectedSorting = useCallback(
    (name: string, sort: 'asc' | 'desc') => {
      setAnchorEl(null);
      setOrder({ [name]: sort });
    },
    [setAnchorEl, setOrder],
  );

  return (
    <>
      <IconButton
        className={`${styles.button} ${noMargin ? 'without-margin' : ''} toggle-btn`}
        size={mobile ? 'small' : 'medium'}
        onClick={handleClick}
        color={color}
        id="sort-menu"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Tooltip title={t("common.buttons.sorting")} placement="bottom">
          <SwapVertIcon />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sort-menu',
        }}
        PaperProps={{
          style: {
            maxHeight: 36 * 6.5,
          },
        }}
        className={styles.menu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          columns.map((item) => {
            const key: string = Object.keys(order)[0];

            return <div key={item.name}>
              <MenuItem
                sx={{ minHeight: '36px' }}
                className={styles.menuItem}
                onClick={() => handleSelectedSorting(item.name, 'asc')}
                selected={key === item.name && order[key] === 'asc'}
              >
                {
                  (key === item.name && order[key] === 'asc')
                  && <CheckIcon sx={{ color: theme.palette.action.active, marginRight: 2 }} />
                }
                <Typography variant="body1">{t(`${prefix}${item.name}`)}</Typography>
                <AscIcon sx={{ marginLeft: 1, width: 12, height: 12 }} />
              </MenuItem>
              <MenuItem
                sx={{ minHeight: '36px' }}
                className={styles.menuItem}
                onClick={() => handleSelectedSorting(item.name, 'desc')}
                selected={key === item.name && order[key] === 'desc'}
              >
                {
                  (key === item.name && order[key] === 'desc')
                  && <CheckIcon sx={{ color: theme.palette.action.active, marginRight: 2 }} />
                }
                <Typography variant="body1">{t(`${prefix}${item.name}`)}</Typography>
                <DescIcon sx={{ marginLeft: 1, width: 12, height: 12 }} />
              </MenuItem>
            </div>
          })
        }
      </Menu>
    </>
  );
};

export default React.memo(SortMenu, arePropsEqual);

