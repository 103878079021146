import {createContext} from "react";

//types
import { itemListData } from "../../../../../common/TableData";

interface IDetailsContext {
  selectedRows: itemListData[],
  addSelectedRow: (data: itemListData) => void;
  setSelectedRows: (data: itemListData[]) => void;
  onGenerateCurrentState: () => void;
  showCreateUpdatePayout?: boolean;
  setShowCreateUpdatePayout?: any;
  dataCreateUpdatePayout?: any,
  setDataCreateUpdatePayout?: any;
  showCreateUpdateAdjustments?: boolean;
  setShowCreateUpdateAdjustments?: any;
  dataCreateUpdateAdjustments?: any,
  setDataCreateUpdateAdjustments?: any;
}

export const DetailsContext = createContext<IDetailsContext>({
  selectedRows: [],
  addSelectedRow: () => {},
  setSelectedRows: () => {},
  onGenerateCurrentState: () => {},
  showCreateUpdatePayout: false,
  setShowCreateUpdatePayout: () => {},
  dataCreateUpdatePayout: {},
  setDataCreateUpdatePayout: () => {},
  showCreateUpdateAdjustments: false,
  setShowCreateUpdateAdjustments: () => {},
  dataCreateUpdateAdjustments: {},
  setDataCreateUpdateAdjustments: () => {},
});


//GenerateContext
