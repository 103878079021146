//core
import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Menu,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
} from '@mui/material';

//icons
import RestartAltIcon from "@mui/icons-material/RestartAlt";

//styles
import { useStyles } from "./styles";

//helpers
import { getComparativePeriodFromPeriod, getPeriod, PeriodTypes } from "../../../UI/FormFields/PeriodDatesField/utils";
import { deleteCookie } from "../../../../helpers/utils";

//constants
import { periodShortcuts } from "../../../common/DashboardV2/constants";

//hooks
import { useUser } from "../../../../store/common/user/useUser";

//components
import MuiTwiceDateRangePicker from "../../Fields/MuiTwiceDateRangePicker";
import DateShortcutsList from "../../DateShortcutsList";
import { defaultPeriodsState, IPeriodsType } from "../../../common/DashboardV2";
import DateTime from "../../../common/TableData/components/DateTime";
import arePropsEqual from "react-fast-compare";
import format from "date-fns/format";
import {startOfMonth} from "date-fns";

interface IPeriodDatesFieldProps {
  periods: IPeriodsType;
  onApply: (data: IPeriodsType) => void;
}

const PeriodDatesField: React.FC<IPeriodDatesFieldProps> = ({
  periods,
  onApply,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { user } = useUser();

  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTypes>(periods.main.period);
  const [periodFrom, setPeriodFrom] = useState<Date | ''>(periods.main.start);
  const [periodTo, setPeriodTo] = useState<Date | ''>(periods.main.end);

  const [selectedComparePeriod, setSelectedComparePeriod] = useState<PeriodTypes>(periods.comparative.period);
  const [comparePeriodFrom, setComparePeriodFrom] = useState<Date | ''>(periods.comparative.start);
  const [comparePeriodTo, setComparePeriodTo] = useState<Date | ''>(periods.comparative.end);

  const [checkedCompare, setCheckedCompare] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!!selectedPeriod) {
      const period = getPeriod(selectedPeriod);

      if (!!period.start && !!period.end) {
        setPeriodFrom(period.start);
        setPeriodTo(period.end);
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (checkedCompare && !!selectedPeriod) {
      const comparePeriod = getComparativePeriodFromPeriod(selectedPeriod);

      setSelectedComparePeriod(comparePeriod);
    }
  }, [checkedCompare, selectedPeriod]);

  useEffect(() => {
    if (!!selectedComparePeriod) {
      const period = getPeriod(selectedComparePeriod);

      if (!!period.start && !!period.end) {
        setComparePeriodFrom(period.start);
        setComparePeriodTo(period.end);
      }
    }
  }, [selectedComparePeriod]);

  useEffect(() => {
    if (!!periods.comparative.start && !!periods.comparative.end) {
      setCheckedCompare(true);
    }
  }, [periods]);

  const handleChangeCompare = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setSelectedComparePeriod('');
      setComparePeriodFrom('');
      setComparePeriodTo('');
    }
    setCheckedCompare(event.target.checked);
  };

  const handleChangePeriodDate = (start: Date | null, end: Date | null) => {
    setPeriodFrom(!!start ? start : '');
    //@ts-ignore
    setPeriodTo(!!end ? end : start);
    setSelectedPeriod('')
  }

  const handleChangeComparePeriodDate = (start: Date | null, end: Date | null) => {
    setComparePeriodFrom(!!start ? start : '');
    //@ts-ignore
    setComparePeriodTo(!!end ? end : start);
    setSelectedComparePeriod('')
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = useCallback(() => {
    onApply({
      main: {
        period: selectedPeriod,
        start: periodFrom,
        end: periodTo,
      },
      comparative: {
        period: selectedComparePeriod,
        start: comparePeriodFrom,
        end: comparePeriodTo,
      },
    });

    setAnchorEl(null);
  }, [selectedPeriod, periodFrom, periodTo, selectedComparePeriod, comparePeriodFrom, comparePeriodTo, checkedCompare]);

  const handleResetPeriodsToDefault = (data: any) => {
    setCheckedCompare(false);
    setSelectedPeriod('last_7_days');
    onApply({
      main: {
        period: 'last_7_days',
        start: getPeriod('last_7_days').start,
        end: getPeriod('last_7_days').end,
      },
      comparative: {
        period: '',
        start: '',
        end: '',
      }
    });


    setTimeout(() => {
      deleteCookie(`${user.user.namespace}-dashboard-periods`);
    }, 200);
  }

  const clearPeriods = <>
    <Tooltip title={t("common.buttons.reset_to_default")}>
      <span>
        <IconButton
          size="small"
          aria-label="reset-periods"
          onClick={handleResetPeriodsToDefault}
          disabled={arePropsEqual(
            {
              main: {
                period: defaultPeriodsState.main.period,
                start: format(new Date(defaultPeriodsState.main.start), "yyyy-MM-dd"),
                end: format(new Date(defaultPeriodsState.main.end), "yyyy-MM-dd"),
              },
              comparative: {
                period: defaultPeriodsState.comparative.period,
                start: !!defaultPeriodsState.comparative.start ? format(new Date(defaultPeriodsState.comparative.start), "yyyy-MM-dd") : '',
                end: !!defaultPeriodsState.comparative.end ? format(new Date(defaultPeriodsState.comparative.end), "yyyy-MM-dd") : '',
              }
            },
            {
              main: {
                period: periods.main.period,
                start: format(new Date(periods.main.start), "yyyy-MM-dd"),
                end: format(new Date(periods.main.end), "yyyy-MM-dd"),
              },
              comparative: {
                period: periods.comparative.period,
                start: !!periods.comparative.start ? format(new Date(periods.comparative.start), "yyyy-MM-dd") : '',
                end: !!periods.comparative.end ? format(new Date(periods.comparative.end), "yyyy-MM-dd") : '',
              }
            }
          )}
        >
        <RestartAltIcon />
      </IconButton>
      </span>
    </Tooltip>
  </>;

  return (
    <Box className={styles.block}>
      <Button
        id="basic-button"
        className={styles.openBtn}
        color="primary"
        variant="contained"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableElevation={true}
      >
        <span className={'label'}>
          {`${t("common.components.periods.title")}: `}
        </span>
        <span className={'group'}>
          <DateTime value={periods.main.start} />
          <span className={'line'}>{` - `}</span>
          <DateTime value={periods.main.end} />
        </span>
        {!!periods.comparative.start && (
          <span>{` / `}</span>
        )}
        <span className={'group'}>
          {!!periods.comparative.start && (<>
            <DateTime value={periods.comparative.start} />
            <span className={'line'}>{` - `}</span>
            <DateTime value={periods.comparative.end} />
          </>)}
        </span>
      </Button>

      {clearPeriods}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={styles.menu}
      >
       <Box className={styles.dropdown}>
         <Box className={styles.shortcutList}>
           <DateShortcutsList
            limit={4}
            data={periodShortcuts}
            period={selectedPeriod}
            setPeriod={setSelectedPeriod}
           />
         </Box>

         <Box>
           <MuiTwiceDateRangePicker
             label={'common.components.periods.title'}
             startLabel={'admin.reports.filter.from'}
             endLabel={'admin.reports.filter.to'}
             startDate={periodFrom}
             endDate={periodTo}
             onChange={handleChangePeriodDate}
             hideLabel={true}
             hideClear={true}
             fieldClassName={styles.dateRange}
           />
         </Box>

         <Box className={styles.compare}>
           <FormControlLabel
             control={<Switch checked={checkedCompare}
                              onChange={handleChangeCompare}
                              inputProps={{ 'aria-label': 'controlled' }} />}
             label={t("common.forms.fields.compare")}
           />
         </Box>

         {checkedCompare && (
           <Box className={styles.compareFields}>
             <Box className={styles.shortcutList}>
               <DateShortcutsList
                 limit={4}
                 data={periodShortcuts}
                 period={selectedComparePeriod}
                 setPeriod={setSelectedComparePeriod}
               />
             </Box>

             <Box>
               <MuiTwiceDateRangePicker
                 label={'common.components.periods.title'}
                 startLabel={'admin.reports.filter.from'}
                 endLabel={'admin.reports.filter.to'}
                 startDate={comparePeriodFrom}
                 endDate={comparePeriodTo}
                 onChange={handleChangeComparePeriodDate}
                 hideLabel={true}
                 hideClear={true}
                 fieldClassName={styles.dateRange}
               />
             </Box>
           </Box>
         )}

         <Box className={styles.footer}>
           <Button
             variant="outlined"
             color="secondary"
             onClick={handleClose}
           >
             {t("common.buttons.cancel")}
           </Button>
           <Button
             variant="contained"
             color="primary"
             onClick={handleApply}
           >
             {t("common.buttons.apply")}
           </Button>
         </Box>
       </Box>
      </Menu>
    </Box>
  );
};

export default PeriodDatesField;
