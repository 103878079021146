import { createContext } from "react";

import { IFilterData } from "../components/admin/Partners/components/DesktopPartners";
import { IOffersFilterData } from "../components/admin/Offers/components/Desktop";
import { initialFilterData } from "../components/admin/Reports/WebhooksReport/components/DesktopReport";
import { initialFilterData as initialCreativesReportFilterData } from "../components/admin/Reports/CreativeReport/components/DesktopReport";
import { initialSummaryReportFilterData } from "../components/admin/Reports/SummaryReportV2/components/DesktopReport";
import { initialSummaryReportFilterData as initialAffSummaryReportFilterData } from "../components/affiliate/Reports/SummaryReportV2/components/DesktopReport";
import { IWebhookReportFilterData } from "../components/admin/Reports/WebhooksReport/types";
import { IFilterFields } from "../components/admin/Reports/Finance_report/components/Details";
import { IFilterFields as IFilterFieldsFinance} from "../components/admin/Reports/Finance_report/";
import { ICreativesReportFilterData } from "../components/admin/Reports/CreativeReport/types";
import { ISummaryReportFilterDataType } from "../components/admin/Reports/SummaryReportV2/types";
import { ISummaryReportFilterDataType as IAffSummaryReportFilterDataType } from "../components/affiliate/Reports/SummaryReportV2/types";
import {IDashboardFilterType} from "../components/common/DashboardV2/types";
import { initialDashboardV2FilterData } from "../components/common/DashboardV2";
import { IDealsFilterData } from "../components/admin/Deals/components/DesktopDeals";
import { IEmployeesFilterData } from "../components/admin/Employees/components/DesktopReport";
import { initialFilterData as initialEmployeesFilterData } from "../components/admin/Employees/components/DesktopReport";

export type OrderType = {
  [key: string]: 'asc' | 'desc'
};

export type ObjType = {
  [key: string]: any;
};

//admin partners
const initialDealTrackersOrder: OrderType = { created: "desc" };
const initialPartnersOrder: OrderType = { id: "asc" };
export const initialPartnersFilter: IFilterData = {
  approve_status: {id: 'approved', label: 'approved'},
  communication_statuses: [],
  traffic_statuses: [],
  traffic_sources: [],
  traffic_geos: [],
  affiliate_ids: [],
  manager_ids: [],
  manager_teams: [],
  platform_ids: [],
  is_blocked: null,
  is_email_confirmed: true,
  order: { id: "asc" },
  invitation_link_ids: [],
  invitation_manager_ids: [],
};

//admin creatives
export const initialAdmCreativesFilter = {
  status: null,
  product: null,
  concept: null,
  geo: [],
  language: [],
  platforms: [],
  brands: [],
  type: [],
};
export const initialAdmCreativesOrder: OrderType = { id: "desc" };
export const initialAdmCreativesV2Filter = {
  status: null,
  use_as_traffic_back: null,
  type: [],
  product: null,
  concept: null,
  geo: [],
  language: [],
  platforms: [],
  brands: [],
};
export const initialAdmCreativesV2Order: OrderType = { id: "desc" };

//admin deals
export const initialAdmDealsOrder: OrderType = { created_at: "desc" };
export const initialAdmDealsFilter = {
  brands: [],
  platforms: [],
  created_by: [],
  deal_types: [],
};
//admin subAffDeals
export const initialAdmSubAffDealsOrder: OrderType = { created_at: "desc" };

//admin offers
export const initialAdmOffersFilter = {
  brands: [],
  geo: [],
};
export const initialAdmOffersOrder: OrderType = { id: "desc" };

//admin details FinReport
export const initialAdmDetailsFinReportFilter: IFilterFields = {
  affiliateIds: [],
  managerIds: [],
  departmentIds: [],
  statuses: [],
  currencies: [],
  brands: [],
  paymentMethodNames: [],
  companies: [],
};

//admin FinReport
export const initialAdmFinReportFilter: IFilterFieldsFinance = {
  affiliateIds: [],
  managerIds: [],
  departmentIds: [],
  platformIds: [],
  statuses: [],
  paymentMethodNames: [],
  companies: [],
};

//aff offers
export const initialAffOffersFilter = {
  geo: [],
};

//brands
export const initialBrandsFilter = {
  countries: [],
  currencies: [],
  departments: [],
  operator_ids: [],
};
export const initialBrandsOrder: OrderType = { created_at: "desc" };

//admWebhooksReport
export const initialAdmWebhooksReportFilter: IWebhookReportFilterData = initialFilterData;
export const initialAdmCreativesReportFilter: ICreativesReportFilterData = initialCreativesReportFilterData;

//admNewSummaryReport
export const initialAdmSummaryReportFilter: ISummaryReportFilterDataType = initialSummaryReportFilterData;

//affNewSummaryReport
export const initialAffSummaryReportFilter: IAffSummaryReportFilterDataType = initialAffSummaryReportFilterData;

//partners tracker links / Creative list
export const initialPartnersTrackerLinksFilter: IFilterData = {
  sort_by: '',
  product: null,
  concept: null,
  geo: [],
  brands: [],
  language: [],
}

//admEmployees
export const initialAdmEmployeesOrder : OrderType = {
  id: 'desc',
}

//aff tracker links / Creative list
export const initialAffTrackerLinksFilter: IFilterData = {
  sort_by: '',
  product: null,
  concept: null,
  geo: [],
  language: [],
}

//billing filters
export const initialAdmBillingFilters: ObjType = { platform: "", date: ''};

//TwoFa settings
export const initialAdmTwoFaSettings: ObjType = { email_secret: "", email_url: ""};

//Payment History
export const initialPaymentHistoryOrder: OrderType = { date: "desc" };
export const initialAdmPaymentBreakdownOrder : OrderType = {
  id: 'desc',
};

export const initialAdmPayoutsOrder : OrderType = {
  id: 'desc',
};

export const initialAdmAdjustmentsOrder : OrderType = {
  id: 'desc',
};

export interface IGlobalContext {
  partnersSearch: string;
  partnersFilter: IFilterData;
  partnersOrder: OrderType;
  partnersPage: number;
  partnersRows: number;
  partnersActiveFilterTab: string,
  setPartnersFilter: (filter: IFilterData) => void;
  setPartnersOrder: (order: OrderType) => void;
  setPartnersPage: (page: number) => void;
  setPartnersRows: (rows: number) => void;
  setPartnersActiveFilterTab: (tab: string) => void;
  setPartnersSearch: (search: string) => void;

  dealTrackersSearch: string;
  dealTrackersOrder: OrderType;
  dealTrackersPage: number;
  dealTrackersRows: number;
  dealTrackersAffId: string;
  setDealTrackersAffId: (affId: string) => void;
  setDealTrackersSearch: (search: string) => void;
  setDealTrackersOrder: (order: OrderType) => void;
  setDealTrackersPage: (page: number) => void;
  setDealTrackersRows: (rows: number) => void;

  dealArchivedTrackersOrder: OrderType;
  dealArchivedTrackersPage: number;
  dealArchivedTrackersRows: number;
  setDealArchivedTrackersOrder: (order: OrderType) => void;
  setDealArchivedTrackersPage: (page: number) => void;
  setDealArchivedTrackersRows: (rows: number) => void;
  resetDealsState: () => void;

  //admCreatives
  admCreativesSearch: string;
  admCreativesFilter: IFilterData;
  admCreativesOrder: OrderType;
  admCreativesPage: number;
  admCreativesRows: number;
  setAdmCreativesFilter: (filter: IFilterData) => void;
  setAdmCreativesOrder: (order: OrderType) => void;
  setAdmCreativesPage: (page: number) => void;
  setAdmCreativesRows: (rows: number) => void;
  setAdmCreativesSearch: (search: string) => void;

  //admCreativesV2
  admCreativesV2Search: string;
  admCreativesV2Filter: IFilterData;
  admCreativesV2Order: OrderType;
  admCreativesV2Page: number;
  admCreativesV2Rows: number;
  setAdmCreativesV2Filter: (filter: IFilterData) => void;
  setAdmCreativesV2Order: (order: OrderType) => void;
  setAdmCreativesV2Page: (page: number) => void;
  setAdmCreativesV2Rows: (rows: number) => void;
  setAdmCreativesV2Search: (search: string) => void;
  resetAdmCreativesV2Context: () => void;

  //admDeals
  admDealsSearch: string;
  admDealsOrder: OrderType;
  admDealsPage: number;
  admDealsRows: number;
  admDealsSelectedBrands: any;
  admDealsSelectedRowBrands: any;
  admDealsFilter: IDealsFilterData;
  setAdmDealsFilter: (filter: IFilterData) => void;
  setAdmDealsOrder: (order: OrderType) => void;
  setAdmDealsPage: (page: number) => void;
  setAdmDealsRows: (rows: number) => void;
  setAdmDealsSearch: (search: string) => void;
  setAdmDealsSelectedBrands: (row: any) => void;
  setAdmDealsSelectedRowBrands: (row: any) => void;

  //admSubAffDeals
  admSubAffDealsSearch: string;
  admSubAffDealsOrder: OrderType;
  admSubAffDealsPage: number;
  admSubAffDealsRows: number;
  setAdmSubAffDealsOrder: (order: OrderType) => void;
  setAdmSubAffDealsPage: (page: number) => void;
  setAdmSubAffDealsRows: (rows: number) => void;
  setAdmSubAffDealsSearch: (search: string) => void;

  //admOffers
  admOffersSearch: string;
  admOffersFilter: IOffersFilterData;
  admOffersOrder: OrderType;
  admOffersPage: number;
  admOffersRows: number;
  setAdmOffersFilter: (filter: IFilterData) => void;
  setAdmOffersOrder: (order: OrderType) => void;
  setAdmOffersPage: (page: number) => void;
  setAdmOffersRows: (rows: number) => void;
  setAdmOffersSearch: (search: string) => void;

  //affOffers
  affOffersList: any[],
  affOffersSearch: string;
  affOffersFilter: IOffersFilterData,
  setAffOffersList: (data: any[]) => void;
  setAffOffersFilter: (filter: IOffersFilterData) => void;
  setAffOffersSearch: (search: string) => void;

  //Brands
  brandsSearch: string;
  brandsFilter: IOffersFilterData;
  brandsOrder: OrderType;
  brandsPage: number;
  brandsRows: number;
  setBrandsFilter: (filter: IFilterData) => void;
  setBrandsOrder: (order: OrderType) => void;
  setBrandsPage: (page: number) => void;
  setBrandsRows: (rows: number) => void;
  setBrandsSearch: (search: string) => void;

  //admWebhooksReport
  admWebhooksReportFilter: IWebhookReportFilterData;
  setAdmWebhooksReportFilter: (filter: IWebhookReportFilterData) => void;
  resetAdmWebhooksReportFilter: () => void;

  //admNewCreativesReport
  admCreativesReportFilter: ICreativesReportFilterData;
  setAdmCreativesReportFilter: (filter: ICreativesReportFilterData) => void;
  resetAdmCreativesReportFilter: () => void;

  //admNewSummaryReport
  admSummaryReportFilter: ISummaryReportFilterDataType;
  setAdmSummaryReportFilter: (filter: ISummaryReportFilterDataType) => void;
  resetAdmSummaryReportFilter: () => void;

  //affNewSummaryReport
  affSummaryReportFilter: IAffSummaryReportFilterDataType;
  setAffSummaryReportFilter: (filter: IAffSummaryReportFilterDataType) => void;
  resetAffSummaryReportFilter: () => void;

  //admPartnersTrackerLinks
  admPartnersTrackerLinksDrawerFilter: IFilterData;
  setAdmPartnersTrackerLinksDrawerFilter: (filter: IFilterData) => void;
  resetAdmPartnersTrackerLinksDrawerFilter: () => void;

  //affPartnersTrackerLinks
  affTrackerLinksDrawerFilter: IFilterData;
  setAffTrackerLinksDrawerFilter: (filter: IFilterData) => void;
  resetAffTrackerLinksDrawerFilter: () => void;


  //FinReport
  admFinReportFilter: IFilterFieldsFinance,
  setAdmFinReportFilter: (filter: IFilterFieldsFinance) => void;
  resetAdmFinReportFilter: () => void;

  //detailsFinReport
  admDetailsFinReportFilter: IFilterFields,
  setAdmDetailsFinReportFilter: (filter: IFilterFields) => void;
  resetAdmDetailsFinReportFilter: () => void;

  //admEmployees
  admEmployeesFilter: IEmployeesFilterData;
  admEmployeesSearch: string;
  admEmployeesOrder: OrderType;
  admEmployeesPage: number;
  admEmployeesRows: number;
  setAdmEmployeesFilter: (filter: IEmployeesFilterData) => void;
  setAdmEmployeesOrder: (order: OrderType) => void;
  setAdmEmployeesPage: (page: number) => void;
  setAdmEmployeesRows: (rows: number) => void;
  setAdmEmployeesSearch: (search: string) => void;
  resetAdmEmployeeContexts: () => void;

  //admBilling
  admBillingFilters: ObjType;
  setAdmBillingFilters: (filter: ObjType) => void;
  resetAdmBillingContexts: () => void;

  //DashboardV2Filter
  dashboardV2Filter: IDashboardFilterType;
  setDashboardV2Filter: (filter: IDashboardFilterType) => void;
  resetDashboardV2Filter: () => void;

  //admPaymentHistory
  admPaymentHistoryOrder: OrderType;
  admPaymentHistoryPage: number;
  admPaymentHistoryRows: number;
  setAdmPaymentHistoryOrder: (order: OrderType) => void;
  setAdmPaymentHistoryPage: (page: number) => void;
  setAdmPaymentHistoryRows: (rows: number) => void;

  //affPaymentHistory
  affPaymentHistoryOrder: OrderType;
  affPaymentHistoryPage: number;
  affPaymentHistoryRows: number;
  setAffPaymentHistoryOrder: (order: OrderType) => void;
  setAffPaymentHistoryPage: (page: number) => void;
  setAffPaymentHistoryRows: (rows: number) => void;

  //admPaymentBreakdown
  admPaymentBreakdownOrder: OrderType;
  admPaymentBreakdownPage: number;
  admPaymentBreakdownRows: number;
  setAdmPaymentBreakdownOrder: (order: OrderType) => void;
  setAdmPaymentBreakdownPage: (page: number) => void;
  setAdmPaymentBreakdownRows: (rows: number) => void;

  //admPayouts
  admPayoutsOrder: OrderType;
  admPayoutsPage: number;
  admPayoutsRows: number;
  setAdmPayoutsOrder: (order: OrderType) => void;
  setAdmPayoutsPage: (page: number) => void;
  setAdmPayoutsRows: (rows: number) => void;

  //admAdjustments
  admAdjustmentsOrder: OrderType;
  admAdjustmentsPage: number;
  admAdjustmentsRows: number;
  setAdmAdjustmentsOrder: (order: OrderType) => void;
  setAdmAdjustmentsPage: (page: number) => void;
  setAdmAdjustmentsRows: (rows: number) => void;

  resetContext: () => void;
  resetPartnersContext: () => void;
  resetAdmCreativesContext: () => void;
  resetAdmDealsContext: () => void;
  resetAdmSubAffDealsContext: () => void;
  resetAdmOffersContext: () => void;
  resetAffOffersContext: () => void;
  resetBrandsContext: () => void;
}

export const initialValue: IGlobalContext = {
  partnersSearch: '',
  partnersFilter: initialPartnersFilter,
  partnersOrder: initialPartnersOrder,
  partnersPage: 0,
  partnersRows: 10,
  partnersActiveFilterTab: '2',
  setPartnersFilter: function (filter) {
    this.partnersFilter = filter;
  },
  setPartnersActiveFilterTab: function (tab) {
    this.partnersActiveFilterTab = tab;
  },
  setPartnersOrder: function (newOrder) {
    this.partnersOrder = newOrder;
  },
  setPartnersPage: function (page) {
    this.partnersPage = page;
  },
  setPartnersRows: function (rows) {
    this.partnersRows = rows;
  },
  setPartnersSearch: function (search) {
    this.partnersSearch = search;
  },

  dealTrackersSearch: '',
  dealTrackersOrder: initialDealTrackersOrder,
  dealTrackersPage: 0,
  dealTrackersRows: 10,
  dealTrackersAffId: '',
  setDealTrackersAffId: function (affId) {
    this.dealTrackersAffId = affId;
  },
  setDealTrackersSearch: function (search) {
    this.dealTrackersSearch = search;
  },
  setDealTrackersOrder: function (newOrder) {
    this.dealTrackersOrder = newOrder;
  },
  setDealTrackersPage: function (page) {
    this.dealTrackersPage = page;
  },
  setDealTrackersRows: function (rows) {
    this.dealTrackersRows = rows;
  },

  dealArchivedTrackersOrder: initialDealTrackersOrder,
  dealArchivedTrackersPage: 0,
  dealArchivedTrackersRows: 10,
  setDealArchivedTrackersOrder: function (newOrder) {
    this.dealArchivedTrackersOrder = newOrder;
  },
  setDealArchivedTrackersPage: function (page) {
    this.dealArchivedTrackersPage = page;
  },
  setDealArchivedTrackersRows: function (rows) {
    this.dealArchivedTrackersRows = rows;
  },
  resetDealsState: function () {
    this.dealTrackersSearch = '';
    this.dealTrackersOrder = initialDealTrackersOrder;
    this.dealTrackersPage = 0;
    this.dealTrackersRows = 10;
    this.dealArchivedTrackersOrder = initialDealTrackersOrder;
    this.dealArchivedTrackersPage = 0;
    this.dealArchivedTrackersRows = 10;
  },

  //admCreatives
  admCreativesSearch: '',
  admCreativesFilter: initialAdmCreativesFilter,
  admCreativesOrder: initialAdmCreativesOrder,
  admCreativesPage: 0,
  admCreativesRows: 10,
  setAdmCreativesFilter: function (filter) {
    this.admCreativesFilter = filter;
  },
  setAdmCreativesOrder: function (newOrder) {
    this.admCreativesOrder = newOrder;
  },
  setAdmCreativesPage: function (page) {
    this.admCreativesPage = page;
  },
  setAdmCreativesRows: function (rows) {
    this.admCreativesRows = rows;
  },
  setAdmCreativesSearch: function (search) {
    this.admCreativesSearch = search;
  },

  //admCreativesV2
  admCreativesV2Search: '',
  admCreativesV2Filter: initialAdmCreativesV2Filter,
  admCreativesV2Order: initialAdmCreativesV2Order,
  admCreativesV2Page: 0,
  admCreativesV2Rows: 10,
  setAdmCreativesV2Filter: function (filter) {
    this.admCreativesV2Filter = filter;
  },
  setAdmCreativesV2Order: function (newOrder) {
    this.admCreativesV2Order = newOrder;
  },
  setAdmCreativesV2Page: function (page) {
    this.admCreativesV2Page = page;
  },
  setAdmCreativesV2Rows: function (rows) {
    this.admCreativesV2Rows = rows;
  },
  setAdmCreativesV2Search: function (search) {
    this.admCreativesV2Search = search;
  },

  //admDeals
  admDealsSearch: '',
  admDealsOrder: initialAdmDealsOrder,
  admDealsPage: 0,
  admDealsRows: 10,
  admDealsFilter: initialAdmDealsFilter,
  admDealsSelectedBrands: [],
  admDealsSelectedRowBrands: [],
  setAdmDealsOrder: function (newOrder) {
    this.admDealsOrder = newOrder;
  },
  setAdmDealsPage: function (page) {
    this.admDealsPage = page;
  },
  setAdmDealsRows: function (rows) {
    this.admDealsRows = rows;
  },
  setAdmDealsSearch: function (search) {
    this.admDealsSearch = search;
  },
  setAdmDealsSelectedBrands: function (selected) {
    this.admDealsSelectedBrands = selected;
  },
  setAdmDealsSelectedRowBrands: function (selected) {
    this.admDealsSelectedRowBrands = selected;
  },
  setAdmDealsFilter: function (filter) {
    this.admDealsFilter = filter;
  },

  //admSubAffDeals
  admSubAffDealsSearch: '',
  admSubAffDealsOrder: initialAdmSubAffDealsOrder,
  admSubAffDealsPage: 0,
  admSubAffDealsRows: 10,
  setAdmSubAffDealsOrder: function (newOrder) {
    this.admSubAffDealsOrder = newOrder;
  },
  setAdmSubAffDealsPage: function (page) {
    this.admSubAffDealsPage = page;
  },
  setAdmSubAffDealsRows: function (rows) {
    this.admSubAffDealsRows = rows;
  },
  setAdmSubAffDealsSearch: function (search) {
    this.admSubAffDealsSearch = search;
  },

  //admOffers
  admOffersSearch: '',
  admOffersFilter: initialAdmOffersFilter,
  admOffersOrder: initialAdmOffersOrder,
  admOffersPage: 0,
  admOffersRows: 10,
  setAdmOffersFilter: function (filter) {
    this.admOffersFilter = filter;
  },
  setAdmOffersOrder: function (newOrder) {
    this.admOffersOrder = newOrder;
  },
  setAdmOffersPage: function (page) {
    this.admOffersPage = page;
  },
  setAdmOffersRows: function (rows) {
    this.admOffersRows = rows;
  },
  setAdmOffersSearch: function (search) {
    this.admOffersSearch = search;
  },

  //affOffers
  affOffersList: [],
  affOffersSearch: '',
  affOffersFilter: initialAffOffersFilter,
  setAffOffersList: function (data) {
    this.affOffersList = data;
  },
  setAffOffersFilter: function (filter) {
    this.affOffersFilter = filter;
  },
  setAffOffersSearch: function (search) {
    this.affOffersSearch = search;
  },

  //Brands
  brandsSearch: '',
  brandsFilter: initialBrandsFilter,
  brandsOrder: initialBrandsOrder,
  brandsPage: 0,
  brandsRows: 10,
  setBrandsFilter: function (filter) {
    this.brandsFilter = filter;
  },
  setBrandsOrder: function (newOrder) {
    this.brandsOrder = newOrder;
  },
  setBrandsPage: function (page) {
    this.brandsPage = page;
  },
  setBrandsRows: function (rows) {
    this.brandsRows = rows;
  },
  setBrandsSearch: function (search) {
    this.brandsSearch = search;
  },

  //admWebhooksReport
  admWebhooksReportFilter: initialAdmWebhooksReportFilter,
  setAdmWebhooksReportFilter: function (filter) {
    this.admWebhooksReportFilter = filter;
  },
  resetAdmWebhooksReportFilter: function () {
    this.admWebhooksReportFilter = initialAdmWebhooksReportFilter;
  },

  //admCreativesReport
  admCreativesReportFilter: initialAdmCreativesReportFilter,
  setAdmCreativesReportFilter: function (filter) {
    this.admCreativesReportFilter = filter;
  },
  resetAdmCreativesReportFilter: function () {
    this.admCreativesReportFilter = initialAdmCreativesReportFilter;
  },

  //admSummaryReport
  admSummaryReportFilter: initialAdmSummaryReportFilter,
  setAdmSummaryReportFilter: function (filter) {
    this.admSummaryReportFilter = filter;
  },
  resetAdmSummaryReportFilter: function () {
    this.admSummaryReportFilter = initialAdmSummaryReportFilter;
  },

  //admPartnersTrackerLinks
  admPartnersTrackerLinksDrawerFilter: initialPartnersTrackerLinksFilter,
  setAdmPartnersTrackerLinksDrawerFilter: function (filter) {
    this.admPartnersTrackerLinksDrawerFilter = filter;
  },
  resetAdmPartnersTrackerLinksDrawerFilter: function () {
    this.admPartnersTrackerLinksDrawerFilter = initialPartnersTrackerLinksFilter;
  },

  //affSummaryReport
  affSummaryReportFilter: initialAffSummaryReportFilter,
  setAffSummaryReportFilter: function (filter) {
    this.affSummaryReportFilter = filter;
  },
  resetAffSummaryReportFilter: function () {
    this.affSummaryReportFilter = initialAffSummaryReportFilter;
  },

  //affTrackerLinks
  affTrackerLinksDrawerFilter: initialAffTrackerLinksFilter,
  setAffTrackerLinksDrawerFilter: function (filter) {
    this.affTrackerLinksDrawerFilter = filter;
  },
  resetAffTrackerLinksDrawerFilter: function () {
    this.admPartnersTrackerLinksDrawerFilter = initialAffTrackerLinksFilter;
  },

  //admDetailsFinReport
  admDetailsFinReportFilter: initialAdmDetailsFinReportFilter,
  setAdmDetailsFinReportFilter: function (filter) {
    this.admDetailsFinReportFilter = filter;
  },
  resetAdmDetailsFinReportFilter: function () {
    this.admDetailsFinReportFilter = initialAdmDetailsFinReportFilter;
  },

  //admFinReport
  admFinReportFilter: initialAdmFinReportFilter,
  setAdmFinReportFilter: function (filter) {
    this.admFinReportFilter = filter;
  },
  resetAdmFinReportFilter: function () {
    this.admFinReportFilter = initialAdmFinReportFilter;
  },

  //admEmployees
  admEmployeesFilter: initialEmployeesFilterData,
  admEmployeesSearch: '',
  admEmployeesOrder: initialAdmEmployeesOrder,
  admEmployeesPage: 0,
  admEmployeesRows: 10,
  setAdmEmployeesFilter: function (filter) {
    this.admEmployeesFilter = filter;
  },
  setAdmEmployeesSearch: function (search) {
    this.admEmployeesSearch = search;
  },
  setAdmEmployeesOrder: function (newOrder) {
    this.admEmployeesOrder = newOrder;
  },
  setAdmEmployeesPage: function (page) {
    this.admEmployeesPage = page;
  },
  setAdmEmployeesRows: function (rows) {
    this.admEmployeesRows = rows;
  },

  //admBilling
  admBillingFilters: initialAdmBillingFilters,
  setAdmBillingFilters: function (filter) {
    this.admBillingFilters = filter;
  },
  resetAdmBillingContexts: function () {
    localStorage.removeItem('billing_platform');
    this.admBillingFilters = initialAdmBillingFilters;
  },

  //DashboardV2Filter
  dashboardV2Filter: initialDashboardV2FilterData,
  setDashboardV2Filter: function (filter) {
    this.dashboardV2Filter = filter;
  },
  resetDashboardV2Filter: function () {
    this.dashboardV2Filter = initialDashboardV2FilterData;
  },

  //admPaymentHistory
  admPaymentHistoryOrder: initialPaymentHistoryOrder,
  admPaymentHistoryPage: 0,
  admPaymentHistoryRows: 10,
  setAdmPaymentHistoryOrder: function (newOrder) {
    this.admPaymentHistoryOrder = newOrder;
  },
  setAdmPaymentHistoryPage: function (page) {
    this.admPaymentHistoryPage = page;
  },
  setAdmPaymentHistoryRows: function (rows) {
    this.admPaymentHistoryRows = rows;
  },

  //affPaymentHistory
  affPaymentHistoryOrder: initialPaymentHistoryOrder,
  affPaymentHistoryPage: 0,
  affPaymentHistoryRows: 10,
  setAffPaymentHistoryOrder: function (newOrder) {
    this.affPaymentHistoryOrder = newOrder;
  },
  setAffPaymentHistoryPage: function (page) {
    this.affPaymentHistoryPage = page;
  },
  setAffPaymentHistoryRows: function (rows) {
    this.affPaymentHistoryRows = rows;
  },

  //admPaymentBreakdown
  admPaymentBreakdownOrder: initialAdmPaymentBreakdownOrder,
  admPaymentBreakdownPage: 0,
  admPaymentBreakdownRows: 100,
  setAdmPaymentBreakdownOrder: function (order) {
    this.admPaymentBreakdownOrder = order;
  },
  setAdmPaymentBreakdownPage: function  (page) {
    this.admPaymentBreakdownPage = page;
  },
  setAdmPaymentBreakdownRows: function (rows) {
    this.admPaymentBreakdownRows = rows;
  },

  //admPayouts
  admPayoutsOrder: initialAdmPayoutsOrder,
  admPayoutsPage: 0,
  admPayoutsRows: 100,
  setAdmPayoutsOrder: function (order) {
    this.admPayoutsOrder = order;
  },
  setAdmPayoutsPage: function  (page) {
    this.admPayoutsPage = page;
  },
  setAdmPayoutsRows: function (rows) {
    this.admPayoutsRows = rows;
  },

  //admAdjustments
  admAdjustmentsOrder: initialAdmAdjustmentsOrder,
  admAdjustmentsPage: 0,
  admAdjustmentsRows: 100,
  setAdmAdjustmentsOrder: function (order) {
    this.admAdjustmentsOrder = order;
  },
  setAdmAdjustmentsPage: function  (page) {
    this.admAdjustmentsPage = page;
  },
  setAdmAdjustmentsRows: function (rows) {
    this.admAdjustmentsRows = rows;
  },

  resetContext: function () {
    this.partnersSearch = '';
    this.partnersFilter = initialPartnersFilter;
    this.partnersOrder = initialPartnersOrder;
    this.partnersPage = 0;
    this.partnersRows = 10;
    this.partnersActiveFilterTab = '2';
    this.dealTrackersSearch = '';
    this.dealTrackersOrder = initialDealTrackersOrder;
    this.dealTrackersPage = 0;
    this.dealTrackersRows = 10;
    this.dealArchivedTrackersOrder = initialDealTrackersOrder;
    this.dealArchivedTrackersPage = 0;
    this.dealArchivedTrackersRows = 10;
    this.dashboardV2Filter = initialDashboardV2FilterData;
  },

  resetPartnersContext: function () {
    this.partnersSearch = '';
    this.partnersFilter = initialPartnersFilter;
    this.partnersOrder = initialPartnersOrder;
    this.partnersPage = 0;
    this.partnersRows = 10;
    this.partnersActiveFilterTab = '2';
    this.dealTrackersSearch = '';
    this.dealTrackersOrder = initialDealTrackersOrder;
    this.dealTrackersPage = 0;
    this.dealTrackersRows = 10;
    this.dealArchivedTrackersOrder = initialDealTrackersOrder;
    this.dealArchivedTrackersPage = 0;
    this.dealArchivedTrackersRows = 10;
  },

  resetAdmCreativesContext: function () {
    this.admCreativesSearch = '';
    this.admCreativesFilter = initialAdmCreativesFilter;
    this.admCreativesOrder = initialAdmCreativesOrder;
    this.admCreativesPage = 0;
    this.admCreativesRows = 10;
  },

  resetAdmCreativesV2Context: function () {
    this.admCreativesV2Search = '';
    this.admCreativesV2Filter = initialAdmCreativesV2Filter;
    this.admCreativesV2Order = initialAdmCreativesV2Order;
    this.admCreativesV2Page = 0;
    this.admCreativesV2Rows = 10;
  },

  resetAdmDealsContext: function () {
    this.admDealsSearch = '';
    this.admDealsOrder = initialAdmDealsOrder;
    this.admDealsPage = 0;
    this.admDealsRows = 10;
    this.admDealsSelectedBrands = [];
    this.admDealsSelectedRowBrands = [];
    this.admDealsFilter = initialAdmDealsFilter;
  },

  resetAdmSubAffDealsContext: function () {
    this.admSubAffDealsSearch = '';
    this.admSubAffDealsOrder = initialAdmSubAffDealsOrder;
    this.admSubAffDealsPage = 0;
    this.admSubAffDealsRows = 10;
  },

  resetAdmOffersContext: function () {
    this.admOffersSearch = '';
    this.admOffersOrder = initialAdmOffersOrder;
    this.admOffersFilter = initialAdmOffersFilter;
    this.admOffersPage = 0;
    this.admOffersRows = 10;
  },

  resetAffOffersContext: function () {
    this.affOffersSearch = '';
    this.affOffersFilter = initialAdmOffersFilter;
    this.affOffersList = [];
  },

  resetBrandsContext: function () {
    this.brandsFilter = initialBrandsFilter;
    this.brandsSearch = '';
    this.brandsOrder = initialBrandsOrder;
    this.brandsPage = 0;
    this.brandsRows = 10;
  },

  resetAdmEmployeeContexts: function () {
    this.admEmployeesSearch = '';
    this.admEmployeesOrder = initialAdmEmployeesOrder;
    this.admEmployeesPage = 0;
    this.admEmployeesRows = 10;
  },
};

export const GlobalContext = createContext<IGlobalContext>(initialValue);
