//core
import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { useDebounce } from "usehooks-ts";
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Checkbox,
  AutocompleteCloseReason,
} from "@mui/material";

//icons
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

//styles
import { useStyles } from "./styles";

//hooks
import useDidMountEffect from "../../../../customHooks/useDidMountEffect";

//components
import CircularProgress from "@mui/material/CircularProgress";
import CustomPaper from "./CustomPaper";
import { AutocompleteSelectContext } from "./context";


const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MAX_HEIGHT = ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP;

interface IMuiAutocompleteSelectProps  {
  keyValue: string;
  keyId: string;
  value: any;
  id: string;
  name: string;
  label: string;
  limitTags?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  multiple?: boolean;
  prefix?: string;
  getOptions: (payload: any) => any;
  payloadData: {
    [key: string]: any;
  };
  showSelectAll?: boolean;
  maxSelectAllCount?: number;
  disableCloseOnSelect?: boolean;
  onChange: (data: any) => void;
  labelWithId?: boolean;
  labelFirstWithId?: boolean;
  showLabelCounter?: boolean;
  maxHeightFieldScroll?: number; // min value 32, max value 96
  maxWidthChip?: number; // default 200
}

const MuiAutocompleteSelectLazy = (props: IMuiAutocompleteSelectProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const {
    keyValue,
    keyId,
    id,
    value,
    name,
    label,
    disabled = false,
    autoFocus = false,
    multiple = false,
    limitTags = -1,
    prefix = '',
    showSelectAll = false,
    maxSelectAllCount = 100,
    disableCloseOnSelect = false,
    getOptions,
    payloadData,
    labelWithId = false,
    labelFirstWithId = false,
    onChange,
    maxHeightFieldScroll = 96,
    showLabelCounter = false,
    maxWidthChip = 200,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const debouncedSearchValue = useDebounce<string>(searchValue, 500)

  const loading = open && !debouncedSearchValue && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      setShowLoader(true);
      const data = await getOptions(postData(page, null));
      if (active) {
        setOptions(data.payload.list);
        setHasMore(!(data.payload.total_pages === page));
        setShowLoader(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (showSelectAll) {
      if (!!value && value.length !== options.length) {
        setSelectAll(false);
      }
    }
  }, [value]);

  useDidMountEffect(() => {
    setOptions([]);
    setSelectAll(false);
    if (page === 1) {
      setShowLoader(true);
      (async () => {
        const data = await getOptions(postData(1, debouncedSearchValue.length >= 2 ? debouncedSearchValue : null));

        setOptions(data.payload.list);
        setHasMore(!(data.payload.total_pages === 1));
        setShowLoader(false);
      })();
    } else {
      setPage(1);
    }
  }, [debouncedSearchValue]);

  useDidMountEffect(() => {
    if (hasMore) {
      setShowLoader(true);
      (async () => {
        const data = await getOptions(postData(page, searchValue));

        setOptions((prevState) => {
          if (page === 1) {
            return [...data.payload.list]
          } else {
            return [...prevState, ...data.payload.list]
          }
        });
        setHasMore(!(data.payload.total_pages === page));
        setShowLoader(false);
      })();
    }
  }, [page]);

  const postData = (page: number, searchValue: string | null) => {
    return {
      ...payloadData,
      page: page,
      search: searchValue || null,
    }
  };

  const handleCheckAllOptions = () => {
    if (selectAll) {
      setSelectAll(false);
      onChange((prevState: any) => {
        return {
          ...prevState,
          [name]: [],
        }
      });
    } else {
      const selected: any[] = [...value, ...options];
      const selectedUniqueById: any[] = [...new Map(selected.map(item => [item['id'], item])).values()];

      setSelectAll(true);
      onChange((prevState: any) => {
        return {
          ...prevState,
          [name]: selectedUniqueById,
        }
      });
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const fetchData = useCallback(() => {
    if (hasMore) {
      setPage(page + 1);
    }
  }, [setPage, page, hasMore]);

  return (
    <AutocompleteSelectContext.Provider value={{
      showSelectAll: showSelectAll,
      selectAllState: selectAll,
      maxSelectAllCount: maxSelectAllCount,
      options: options,
      handleCheckAllOptions: handleCheckAllOptions,
    }}>
      <Box>
        <Autocomplete
          disableCloseOnSelect={disableCloseOnSelect}
          id={id}
          size="small"
          multiple={multiple}
          value={value}
          open={open}
          limitTags={limitTags}
          disabled={disabled}
          noOptionsText={t("common.components.autocomplete.no_matches")}
          filterOptions={(options, state) => options}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(e: any, reason: AutocompleteCloseReason) => {
            if (reason === "escape") {
              setOpen(false);
            }
            setOpen(false);

            setOptions([]);
            setPage(1);
            setSearchValue('');
            setHasMore(true);
          }}
          loading={showLoader}
          options={options.map((option) => option)}
          isOptionEqualToValue={(option, value) => option[keyId] === value[keyId]}
          getOptionLabel={(option) => {
            if (!!prefix) {
              return !!option[keyValue] ? t(`${prefix}${option[keyValue]}`) : '';
            } else {
              if (labelWithId) {
                return !!option[keyValue] ? `${option[keyId]} - ${option[keyValue]}` : '';
              } else if (labelFirstWithId) {
                return !!option[keyValue] ? `${option[keyValue]} - ${option[keyId]}` : '';
              } else {
                return !!option[keyValue] ? option[keyValue] : '';
              }
            }
          }}
          renderOption={(props, option, { selected }) => {
            if (multiple) {
              return (
                <Box
                  sx={{ minHeight: 'auto!important' }}
                  component="li"
                  {...props}
                  key={`${name}-li-${option[keyId]}`}
                >
                  <Checkbox
                    sx={{ padding: '0', width: 24, height: 24, marginRight: 1 }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Box component='span' sx={{
                    display: 'block',
                    width: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                    {prefix ? t(`${prefix}${option[keyValue]}`) : labelWithId ? `${option[keyId]} - ${option[keyValue]}` : labelFirstWithId ? `${option[keyValue]} - ${option[keyId]}` : option[keyValue]}
                  </Box>
                </Box>
              )
            } else {
              return (
                <Box
                  sx={{ minHeight: 'auto!important' }}
                  component="li"
                  {...props}
                  key={`${name}-li-${option[keyId]}`}
                >
                  <Box component='span' sx={{
                    display: 'block',
                    width: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                    {prefix ? t(`${prefix}${option[keyValue]}`) : option[keyValue]}
                  </Box>
                </Box>
              )
            }
          }}
          renderTags={(tagValue, getTagProps) => {
            return (
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  variant="filled"
                  label={prefix ? t(`${prefix}${option[keyValue]}`) : labelWithId ? `${option[keyId]} - ${option[keyValue]}` : labelFirstWithId ? `${option[keyValue]} - ${option[keyId]}` : option[keyValue]}
                  {...getTagProps({ index })}
                  sx={{ maxWidth: `${maxWidthChip}px!important`, margin: '4px 2px!important' }}
                />
              ))
            )
          }}
          onChange={(e, val, reason) => {
            if (reason === 'clear') {
              setSelectAll(false);
            }

            onChange((prevState: any) => {
              return {
                ...prevState,
                [`${name}`]: val,
              }
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name={name}
                label={`${label} ${showLabelCounter && multiple && value.length > 0 ? `(${value.length})` : ''}`}
                placeholder={t("common.components.autocomplete.placeholder")}
                autoFocus={autoFocus}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: multiple && !!value.length ? (
                    <Box
                      sx={{
                        paddingRight: multiple && !!value.length ? '30px' : '0px',
                        boxSizing: 'initial',
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <Scrollbars
                        className={styles.customScroll}
                        autoHide={true}
                        hideTracksWhenNotNeeded={true}
                        autoHeight={true}
                        autoHeightMin={32}
                        autoHeightMax={maxHeightFieldScroll}
                        renderView={props => <div {...props} className="view-select"/>}
                        renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                      >
                        {params.InputProps.startAdornment}
                      </Scrollbars>
                    </Box>
                  ) : undefined,
                  endAdornment: (
                    <React.Fragment>
                      {showLoader ? <CircularProgress className={styles.loader} color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  sx: {
                    paddingTop: '4px!important',
                    paddingBottom: '4px!important',
                    minHeight: '40px',
                  },
                }}
                onChange={(e) => handleChangeInput(e)}
              />
            )
          }}
          PaperComponent={CustomPaper}
          ListboxProps={{
            style: { maxHeight: MAX_HEIGHT },
            role: "list-box",
            onScroll: (e) => {
              const listboxNode = e.currentTarget;
              if ((listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight - 2) {
                fetchData();
              }
            },
          }}
        />
      </Box>
    </AutocompleteSelectContext.Provider>
  );
};

export default MuiAutocompleteSelectLazy;
