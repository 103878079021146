//core
import React, {useCallback, useContext, useEffect, useState} from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {
  Box,
  Button,
  SelectChangeEvent,
  useMediaQuery,
  InputLabel,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//utils
import { getDataIds } from "../../../../admin/Reports/utils";

//hooks
import { useDashboardV2 } from "../../../../../store/admin/dashboardV2/useDashboardV2";
import { useRegistration } from "../../../../../store/common/registration/useRegistration";
import { useUser } from "../../../../../store/common/user/useUser";

//components
import { IDashboardFilterType } from "../../types";
import MuiAutocompleteSelectLazy from "../../../../UI/Fields/MuiAutocompleteSelectLazy";
import MuiAutocompleteSelect from "../../../../UI/Fields/MuiAutocompleteSelect";
import CircularLoader from "../../../../UI/CircularLoader";
import { GlobalContext } from "../../../../../context/GlobalContext";

interface IFilterProps {
  initialFilter: IDashboardFilterType;
  setUpdateFilter: React.Dispatch<React.SetStateAction<IDashboardFilterType>>;
  availableFields: string[];
  setToggleDrawer: (data: boolean) => void;
}

const Filter: React.FC<IFilterProps> = ({
  initialFilter,
  setUpdateFilter,
  availableFields,
  setToggleDrawer,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const context = useContext(GlobalContext);

  const { countries } = useRegistration();
  const { user } = useUser();
  const {
    getAffiliates,
    getCreatives,
    getTrackers,
    getBrands,
  } = useDashboardV2();

  const [dataCountries, setDataCountries] = useState<any | null>(null);
  const [filter, setFilter] = useState<IDashboardFilterType>(context.dashboardV2Filter);

  useEffect(() => {
    if (!!countries.data) {
      const data = countries.data.reduce((acc: any[], item: any) => {
        acc.push({
          id: item.code,
          value: t(`common.components.creative.list_geolocales.${item.code}`),
        });
        return acc;
      }, []);
      setDataCountries({
        list: data,
        total_entries: data.length,
      });
    }
  }, [countries]);

  useEffect(() => {
    context.setDashboardV2Filter(filter);
  }, [filter]);

  const handleApplyFilter = useCallback(() => {
    setUpdateFilter(filter);
    setToggleDrawer(false);
  }, [filter]);

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const otherFieldPayload = () => {
    return {
      namespace: user.user.namespace,
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const trackersFieldPayload = () => {
    return {
      namespace: user.user.namespace,
      affiliate_ids: filter.affiliateIds.length ? getDataIds(filter.affiliateIds) : null,
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  return (<>
    <Box className={styles.wrapper}>
      {!!dataCountries ? (
        <>
          <Scrollbars
            className={styles.customScroll}
            style={{ overflow: "hidden" }}
            hideTracksWhenNotNeeded={true}
            renderView={props => <div {...props} className="view"/>}
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
          >
            {availableFields.includes("geo") && (
              <Box className={styles.box}>
                <MuiAutocompleteSelect
                  keyValue="value"
                  keyId="id"
                  id="geo"
                  name="geo"
                  label={t(`admin.reports.filter.geo`)}
                  value={filter.geo}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  staticOptions={dataCountries.list}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  showLabelCounter={true}
                />
              </Box>
            )}

            {availableFields.includes("affiliateIds") && (
              <Box className={styles.box}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="affiliateIds"
                  name="affiliateIds"
                  label={t(`admin.reports.filter.aff_id`)}
                  value={filter.affiliateIds}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getAffiliates}
                  payloadData={fieldPayload()}
                  labelWithId={true}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  showLabelCounter={true}
                />
              </Box>
            )}

            {availableFields.includes("creativeIds") && (
              <Box className={styles.box}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="creativeIds"
                  name="creativeIds"
                  label={t(`admin.reports.filter.creative_id`)}
                  value={filter.creativeIds}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getCreatives}
                  payloadData={otherFieldPayload()}
                  labelWithId={false}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  showLabelCounter={true}
                />
              </Box>
            )}

            {availableFields.includes("trackerIds") && (
              <Box className={styles.box}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="trackerIds"
                  name="trackerIds"
                  label={t(`admin.reports.filter.tracker_id`)}
                  value={filter.trackerIds}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getTrackers}
                  payloadData={trackersFieldPayload()}
                  labelWithId={false}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  showLabelCounter={true}
                />
              </Box>
            )}

            {availableFields.includes("brands") && (
              <Box className={styles.box}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="brands"
                  name="brands"
                  label={t(`admin.reports.filter.brands`)}
                  value={filter.brands}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getBrands}
                  payloadData={otherFieldPayload()}
                  labelWithId={true}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  showLabelCounter={true}
                />
              </Box>
            )}
          </Scrollbars>

          <Box className={styles.actions}>
            <Box className={styles.actionsWrapper}>
              <Button
                className={styles.button}
                fullWidth
                disableElevation
                type="submit"
                variant="contained"
                onClick={handleApplyFilter}
                disabled={arePropsEqual(initialFilter, filter)}
              >
                {t("common.buttons.apply")}
              </Button>
              <Button
                className={styles.button}
                color="secondary"
                disableElevation
                type="button"
                variant="outlined"
                onClick={() => setToggleDrawer(false)}
              >
                {t("common.buttons.cancel")}
              </Button>
            </Box>
          </Box>
        </>
      ) : (<>
        <CircularLoader />
      </>)}

    </Box>
  </>);
};

export default Filter;
