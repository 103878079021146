import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";

const getPackage = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.billing.package;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createUpdatePackage = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.billing.create_update;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getHistory = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.billing.history_changes;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getNgrList = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.billing.package_by_brand;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const billingService = {
  getPackage,
  createUpdatePackage,
  getHistory,
  getNgrList,
}
