//core
import React, {useCallback, useState, useEffect, useContext} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

//styles
import { useStyles } from "./styles";
import { useHeaderStyles } from "../../../../UI/PageHeaderStyles/styles";

//hooks
import useDidMountEffect from "../../../../../customHooks/useDidMountEffect";
import { useEmployees } from "../../../../../store/admin/employees/useEmployees";
import { usePermissions } from "../../../../../store/common/configuration/usePermissions";

//selectors
import {
  selectListData,
  selectAddData,
  selectRemoveData,
  selectInviteData,
  selectBlockData,
  selectEditData,
  selectListDataColumnsSort,
  selectListError,
} from "../../../../../store/admin/employees/selectors";

//context
import { GlobalContext } from "../../../../../context/GlobalContext";

//helpers
import { getIds } from "../../../Brands/utils";


//components
import TableData from "../../../../common/TableData";
import { OrderType } from "../../../../affiliate/Trackers";
import PrimarySearch from "../../../../UI/PrimarySearch";
import PageBreadcrumbs from "../PageBreadcrumbs";
import TableActions from "../TableActions";
import MuiModal from "../../../../UI/MuiModal";
import RoleForm from "../RoleForm";
import MobileList from "../MobileList";
import DrawerFilter from "../DrawerFilter";
import ErrorData from "../../../../common/ErrorData";


export interface IEmployeesFilterData {
  [key: string]: any;
}

export const initialFilterData = {
  departments: [],
  status: null,
  //company: [],
  roles: [],
  platforms: [],
}

const DesktopReport = () => {
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');
  const { hasPermissions } = usePermissions();

  const context = useContext(GlobalContext);

  const isPermissionCreate = hasPermissions(["api2.admin.personalscontroller.create"]);
  const isPermissionEditBtn = hasPermissions(["api2.admin.personalscontroller.edit"]);
  const isPermissionBanBtn = hasPermissions(["api2.admin.personalscontroller.change_ban_status"]);
  const isPermissionRemoveBtn = hasPermissions(["api2.admin.personalscontroller.delete"]);

  const [actionsCount, setActionsCount] = useState<number>(0);

  /* action calculation */
  useEffect(() => {
    if (isPermissionEditBtn) {
      setActionsCount((prevState) => {
        return prevState + 2
      });
    }
  }, [isPermissionEditBtn]);
  useEffect(() => {
    if (isPermissionBanBtn) {
      setActionsCount((prevState) => {
        return prevState + 1
      });
    }
  }, [isPermissionBanBtn]);
  useEffect(() => {
    if (isPermissionRemoveBtn) {
      setActionsCount((prevState) => {
        return prevState + 1
      });
    }
  }, [isPermissionRemoveBtn]);
  /* end action calculation */

  const { getList, resetState, addAdmin, } = useEmployees();

  const data = useSelector(selectListData);
  const error = useSelector(selectListError);
  const sortingColumns = useSelector(selectListDataColumnsSort);
  const addRole = useSelector(selectAddData);
  const removeRole = useSelector(selectRemoveData);
  const inviteRole = useSelector(selectInviteData);
  const blockRole = useSelector(selectBlockData);
  const editRole = useSelector(selectEditData);

  const [filter, setFilter] = useState<IEmployeesFilterData>(context.admEmployeesFilter);
  const [order, setOrder] = useState<OrderType>(context.admEmployeesOrder);
  const [page, setPage] = useState<number>(context.admEmployeesPage);
  const [rowsPerPage, setRowsPerPage] = useState(context.admEmployeesRows);
  const [searchValue, setSearchValue] = useState(context.admEmployeesSearch);
  const [showAddAdminModal, setShowAddAdminModal] = useState<boolean>(false);

  const debouncedValue = useDebounce<string>(searchValue, 500);
  const debouncedMobile = useDebounce<boolean>(mobile, 200);
  const debouncedFilter = useDebounce<IEmployeesFilterData>(filter, 150);

  useEffect(() => {
    const payload = getPayloadData(page+1);
    getList(payload);
  }, [page]);

  useDidMountEffect(() => {
    setPage(0);
    context.setAdmEmployeesPage(0);
    const payload = getPayloadData(1);
    getList(payload);
  }, [rowsPerPage, order, debouncedFilter]);

  useDidMountEffect(() => {
    if (!!addRole) {
      setPage(0);
      context.setAdmEmployeesPage(0);
      const payload = getPayloadData(1);
      getList(payload);
    }
  }, [addRole]);

  useDidMountEffect(() => {
    if (!!removeRole || !!inviteRole || !!blockRole || !!editRole) {
      const payload = getPayloadData(page + 1);
      getList(payload);
    }
  }, [removeRole, inviteRole, blockRole, editRole]);

  useDidMountEffect(() => {
    if (searchValue.length >= 2 || searchValue === '') {
      setPage(0);
      context.setAdmEmployeesPage(0);
      const payload = getPayloadData(1);
      getList(payload);
    }
  }, [debouncedValue]);

  useEffect(() => {
    return () => {
      resetState();
      // if (!mobile) {}
    };
  }, []);

  useEffect(() => {
    if (debouncedMobile) {
      setRowsPerPage(5);
      context.setAdmEmployeesRows(5);
    } else {
      setRowsPerPage(10);
      context.setAdmEmployeesRows(10);
    }
  }, [debouncedMobile]);

  useDidMountEffect(() => {
    context.setAdmEmployeesFilter(filter);
  },[filter]);

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    context.setAdmEmployeesSearch(event.target.value);
  },[setSearchValue]);

  const handleClearSearch = useCallback(() => {
    setSearchValue('');
    context.setAdmEmployeesSearch('');
  }, [setSearchValue]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
    context.setAdmEmployeesPage(newPage);
  }, [setPage]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    context.setAdmEmployeesPage(0);
    setRowsPerPage(+event.target.value);
    context.setAdmEmployeesRows(+event.target.value);
  },[setRowsPerPage, setPage]);

  const handleChangeOrder = useCallback((order: OrderType) => {
    setOrder(order);
    context.setAdmEmployeesOrder(order);
  }, [setOrder]);


  const getPayloadData = (page: number) => {
    return {
      page: page,
      per_page: rowsPerPage,
      order: { ...order },
      filter: {
        ...(!!filter.departments.length ? {departments: getIds(filter.departments)} : {}),
        ...(!filter.status ? {} : {status: filter.status.id === 'status_active'}),
        ...(!!filter.roles.length ? {roles: getIds(filter.roles)} : {}),
        ...(!!filter.platforms.length ? {platforms: getIds(filter.platforms)} : {}),
      },
      search: {
        keyword: !!searchValue && searchValue.length > 0 ? searchValue : null
      }
    }
  }

  const handleToggleAddAdminModal = useCallback((state: boolean) => {
    setShowAddAdminModal(state);
  }, [setShowAddAdminModal]);

  const addNewAdmin = (payload: any) => {
    addAdmin(payload);
  }

  const searchField = <PrimarySearch
    value={searchValue}
    name="search"
    onChange={handleChangeSearch}
    onClear={handleClearSearch}
  />;

  return (
    <>
      <Box className={styles.content}>
        <Box className={headerStyles.header}>
          <Box className={headerStyles.headerTop}>
            <PageBreadcrumbs />
            {!mobile && (
              <Box className={headerStyles.headerSearch}>
                {searchField}
              </Box>
            )}
          </Box>

          <Box className={headerStyles.headerBottom}>
            <Box className={headerStyles.headerBottomWrapper} sx={{ mb: 2 }}>
              {mobile && (
                <Typography className={headerStyles.pageTitle} variant="h6">
                  {t("admin.employees.title")}
                </Typography>
              )}
              {isPermissionCreate && mobile && (
                <Button
                  onClick={()=> {handleToggleAddAdminModal(true)}}
                  disableElevation
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{ width: 'max-content', }}
                >
                  {t("admin.employees.add_employer")}
                </Button>
              )}
            </Box>

            {mobile && (
              <Box className={headerStyles.headerBottomWrapper}>
                <Box className={headerStyles.headerSearch}>
                  {searchField}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <DrawerFilter
          initialFilter={filter}
          setUpdateFilter={setFilter}
          order={order}
          setOrder={handleChangeOrder}
          onCreate={()=> handleToggleAddAdminModal(true)}
        >
          {!mobile && (<>
            <Typography className={headerStyles.pageTitle} variant="h6">
              {t("admin.employees.title")}
            </Typography>
          </>)}
        </DrawerFilter>


        {!!data && (
          <>
            {mobile ? (
              <MobileList
                data={data}
                currentPage={page}
                setPage={handleChangePage}
              />
            ) : (
              <Box sx={{ paddingTop: '4px' }}>
                <TableData
                  list={data.list}
                  columns={data.columns_for_display_data}
                  order={order}
                  setOrder={setOrder}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalEntries={+data.total_entries}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  actionsCount={actionsCount}
                  ActionsComponent={actionsCount === 0 ? null : TableActions}
                  translationColumnsPrefix="columns_for_display.table.employees_report."
                  stickyHeader={true}
                  startRowNum={data.start_row_num}
                  endRowNum={data.end_row_num}
                />
              </Box>
            )}
          </>
        )}
        {
          error && <ErrorData title={error.status} text={error.detail} />
        }

        {/*{
          isPermissionCreate && !mobile && (
            <Box sx={{marginTop: 3}}>
              <Button
                onClick={()=> {handleToggleAddAdminModal(true)}}
                className={styles.btn}
                disableElevation
                type="submit"
                variant="contained"
                size="large"
              >
                {t("admin.employees.add_employer")}
              </Button>
            </Box>
          )
        }*/}
        {isPermissionCreate && (
          <MuiModal
            width={472}
            open={showAddAdminModal}
            closeOnOutside={false}
            setToggleModal={handleToggleAddAdminModal}
          >
            <RoleForm
              onClose={handleToggleAddAdminModal}
              onSubmit={addNewAdmin}
            />
          </MuiModal>
        )}
      </Box>
    </>
  );
};

export default DesktopReport;
