//core
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

//styles
import { useStyles } from "./styles";

import { ISummaryReportFilterDataType as IFilterData } from "../../../../types";
import SelectedField from "../SelectedField";


interface IDataItem {
  [key: string]: any;
}

export interface ISelectedData {
  name: string;
  data: IDataItem[];
  prefix: string;
}

interface ISelectedFiltersProps {
  initialFilter: IFilterData;
  setActiveField: (field: string) => void;
  onDeleteOption: (id: string | number, fieldName: string) => void;
}

const fieldsPrefix: IDataItem = {
  period: "",
  sent_from: "",
  sent_to: "",
  productIds: "",
  managerIds: "",
  affiliateIds: "",
  trackerIds: "",
  creativeIds: "",
  brands: "",
  currencies: "",
  dealTypes: "",
  platformIds: "",
};

const exceptions = ['period', 'sent_from', 'sent_to', 'group_by', 'registrationFrom', 'firstDepositFrom', 'registrationTo', 'firstDepositTo'];

const SelectedFiltersWrapper: React.FC<ISelectedFiltersProps> = ({
  initialFilter,
  setActiveField,
  onDeleteOption,
}): JSX.Element => {
  const styles = useStyles();

  const [selectedData, setSelectedData] = useState<ISelectedData[] | null>(null);

  useEffect(() => {
    const data = Object.keys(initialFilter).reduce((acc: ISelectedData[], item): ISelectedData[] => {
      // @ts-ignore
      if (Array.isArray(initialFilter[item]) && initialFilter[item].length !== 0 || (initialFilter[item] != '') && !(initialFilter[item] === null) && !exceptions.includes(item)) {
        acc.push({
          name: item,
          // @ts-ignore
          data: Array.isArray(initialFilter[item]) ? initialFilter[item] : [initialFilter[item]],
          prefix: `${fieldsPrefix[item]}`,
        })
      }

      return acc;
    }, []);

    setSelectedData(data);
  }, [initialFilter]);

  return (
    <>
      {!!selectedData && (
        <Box className={styles.block}>
          {selectedData.map((item) => (
            <SelectedField
              key={item.name}
              name={item.name}
              data={item}
              setActiveField={setActiveField}
              exceptions={exceptions}
              onDelete={onDeleteOption}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default SelectedFiltersWrapper;
