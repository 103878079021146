//core
import React, {useContext} from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { BillingContext } from "../../../../admin/Billing/components/Desktop/BillingContext";

interface IText {
  value: string | number;
  bold?: boolean;
  prefix?: string;
  whiteSpace?: boolean;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  },
  btn: {
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 16,
    '@media (max-width: 767px)': {
      paddingLeft: 4,
      paddingRight: 4,
    },
    '@media (min-width: 768px)': {
      height: 30,
    }
  },
});

const TextWithBtn: React.FC<IText> = ({
  value = '',
  bold = false,
  prefix,
  whiteSpace = false,
}): JSX.Element  => {
  const styles = useStyles();
  const { t } = useTranslation();

  const context = useContext(BillingContext);

  return (
    <span
      className={`${styles.text} ${whiteSpace ? styles.whiteSpace : ''}`}
      style={{
        fontWeight: bold ? "bold" : "inherit",
      }}
    >
      {prefix ? (
          !!value ? t(`${prefix}${value}`) : '—'
      ): (
        !!value ? (
          value === 'NGR' ? (
            <Box>
              {value}
              <Button
                className={styles.btn}
                size="small"
                color="primary"
                variant="text"
                startIcon={<ErrorOutlineIcon fontSize="small" />}
                onClick={() => context.setShowNgrModal(true)}
              >{t("common.buttons.info")}</Button>
            </Box>
          ) : (
             value
          )
        ) : '—'
      )}
    </span>
  );
};

export default TextWithBtn;
