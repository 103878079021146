import ReactGA from "react-ga4";

interface IUserPropertyProps {
  userId: number,
  platform: string,
}

const setPageview = (value: string) => {
  if (window.hasOwnProperty('dataLayer')) {
    ReactGA.send({ hitType: "pageview", page: value});
  }
};

const setUserId = (value: number | null) => {
  if (window.hasOwnProperty('dataLayer')) {
    ReactGA.set({ userId: value });
  }
};

const setUserProperty = ({ userId, platform }: IUserPropertyProps) => {
  if (window.hasOwnProperty('dataLayer')) {
    ReactGA.gtag("set", "user_properties", {
      userId: userId,
      platform: platform
    });
    ReactGA.gtag('config', `${process.env.REACT_APP_GA}`, {
      'user_id': userId,
    });
  }
};

const setLogin = () => {
  if (window.hasOwnProperty('dataLayer')) {
    ReactGA.gtag("event", "login");
  }
};

const setLogout = () => {
  if (window.hasOwnProperty('dataLayer')) {
    ReactGA.gtag("event", "logout");
  }
};

const setSignUp = () => {
  if (window.hasOwnProperty('dataLayer')) {
    ReactGA.gtag("event", "sign_up");
  }
};

const sendAction = (action: string, value: string) => {
  if (window.hasOwnProperty('dataLayer')) {
    ReactGA.gtag("event", action, value);
  }
};

const logEvent = (category = '', action = '') => {
  if (category && action) {
    if (window.hasOwnProperty('dataLayer')) {
      ReactGA.event({ category, action });
    }
  }
};


export const gaEvents = {
  setPageview,
  setUserId,
  setUserProperty,
  setLogin,
  setLogout,
  setSignUp,
  sendAction,
  logEvent,
}
