//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {twoFactorAuthService} from "../../../services/common/twoFactorAuth.service";
import {setCreateCreativeState, setGeneralProgressHide, setGeneralSnackbarState} from "../ui";

export type twoFactorAuthSliceState = {
  confirmPassword: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  resendCodeEmail: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  enableEmail: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  makeDefaultEmail: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  makeDefault: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  enableOtp: {
    data: any,
    error: any,
    isFetching: boolean,
  },
}

const initialState: twoFactorAuthSliceState = {
  confirmPassword: {
    data: null,
    error: null,
    isFetching: false,
  },
  resendCodeEmail: {
    data: null,
    error: null,
    isFetching: false,
  },
  enableEmail: {
    data: null,
    error: null,
    isFetching: false,
  },
  makeDefaultEmail: {
    data: null,
    error: null,
    isFetching: false,
  },
  makeDefault: {
    data: null,
    error: null,
    isFetching: false,
  },
  enableOtp: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const sendConfirmPasswordData: any = createAsyncThunk(
  'twoFactor/sendConfirmPasswordData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await twoFactorAuthService.sendConfirmPassword(payload);
      const data = await response.json();

      // if (response.status === 200 || (data.hasOwnProperty('two_factor.already_enabled') && data['two_factor.already_enabled'])) {
      //
      //   dispatch(
      //     setGeneralSnackbarState({
      //       open: true,
      //       type: 'info',
      //       message: 'success',
      //       messageKey: 'common.messages.success_send_letter',
      //     })
      //   );
      //   dispatch(setCreateCreativeState(false));
      // }

      if (data.hasOwnProperty('two_factor.already_enabled') && data['two_factor.already_enabled']) {return data}

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const resendConfirmEmailPasswordData: any = createAsyncThunk(
  'twoFactor/resendConfirmEmailPasswordData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await twoFactorAuthService.resendConfirmEmailPassword(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const enableEmailData: any = createAsyncThunk(
  'twoFactor/enableEmailData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await twoFactorAuthService.enableEmail(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const makeDefaultData: any = createAsyncThunk(
  'twoFactor/makeDefaultData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await twoFactorAuthService.makeDefault(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      dispatch(setGeneralProgressHide());

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const enableOtpData: any = createAsyncThunk(
  'twoFactor/enableOtpData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await twoFactorAuthService.enableOtp(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

//slice
const twoFactorAuthSlice = createSlice({
  name: 'two_factor_auth',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearConfirmPasswordData(state) {
      state.confirmPassword.data = null;
      state.confirmPassword.error = null;
      state.confirmPassword.isFetching = false;
    },

    clearEnableEmailData(state) {
      state.enableEmail.data = null;
      state.enableEmail.error = null;
      state.enableEmail.isFetching = false;
    },

    clearEnableOtpData(state) {
      state.enableOtp.data = null;
      state.enableOtp.error = null;
      state.enableOtp.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<twoFactorAuthSliceState>>) => {
    builder.addCase(sendConfirmPasswordData.pending, (state ) => {
      state.confirmPassword.isFetching = true;
      state.confirmPassword.error = null;
    });
    builder.addCase(sendConfirmPasswordData.fulfilled, (state, action ) => {
      state.confirmPassword.error = null;
      state.confirmPassword.isFetching = false;
      state.confirmPassword.data = action.payload;
    });
    builder.addCase(sendConfirmPasswordData.rejected, (state, action ) => {
      state.confirmPassword.error = action.payload;
    });

    builder.addCase(resendConfirmEmailPasswordData.pending, (state ) => {
      state.resendCodeEmail.isFetching = true;
      state.resendCodeEmail.error = null;
    });
    builder.addCase(resendConfirmEmailPasswordData.fulfilled, (state, action ) => {
      state.resendCodeEmail.error = null;
      state.resendCodeEmail.isFetching = false;
      state.resendCodeEmail.data = action.payload;
    });
    builder.addCase(resendConfirmEmailPasswordData.rejected, (state, action ) => {
      state.resendCodeEmail.error = action.payload;
    });

    builder.addCase(enableEmailData.pending, (state ) => {
      state.enableEmail.isFetching = true;
      state.enableEmail.error = null;
    });
    builder.addCase(enableEmailData.fulfilled, (state, action ) => {
      state.enableEmail.error = null;
      state.enableEmail.isFetching = false;
      state.enableEmail.data = action.payload;
    });
    builder.addCase(enableEmailData.rejected, (state, action ) => {
      state.enableEmail.error = action.payload;
    });

    builder.addCase(makeDefaultData.pending, (state ) => {
      state.makeDefault.isFetching = true;
      state.makeDefault.error = null;
    });
    builder.addCase(makeDefaultData.fulfilled, (state, action ) => {
      state.makeDefault.error = null;
      state.makeDefault.isFetching = false;
      state.makeDefault.data = action.payload;
    });
    builder.addCase(makeDefaultData.rejected, (state, action ) => {
      state.makeDefault.error = action.payload;
    });

    builder.addCase(enableOtpData.pending, (state ) => {
      state.enableOtp.isFetching = true;
      state.enableOtp.error = null;
    });
    builder.addCase(enableOtpData.fulfilled, (state, action ) => {
      state.enableOtp.error = null;
      state.enableOtp.isFetching = false;
      state.enableOtp.data = action.payload;
    });
    builder.addCase(enableOtpData.rejected, (state, action ) => {
      state.enableOtp.error = action.payload;
    });
  },
});

export default twoFactorAuthSlice.reducer;

export const {
  reset,
  clearConfirmPasswordData,
  clearEnableEmailData,
  clearEnableOtpData,
} = twoFactorAuthSlice.actions;
