//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";
import {setUser} from "../../common/user";
import {trackersService} from "../../../services/affiliate/trackers.service";
import {setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState} from "../../common/ui";

export type trackersListItem = {
  id: number | string;
  tracker_code: number | string;
  name: string;
  assigning_at: string;
  description: string;
  type_of_transaction: string;
}

export type trackersColumnsItem = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
}

export interface ITrackersData {
  list: trackersListItem[];
  columns: trackersColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  available_trackers: number;
}

export interface IGeoItem {
  id: string;
  label: string;
}

export interface ILanguageItem {
  id: string;
  label: string;
}
export interface IPreviewData {
  url: string;
  recommended: boolean;
}

export interface trackerCreativesListItem {
  atBeginningShow: string;
  atEndShow: string;
  concept: string;
  createdAt: string;
  createdById: number;
  createdByName: string;
  finalUrl: string;
  geo: IGeoItem[];
  id: number;
  language: ILanguageItem[];
  name: string;
  preview: IPreviewData;
  previewComplete: IPreviewData;
  product: string;
  status: string;
  updatedAt: string;
  updatedById: number;
  updatedByName: string;
  trackingLinkId?: number | string | null;
}

export interface trackerCreativesColumnsItem {
  name: string;
  type: string;
  sort: boolean;
}

export interface ITrackerCreativesData {
  list: trackerCreativesListItem[];
  columns: trackerCreativesColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  available_trackers: number;
}

export type trackersSliceState = {
  list: {
    data: ITrackersData | null,
    error: any,
    isFetching: boolean,
  },
  archivedList: {
    data: ITrackersData | null,
    error: any,
    isFetching: boolean,
  },
  tracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trackingLinksFilter: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trackerCreativesList: {
    data: ITrackerCreativesData | null,
    error: any,
    isFetching: boolean,
  },
  trackerCreativesMyList: {
    data: ITrackerCreativesData | null,
    error: any,
    isFetching: boolean,
  },
  create: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  editName: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  defaultCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  archiveTracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  unArchiveTracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  deleteTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  saveTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  editTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
}

const initialState: trackersSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  archivedList: {
    data: null,
    error: null,
    isFetching: false,
  },
  tracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackingLinksFilter: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackerCreativesList: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackerCreativesMyList: {
    data: null,
    error: null,
    isFetching: false,
  },
  create: {
    data: null,
    error: null,
    isFetching: false,
  },
  editName: {
    data: null,
    error: null,
    isFetching: false,
  },
  defaultCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  archiveTracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  unArchiveTracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  createTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  deleteTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  saveTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  editTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getTrackersListData: any = createAsyncThunk(
  'trackers/getListData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getArchivedTrackersListData: any = createAsyncThunk(
  'trackers/getArchivedListData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackerById: any = createAsyncThunk(
  'trackers/getTrackerById',
  async (payload: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getTrackerById(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const createNewTrackerData: any = createAsyncThunk(
  'trackers/createNewTracker',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.createTracker(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateTrackerNameData: any = createAsyncThunk(
  'trackers/updateTrackerNameData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.updateTrackerName(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getDefaultConditionData: any = createAsyncThunk(
  'trackers/getDefaultConditionData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getDefaultCondition();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const putArchiveTracker: any = createAsyncThunk(
  'trackers/putArchiveTracker',
  async (payload: string | number, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.archiveTracker(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.archived_tracker',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const putUnArchiveTracker: any = createAsyncThunk(
  'trackers/putUnArchiveTracker',
  async (payload: string | number, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.unArchiveTracker(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.unarchived_tracker',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackingLinksFilterData: any = createAsyncThunk(
  'trackers/getTrackingLinksFilterData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getTrackingLinksFilter();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackerCreativesListData: any = createAsyncThunk(
  'trackers/getTrackerCreativesListData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await trackersService.getTrackerCreativesList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getTrackerCreativesMyListData: any = createAsyncThunk(
  'trackers/getTrackerCreativesMyListData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await trackersService.getTrackerCreativesMyList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getCreateTrackingLinkData: any = createAsyncThunk(
  'trackers/getCreateTrackingLinkData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getCreateTrackingLink();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const saveTrackingLinkData: any = createAsyncThunk(
  'trackers/saveTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.saveTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateTrackingLinkData: any = createAsyncThunk(
  'trackers/updateTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.saveTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getEditTrackingLinkData: any = createAsyncThunk(
  'trackers/getEditTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getEditTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const deleteTrackingLinkData: any = createAsyncThunk(
  'trackers/deleteTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.deleteTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success_deleted_tracking_link',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const trackersSlice = createSlice({
  name: 'trackers',
  initialState: initialState,
  reducers: {
    setTrackersData(state, action) {
      state.list.data = action.payload;
    },
    setTrackerNameData(state, action) {
      state.tracker.data.tracker.name = action.payload;
    },
    setTrackerNameStateClear(state) {
      state.editName.error = null;
      state.editName.isFetching = false;
      state.editName.data = null;
    },
    setCreateTrackerData(state, action) {
      state.create.data = action.payload;
    },
    clearArchiveTrackerState(state) {
      state.archiveTracker.error = null;
      state.archiveTracker.isFetching = false;
      state.archiveTracker.data = null;
    },
    clearUnArchiveTrackerState(state) {
      state.unArchiveTracker.error = null;
      state.unArchiveTracker.isFetching = false;
      state.unArchiveTracker.data = null;
    },
    setCreativesListData(state, action) {
      state.trackerCreativesList.data = action.payload;
    },
    setCreativesMyListData(state, action) {
      state.trackerCreativesMyList.data = action.payload;
    },
    clearSaveTrackingLinkState(state) {
      state.saveTrackingLink.error = null;
      state.saveTrackingLink.isFetching = false;
      state.saveTrackingLink.data = null;
    },
    clearUpdateTrackingLinkState(state) {
      state.updateTrackingLink.error = null;
      state.updateTrackingLink.isFetching = false;
      state.updateTrackingLink.data = null;
    },
    clearEditTrackingLinkState(state) {
      state.editTrackingLink.error = null;
      state.editTrackingLink.isFetching = false;
      state.editTrackingLink.data = null;
    },
    clearCreateState(state) {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = null;
    },
    clearDeleteState(state) {
      state.deleteTrackingLink.error = null;
      state.deleteTrackingLink.isFetching = false;
      state.deleteTrackingLink.data = null;
    },
    clearTrackerDataState(state) {
      state.tracker.error = null;
      state.tracker.isFetching = false;
      state.tracker.data = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<trackersSliceState>>) => {
    builder.addCase(getTrackersListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getTrackersListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getTrackersListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
      state.list.data = null;
    });

    builder.addCase(getArchivedTrackersListData.pending, (state ) => {
      state.archivedList.isFetching = true;
      state.archivedList.error = null;
    });
    builder.addCase(getArchivedTrackersListData.fulfilled, (state , action) => {
      state.archivedList.error = null;
      state.archivedList.isFetching = false;
      state.archivedList.data = action.payload;
    });
    builder.addCase(getArchivedTrackersListData.rejected, (state , action) => {
      state.archivedList.isFetching = false;
      state.archivedList.error = action.payload;
      state.archivedList.data = null;
    });

    builder.addCase(getTrackerById.pending, (state ) => {
      state.tracker.isFetching = true;
      state.tracker.error = null;
    });
    builder.addCase(getTrackerById.fulfilled, (state , action) => {
      state.tracker.error = null;
      state.tracker.isFetching = false;
      state.tracker.data = action.payload;
    });
    builder.addCase(getTrackerById.rejected, (state , action) => {
      state.tracker.isFetching = false;
      state.tracker.error = action.payload;
    });

    builder.addCase(updateTrackerNameData.pending, (state ) => {
      state.editName.isFetching = true;
      state.editName.error = null;
    });
    builder.addCase(updateTrackerNameData.fulfilled, (state , action) => {
      state.editName.error = null;
      state.editName.isFetching = false;
      state.editName.data = action.payload;
    });
    builder.addCase(updateTrackerNameData.rejected, (state , action) => {
      state.editName.isFetching = false;
      state.editName.error = action.payload;
    });

    builder.addCase(createNewTrackerData.pending, (state ) => {
      state.create.isFetching = true;
      state.create.error = null;
    });
    builder.addCase(createNewTrackerData.fulfilled, (state , action) => {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = action.payload;
    });
    builder.addCase(createNewTrackerData.rejected, (state , action) => {
      state.create.isFetching = false;
      state.create.error = action.payload;
    });

    builder.addCase(getDefaultConditionData.pending, (state ) => {
      state.defaultCondition.isFetching = true;
      state.defaultCondition.error = null;
    });
    builder.addCase(getDefaultConditionData.fulfilled, (state , action) => {
      state.defaultCondition.error = null;
      state.defaultCondition.isFetching = false;
      state.defaultCondition.data = action.payload;
    });
    builder.addCase(getDefaultConditionData.rejected, (state , action) => {
      state.defaultCondition.isFetching = false;
      state.defaultCondition.error = action.payload;
    });

    builder.addCase(putArchiveTracker.pending, (state ) => {
      state.archiveTracker.isFetching = true;
      state.archiveTracker.error = null;
    });
    builder.addCase(putArchiveTracker.fulfilled, (state , action) => {
      state.archiveTracker.error = null;
      state.archiveTracker.isFetching = false;
      state.archiveTracker.data = action.payload;
    });
    builder.addCase(putArchiveTracker.rejected, (state , action) => {
      state.archiveTracker.isFetching = false;
      state.archiveTracker.error = action.payload;
    });

    builder.addCase(putUnArchiveTracker.pending, (state ) => {
      state.unArchiveTracker.isFetching = true;
      state.unArchiveTracker.error = null;
    });
    builder.addCase(putUnArchiveTracker.fulfilled, (state , action) => {
      state.unArchiveTracker.error = null;
      state.unArchiveTracker.isFetching = false;
      state.unArchiveTracker.data = action.payload;
    });
    builder.addCase(putUnArchiveTracker.rejected, (state , action) => {
      state.unArchiveTracker.isFetching = false;
      state.unArchiveTracker.error = action.payload;
    });

    builder.addCase(getTrackerCreativesListData.pending, (state ) => {
      state.trackerCreativesList.isFetching = true;
      state.trackerCreativesList.error = null;
    });
    builder.addCase(getTrackerCreativesListData.fulfilled, (state , action) => {
      state.trackerCreativesList.error = null;
      state.trackerCreativesList.isFetching = false;
      state.trackerCreativesList.data = action.payload;
    });
    builder.addCase(getTrackerCreativesListData.rejected, (state , action) => {
      state.trackerCreativesList.isFetching = false;
      state.trackerCreativesList.error = action.payload;
    });

    builder.addCase(getTrackerCreativesMyListData.pending, (state ) => {
      state.trackerCreativesMyList.isFetching = true;
      state.trackerCreativesMyList.error = null;
    });
    builder.addCase(getTrackerCreativesMyListData.fulfilled, (state , action) => {
      state.trackerCreativesMyList.error = null;
      state.trackerCreativesMyList.isFetching = false;
      state.trackerCreativesMyList.data = action.payload;
    });
    builder.addCase(getTrackerCreativesMyListData.rejected, (state , action) => {
      state.trackerCreativesMyList.isFetching = false;
      state.trackerCreativesMyList.error = action.payload;
    });

    builder.addCase(getTrackingLinksFilterData.pending, (state ) => {
      state.trackingLinksFilter.isFetching = true;
      state.trackingLinksFilter.error = null;
    });
    builder.addCase(getTrackingLinksFilterData.fulfilled, (state , action) => {
      state.trackingLinksFilter.error = null;
      state.trackingLinksFilter.isFetching = false;
      state.trackingLinksFilter.data = action.payload;
    });
    builder.addCase(getTrackingLinksFilterData.rejected, (state , action) => {
      state.trackingLinksFilter.isFetching = false;
      state.trackingLinksFilter.error = action.payload;
    });

    builder.addCase(getCreateTrackingLinkData.pending, (state ) => {
      state.createTrackingLink.isFetching = true;
      state.createTrackingLink.error = null;
    });
    builder.addCase(getCreateTrackingLinkData.fulfilled, (state , action) => {
      state.createTrackingLink.error = null;
      state.createTrackingLink.isFetching = false;
      state.createTrackingLink.data = action.payload;
    });
    builder.addCase(getCreateTrackingLinkData.rejected, (state , action) => {
      state.createTrackingLink.isFetching = false;
      state.createTrackingLink.error = action.payload;
    });

    builder.addCase(saveTrackingLinkData.pending, (state ) => {
      state.saveTrackingLink.isFetching = true;
      state.saveTrackingLink.error = null;
    });
    builder.addCase(saveTrackingLinkData.fulfilled, (state , action) => {
      state.saveTrackingLink.error = null;
      state.saveTrackingLink.isFetching = false;
      state.saveTrackingLink.data = action.payload;
    });
    builder.addCase(saveTrackingLinkData.rejected, (state , action) => {
      state.saveTrackingLink.isFetching = false;
      state.saveTrackingLink.error = action.payload;
    });

    builder.addCase(updateTrackingLinkData.pending, (state ) => {
      state.updateTrackingLink.isFetching = true;
      state.updateTrackingLink.error = null;
    });
    builder.addCase(updateTrackingLinkData.fulfilled, (state , action) => {
      state.updateTrackingLink.error = null;
      state.updateTrackingLink.isFetching = false;
      state.updateTrackingLink.data = action.payload;
    });
    builder.addCase(updateTrackingLinkData.rejected, (state , action) => {
      state.updateTrackingLink.isFetching = false;
      state.updateTrackingLink.error = action.payload;
    });

    builder.addCase(getEditTrackingLinkData.pending, (state ) => {
      state.editTrackingLink.isFetching = true;
      state.editTrackingLink.error = null;
    });
    builder.addCase(getEditTrackingLinkData.fulfilled, (state , action) => {
      state.editTrackingLink.error = null;
      state.editTrackingLink.isFetching = false;
      state.editTrackingLink.data = action.payload;
    });
    builder.addCase(getEditTrackingLinkData.rejected, (state , action) => {
      state.editTrackingLink.isFetching = false;
      state.editTrackingLink.error = action.payload;
    });

    builder.addCase(deleteTrackingLinkData.pending, (state ) => {
      state.deleteTrackingLink.isFetching = true;
      state.deleteTrackingLink.error = null;
    });
    builder.addCase(deleteTrackingLinkData.fulfilled, (state , action) => {
      state.deleteTrackingLink.error = null;
      state.deleteTrackingLink.isFetching = false;
      state.deleteTrackingLink.data = action.payload;
    });
    builder.addCase(deleteTrackingLinkData.rejected, (state , action) => {
      state.deleteTrackingLink.isFetching = false;
      state.deleteTrackingLink.data = null;
      state.deleteTrackingLink.error = action.payload;
    });
  }
});

export const {
  setTrackersData,
  setTrackerNameStateClear,
  setTrackerNameData,
  setCreateTrackerData,
  clearArchiveTrackerState,
  clearUnArchiveTrackerState,
  clearSaveTrackingLinkState,
  clearUpdateTrackingLinkState,
  setCreativesListData,
  setCreativesMyListData,
  clearEditTrackingLinkState,
  clearCreateState,
  clearDeleteState,
  clearTrackerDataState,
} = trackersSlice.actions;


export default trackersSlice.reducer;
