import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  formField: {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    '& label': {
      color: 'inherit!important',
    },
    '& fieldset': {
      borderColor: `${theme.palette.other.outlinedBorder}!important`,
    },
  },

  input: {
    '& label': {
      pointerEvents: 'none',
    },
    '& fieldset': {
      borderColor: `${theme.palette.other.outlinedBorder}!important`,
    },
  },

  small: {
    '& input': {
      padding: '2px 14px',
      height: '24px',
      fontSize: '14px',
    },
    '& button': {
      padding: 0,
      marginRight: '-6px',
    },
    '& svg': {
      width: 18,
      height: 18,
    }
  },
}));
