//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import { profileService } from "../../../services/affiliate/profile.service";

//actions
import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../../common/ui";
import {setUser} from "../../common/user";

export type trafficSourcesType = {
  id?: number,
  name?: string,
  extra_info?: string | null
  traffic_source: {
    id: number
  }
};

type countriesType = {
  country_code: string,
}

export type generalDataType = {
  about_affiliate?: string,
  account_number: string,
  affiliate_traffic_sources: trafficSourcesType[],
  agree_receive_emails: boolean,
  birthday: string,
  countries: countriesType[],
  email: string,
  name: string,
  payment_contract_is_used: boolean,
  payment_system: string,
  phone_number: string,
  site_url: string,
  skype: string,
  surname: string,
  unconfirmed_email: boolean | null,
  username: string,
  contact: {
    type: string,
    value: string,
  },
  messenger: string,
  messenger_username: string,
}

export type PaymentItemType = {
  id: number;
  paymentMethod: PaymentMetodType;
  isDefault: boolean;
  value: {
    [key: string]: any;
  }
};

export type PaymentFieldType = {
  type: string;
  name: string;
  regex: string;
}

export type PaymentMetodType = {
  id: number;
  name: string;
  defaultField: string;
  fields: PaymentFieldType[];
};

export type PaymentFieldItemType = {
  id: number;
  isDefault: boolean;
  name: string;
  value: PaymentFieldType[];
  defaultField: string;
};

export type profileSliceState = {
  general: {
    data: generalDataType | null,
    error: any,
    isFetching: boolean,
  },
  updateGeneral: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  updateEmail: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  updatePassword: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  paymentList: {
    data: PaymentItemType[] | null,
    error: any,
    isFetching: boolean,
  },
  paymentMethods: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  createPaymentMethod: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  deletePaymentMethod: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  updatePaymentMethod: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  paymentHistory: {
    data: any,
    error: any,
    isFetching: boolean,
  },
}

const initialState: profileSliceState = {
  general: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateGeneral: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateEmail: {
    data: null,
    error: null,
    isFetching: false,
  },
  updatePassword: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentList: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentMethods: {
    data: null,
    error: null,
    isFetching: false,
  },
  createPaymentMethod: {
    data: null,
    error: null,
    isFetching: false,
  },
  deletePaymentMethod: {
    data: null,
    error: null,
    isFetching: false,
  },
  updatePaymentMethod: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentHistory: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getGeneralData: any = createAsyncThunk(
  'profile/general',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await profileService.getGeneralData();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateGeneralData: any = createAsyncThunk(
  'profile/updateGeneral',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await profileService.updateGeneralData(payload);
      let data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        data = {
          response: data,
          success: true,
        };
        dispatch(getGeneralData());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
      } else {
        data = {
          response: data,
          success: false,
        }
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: data.response.title,
            messageKey: 'common.messages.error',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateNewEmailData: any = createAsyncThunk(
  'profile/updateNewEmail',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await profileService.updateEmailData(payload);
      let data = await response.json();

      if (Array.isArray(data)) {
        data = {
          response: data,
          success: true,
        }
      } else {
        if (data.hasOwnProperty('authorized')) {
          dispatch(setUser(null));
          localStorage.removeItem('user');
        } else {
          data = {
            response: data,
            success: false,
          }
        }
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updatePasswordData: any = createAsyncThunk(
  'profile/updatePassword',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await profileService.updatePasswordData(payload);
      let data = await response.json();

      if (Array.isArray(data)) {
        data = {
          response: data,
          success: true,
        }
      } else {
        if (data.hasOwnProperty('authorized')) {
          dispatch(setUser(null));
          localStorage.removeItem('user');
        } else {
          data = {
            response: data,
            success: false,
          }
        }
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getPaymentListData: any = createAsyncThunk(
  'profile/getPaymentListData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await profileService.getPaymentList();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPaymentMethodsData: any = createAsyncThunk(
  'profile/getPaymentMethodsData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await profileService.getPaymentMethods();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createPaymentMethodData: any = createAsyncThunk(
  'profile/createPaymentMethodData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await profileService.postPaymentMethod(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deletePaymentMethodData: any = createAsyncThunk(
  'profile/deletePaymentMethodData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await profileService.deletePaymentMethod(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updatePaymentMethodData: any = createAsyncThunk(
  'profile/updatePaymentMethodData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await profileService.updatePaymentMethod(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.updated',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPaymentHistoryData: any = createAsyncThunk(
  'profile/getPaymentHistoryData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await profileService.getPaymentHistory(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    clearUpdateEmailState(state) {
      state.updateEmail.data = null;
      state.updateEmail.error = null;
      state.updateEmail.isFetching = false;
    },
    clearUpdatePasswordState(state) {
      state.updatePassword.data = null;
      state.updatePassword.error = null;
      state.updatePassword.isFetching = false;
    },
    clearGeneralState(state) {
      state.general.data = null;
      state.general.error = null;
      state.general.isFetching = false;
    },
    clearPaymentListState(state) {
      state.paymentList.data = null;
      state.paymentList.error = null;
      state.paymentList.isFetching = false;
    },
    clearPaymentMethodsState(state) {
      state.paymentMethods.data = null;
      state.paymentMethods.error = null;
      state.paymentMethods.isFetching = false;
    },
    clearCreatePaymentMethodState(state) {
      state.createPaymentMethod.data = null;
      state.createPaymentMethod.error = null;
      state.createPaymentMethod.isFetching = false;
    },
    clearDeletePaymentMethodState(state) {
      state.deletePaymentMethod.data = null;
      state.deletePaymentMethod.error = null;
      state.deletePaymentMethod.isFetching = false;
    },
    clearUpdatePaymentMethodState(state) {
      state.updatePaymentMethod.data = null;
      state.updatePaymentMethod.error = null;
      state.updatePaymentMethod.isFetching = false;
    },
    clearPaymentHistoryState(state) {
      state.paymentHistory.data = null;
      state.paymentHistory.error = null;
      state.paymentHistory.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<profileSliceState>>) => {
    builder.addCase(getGeneralData.pending, (state ) => {
      state.general.isFetching = true;
      state.general.error = null;
    });
    builder.addCase(getGeneralData.fulfilled, (state, action: any) => {
      state.general.error = null;
      state.general.isFetching = false;
      state.general.data = action.payload.affiliate_attributes;
    });
    builder.addCase(getGeneralData.rejected, (state, action) => {
      state.general.error = action.payload;
    });

    builder.addCase(updateGeneralData.pending, (state) => {
      state.updateGeneral.isFetching = true;
      state.updateGeneral.error = null;
    });
    builder.addCase(updateGeneralData.fulfilled, (state, action) => {
      state.updateGeneral.error = null;
      state.updateGeneral.isFetching = false;
      state.updateGeneral.data = action.payload;
    });
    builder.addCase(updateGeneralData.rejected, (state, action) => {
      state.updateGeneral.error = action.payload;
    });

    builder.addCase(updateNewEmailData.pending, (state) => {
      state.updateEmail.isFetching = true;
      state.updateEmail.error = null;
    });
    builder.addCase(updateNewEmailData.fulfilled, (state, action) => {
      state.updateEmail.error = null;
      state.updateEmail.isFetching = false;
      state.updateEmail.data = action.payload;
    });
    builder.addCase(updateNewEmailData.rejected, (state, action) => {
      state.updateEmail.error = action.payload;
    });

    builder.addCase(updatePasswordData.pending, (state) => {
      state.updatePassword.isFetching = true;
      state.updatePassword.error = null;
    });
    builder.addCase(updatePasswordData.fulfilled, (state, action) => {
      state.updatePassword.error = null;
      state.updatePassword.isFetching = false;
      state.updatePassword.data = action.payload;
    });
    builder.addCase(updatePasswordData.rejected, (state, action) => {
      state.updatePassword.error = action.payload;
    });

    //getPaymentListData
    builder.addCase(getPaymentListData.pending, (state ) => {
      state.paymentList.isFetching = true;
      state.paymentList.error = null;
    });
    builder.addCase(getPaymentListData.fulfilled, (state , action) => {
      state.paymentList.error = null;
      state.paymentList.isFetching = false;
      state.paymentList.data = action.payload.list;
    });
    builder.addCase(getPaymentListData.rejected, (state , action) => {
      state.paymentList.isFetching = false;
      state.paymentList.error = action.payload;
    });
    builder.addCase(getPaymentMethodsData.pending, (state ) => {
      state.paymentMethods.isFetching = true;
      state.paymentMethods.error = null;
    });
    builder.addCase(getPaymentMethodsData.fulfilled, (state , action) => {
      state.paymentMethods.error = null;
      state.paymentMethods.isFetching = false;
      state.paymentMethods.data = action.payload;
    });
    builder.addCase(getPaymentMethodsData.rejected, (state , action) => {
      state.paymentMethods.isFetching = false;
      state.paymentMethods.error = action.payload;
    });

    builder.addCase(createPaymentMethodData.pending, (state ) => {
      state.createPaymentMethod.isFetching = true;
      state.createPaymentMethod.error = null;
    });
    builder.addCase(createPaymentMethodData.fulfilled, (state , action) => {
      state.createPaymentMethod.error = null;
      state.createPaymentMethod.isFetching = false;
      state.createPaymentMethod.data = action.payload;
    });
    builder.addCase(createPaymentMethodData.rejected, (state , action) => {
      state.createPaymentMethod.isFetching = false;
      state.createPaymentMethod.error = action.payload;
    });

    builder.addCase(deletePaymentMethodData.pending, (state ) => {
      state.deletePaymentMethod.isFetching = true;
      state.deletePaymentMethod.error = null;
    });
    builder.addCase(deletePaymentMethodData.fulfilled, (state , action) => {
      state.deletePaymentMethod.error = null;
      state.deletePaymentMethod.isFetching = false;
      state.deletePaymentMethod.data = action.payload;
    });
    builder.addCase(deletePaymentMethodData.rejected, (state , action) => {
      state.deletePaymentMethod.isFetching = false;
      state.deletePaymentMethod.error = action.payload;
    });

    builder.addCase(updatePaymentMethodData.pending, (state ) => {
      state.updatePaymentMethod.isFetching = true;
      state.updatePaymentMethod.error = null;
    });
    builder.addCase(updatePaymentMethodData.fulfilled, (state , action) => {
      state.updatePaymentMethod.error = null;
      state.updatePaymentMethod.isFetching = false;
      state.updatePaymentMethod.data = action.payload;
    });
    builder.addCase(updatePaymentMethodData.rejected, (state , action) => {
      state.updatePaymentMethod.isFetching = false;
      state.updatePaymentMethod.error = action.payload;
    });

    builder.addCase(getPaymentHistoryData.pending, (state ) => {
      state.paymentHistory.isFetching = true;
      state.paymentHistory.error = null;
    });
    builder.addCase(getPaymentHistoryData.fulfilled, (state , action) => {
      state.paymentHistory.error = null;
      state.paymentHistory.isFetching = false;
      state.paymentHistory.data = action.payload;
    });
    builder.addCase(getPaymentHistoryData.rejected, (state , action) => {
      state.paymentHistory.isFetching = false;
      state.paymentHistory.error = action.payload;
    });
  }
});

export default profileSlice.reducer;

export const {
  clearUpdateEmailState,
  clearUpdatePasswordState,
  clearGeneralState,
  clearPaymentListState,
  clearPaymentMethodsState,
  clearCreatePaymentMethodState,
  clearDeletePaymentMethodState,
  clearUpdatePaymentMethodState,
  clearPaymentHistoryState,
} = profileSlice.actions;
