//core
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import {
  Box,
  FormControlLabel,
  Checkbox, Button,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//helpers
import {isObjectEmpty} from "../../../../helpers/isObjectEmpty";

//validation
import { validationTextField as validationTextFieldMax } from "../../../validation/textFieldMax.validation";

//hooks
import { useStorage } from "../../../../store/common/storage/useStorage";

//components
import MuiTextField from "../../../UI/FormFields/MuiTextField";

interface ISaveFilterFormProps {
  filter: {
    [key: string]: any;
  };
  type: string;
  target: string;
  exceptions: string[],
  exceptionsIds: string[],
  disabled: boolean;
}

const SaveFilterForm: React.FC<ISaveFilterFormProps> = ({
  filter,
  type,
  target,
  exceptions,
  exceptionsIds,
  disabled,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const formRef: any = useRef();

  const { createItem } = useStorage();

  const [checked, setChecked] = useState(false);
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [initialValues, setInitialValues] = useState<any>({
    name: '',
  });

  // validation errors translation
  const [errorsMessages, setErrorsMessages] = useState({
    required: t("common.validation.required_field"),
    maxLength: t("common.validation.max_length"),
  });

  useEffect(() => {
    setErrorsMessages({
      required: t("common.validation.required_field"),
      maxLength: t("common.validation.max_length"),
    });

    if (formRef.current) {
      if (!isObjectEmpty(formRef.current.errors)) {
        setTimeout(() => {
          formRef.current.validateForm();
        }, 50)
      }
    }
  }, [i18n.language, t]);

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    const payload = {
      type,
      target,
      payload: {
        name: values.name,
        data: JSON.stringify(getFilterData()),
      }
    };
    createItem(payload);

    actions.setSubmitting(false);
    if (formRef.current) {
      formRef.current.resetForm();
    }
    setChecked(false);
  };

  const getFilterData = useCallback(() => {
    return Object.keys(filter).reduce((acc: any, item: string) => {
        if (!exceptions.includes(item)) {
          //@ts-ignore
          if (exceptionsIds.includes(item)) {
            // get Ids
            acc[item] = filter[item];
          } else {
            acc[item] = filter[item];
          }
        }
        return acc;
      }, {})
  }, [filter, exceptions]);

  const validateName = (value: any) => validationTextFieldMax(value, 255, errorsMessages);


  return (
    <Box className={styles.block}>
      <Box className={styles.checkbox}>
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox
            checked={checked}
            onChange={handleChangeCheckbox}
            inputProps={{ 'aria-label': 'controlled' }}
          />}
          label={t("common.forms.fields.add_to_my_filter")}
        />
      </Box>
      {checked && (
        <>
          <Box className={styles.form}>
            <Formik
              validateOnChange
              validateOnBlur
              validateOnMount
              enableReinitialize
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={ (values, actions) => submitForm(values, actions) }
            >
              {props => {
                const { values, handleSubmit, handleChange, isValid, dirty } = props;
                const disableSubmit = isValid ? !dirty : true;

                return (
                  <form onSubmit={handleSubmit}>
                    <Box className={styles.group}>
                      <Box className={styles.field}>
                        <MuiTextField
                          size="small"
                          id="name"
                          type="text"
                          name="name"
                          autoComplete="name"
                          label={t("common.forms.fields.filter_name")}
                          value={values.name}
                          validate={validateName}
                          onChange={handleChange}
                          autoFocus={true}
                        />
                      </Box>
                      <Box className={styles.actions}>
                        <Button
                          disabled={disableSubmit}
                          className={styles.button}
                          type="submit"
                          variant="contained"
                        >
                          {t("common.buttons.save")}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )
              }}
            </Formik>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SaveFilterForm;
