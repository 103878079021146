//core
import React from "react";
import {makeStyles, useTheme} from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";
import {Box, LinearProgress} from "@mui/material";

interface IProgress {
  value: number;
}

export const useStyles = makeStyles<Theme>((theme) => ({
  text: {
    color: 'inherit',
    fontWeight: 'inherit',
    fontSize: 12,
    letterSpacing: '0.4px'
  },
  row: {
    display: "flex",
    alignItems: 'center'
  },
  progress: {
    width: "100%",
    marginRight: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.success.light,

    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.success.main,
    },
  }
}));

const Text: React.FC<IProgress> = ({
  value = 0,
}): JSX.Element  => {
  const styles = useStyles();
  const theme: Theme = useTheme();
  const progressValue = 100 - value;

  return (
    <Box className={styles.row}>
      {
        value === Infinity ? (
          ''
        ) : (
          <LinearProgress
            className={styles.progress}
            variant="determinate"
            value={value ? value : 0}
            color={'primary'}
            {...(value > 100 ? {
              sx: {
              backgroundColor: `${theme.palette.error.main}!important`,
              '& .MuiLinearProgress-barColorPrimary': {transform: `translateX(${progressValue}%)!important`}
            }
            } : {} )}
          />
        )
      }
      <span
        className={styles.text}
      >
        {
          value === Infinity ? (
            '—'
          ) : (
            `${value ? value : '0'}/100%`
          )
        }
    </span>
    </Box>
  );
};

export default Text;
