//core
import React, {useCallback, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import format from "date-fns/format";
import {
  Box, Button,
  SelectChangeEvent, Typography,
  useMediaQuery,
} from "@mui/material";

//styles
import { useStyles } from "./styles";
import { useHeaderStyles } from "../../../../../UI/PageHeaderStyles/styles";

//hooks
import {
  useCreativeReport,
} from "../../../../../../store/admin/reports/creative_report/useCreativeReport";
import { useUI } from "../../../../../../store/common/ui/useUI";
import useDidMountEffect from "../../../../../../customHooks/useDidMountEffect";
import { usePermissions } from "../../../../../../store/common/configuration/usePermissions";

//selectors
import {
  selectListData,
  selectDownloadData,
  selectOptionsData,
  selectColumnsData,
} from "../../../../../../store/admin/reports/creative_report/selectors";

//utils
import { getDataIds, getOptionsList, getPeriod, getStringIds } from "../../../utils";

//context
import { GlobalContext } from "../../../../../../context/GlobalContext";

//components
import TableData from "../../../../../common/TableData";
import CategorySearch from "../../../components/CategorySearch";
import MuiModal from "../../../../../UI/MuiModal";
import DownloadReport from "../DownloadReport";
import DrawerFilter from "../DrawerFilter";
import { OrderType, IFilterData } from "../../types";
import { TableColumnsItem } from "../../../../../common/FiltersComponents/TableSettings/types";

const initialOrderState: OrderType = {
  date_for_report: 'desc',
};

export const initialFilterData: IFilterData = {
  period: 'month',
  sent_from: getPeriod('month').start,
  sent_to: getPeriod('month').end,
  group_by: {
    fields: [],
  },
  affiliateIds: [],
  dealTypes: [],
  trackerIds: [],
  creativeIds: [],
  geo: [],
};

const DesktopReport = () => {
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const { hasPermissions } = usePermissions();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');

  const context = useContext(GlobalContext);

  const { setGeneralSnackbar } = useUI();

  const {
    getList,
    setDownload,
    clearDownloadState,
    resetState,
    getColumns
  } = useCreativeReport();

  const data = useSelector(selectListData);
  const filterData = useSelector(selectOptionsData);
  const downloadData = useSelector(selectDownloadData);
  const columnsData = useSelector(selectColumnsData);

  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showMobileTooltip, setShowMobileTooltip] = useState<boolean>(false);
  const [mobileFilterIsOpen, setMobileFilterIsOpen] = useState<boolean>(true);
  const [generateReport, setGenerateReport] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const debouncedValue = useDebounce<string>(searchValue, 500);

  const [filter, setFilter] = useState<IFilterData>(initialFilterData);
  const [selectedFilterId, setSelectedFilterId] = useState<string>('');
  const [order, setOrder] = useState<OrderType>(initialOrderState);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [columns, setColumns] = useState<TableColumnsItem[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<TableColumnsItem[]>([]);

  useEffect(() => {
    //get columns data
    if (hasPermissions(["api3.admin.creativeclickreportnewcontroller.sort_columns"])) {
      if (!columns.length) {
        getColumns();
      }
    }
  }, []);

  useEffect(() => {
    if (!!columnsData) {
      const newColumns = columnsData.map((item: string) => {
        return {
          id: item,
          value: item,
          checked: true,
        };
      });

      setColumns(newColumns);
      setDefaultColumns(newColumns);
    }
  }, [columnsData]);

  useEffect(() => {
    if (!!data && !!data.search_fields && !!data.search_fields.length) {
      if (!data.search_fields.includes(searchFilter)) {
        setSearchFilter(getOptionsList(data.search_fields)[0].id);
      }
    }
  }, [data]);

  useDidMountEffect(() => {
    if (!!filter) {
      const payload = getPayloadData(page+1);
      getList(payload);
    }
  }, [page]);

  useDidMountEffect(() => {
    setPage(0);
    if (!!filter) {
      const payload = getPayloadData(1);
      getList(payload);
    }
  }, [rowsPerPage, order]);

  useDidMountEffect(() => {
    if (!!filter) {
      if (searchValue.length >= 2 || searchValue === '') {
        setPage(0);
        const payload = getPayloadData(1);
        getList(payload);
      }
    }
  }, [debouncedValue]);

  useDidMountEffect(() => {
    handleGenerateReport();
  }, [generateReport])

  useEffect(() => {
    return () => {
      resetState();
      context.resetAdmCreativesReportFilter();
    };
  }, []);

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  },[setSearchValue]);

  const handleClearSearch = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  const handleChangeSearchFilter = useCallback((event: SelectChangeEvent) => {
    setSearchFilter(event.target.value);
  }, [setSearchFilter]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, [setPage]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  },[setRowsPerPage, setPage]);

  const handleDownloadReport = useCallback(() => {
    setShowDownloadModal(true);
  }, [setShowDownloadModal]);

  const handleGenerateReport = (): void => {
    setPage(0);
    setOrder(initialOrderState);
    setSearchValue('')
    const payload = getPayloadData(1);
    getList(payload);

    setMobileFilterIsOpen(false);

    if (!showMobileTooltip) {
      setShowMobileTooltip(true);
      if (mobile) {
        setGeneralSnackbar({
          open: true,
          type: 'info',
          messageKey: 'common.messages.report_page_loaded'
        });
      }
    }
  }

  const getPayloadData = (page: number) => {
    const orderedColumns = columns.reduce((acc: string[], item) => {
      if (item.checked) {
        acc.push(item.id);
      }
      return acc;
    }, []);

    const payload = {
      page: page,
      per_page: rowsPerPage,
      order: { ...order },
      ...(hasPermissions(['api3.admin.creativeclickreportnewcontroller.sort_columns']) ? { orderedColumns: orderedColumns } : {}),
      report: {
        period: {
          start: format(new Date(filter.sent_from), 'yyyy-MM-dd'),
          end: format(new Date(filter.sent_to), 'yyyy-MM-dd'),
        },
        group_by: {
          fields: getStringIds(filter.group_by.fields),
        },
        affiliateIds: !!filter.affiliateIds ? getDataIds(filter.affiliateIds) : [],
        creativeIds: !!filter.creativeIds ? getDataIds(filter.creativeIds) : [],
        dealTypes: !!filter.dealTypes ? getDataIds(filter.dealTypes) : [],
        trackerIds: !!filter.trackerIds ? getDataIds(filter.trackerIds) : [],
        geo: !!filter.geo ? getDataIds(filter.geo) : [],
        encoding: "Unicode, 8-bit",
        ...( searchValue.length >= 2 ? {
          search: {
            key: searchFilter,
            value: searchValue,
          }
        } : {})
      },
    };

    return payload;
  }

  return (
    <>
      {mobile && (<>
        <Box className={headerStyles.header}>
          <Box className={headerStyles.headerBottom}>
            <Box className={headerStyles.headerBottomWrapper}>
              <Typography className={headerStyles.pageTitle} variant="h6">{t("affiliate.reports.creative_report.title")}</Typography>
              <Button
                sx={{ width: 'max-content' }}
                disableElevation
                type="submit"
                variant="contained"
                size="small"
                onClick={handleGenerateReport}
              >
                { t("common.buttons.generate") }
              </Button>
            </Box>
          </Box>
        </Box>
      </>)}

      <Box className={styles.filters}>
        <DrawerFilter
          initialFilter={filter}
          setUpdateFilter={setFilter}
          order={order}
          setOrder={setOrder}
          onDownload={handleDownloadReport}
          onGenerateReport={handleGenerateReport}
          setGenerateReport={setGenerateReport}
          columns={columns}
          setColumns={setColumns}
          defaultColumns={defaultColumns}
          selectedFilterId={selectedFilterId}
          setSelectedFilterId={setSelectedFilterId}
        >
          {!mobile && (
            <Typography variant="h6">{t("affiliate.reports.creative_report.title")}</Typography>
          )}
        </DrawerFilter>
      </Box>

      <Box className={headerStyles.contentWrapper}>
        {!!data && (
          <>
            <Box className={styles.tableHeader}>
              <CategorySearch
                hideSearch={!getOptionsList(data.search_fields).length}
                selected={searchFilter}
                options={getOptionsList(data.search_fields)}
                prefix="common.components.report_search_options."
                value={searchValue}
                name="search"
                onChange={handleChangeSearch}
                onClear={handleClearSearch}
                onChangeSelect={handleChangeSearchFilter}
              />
            </Box>
            <Box
              sx={{ paddingTop: !getOptionsList(data.search_fields).length ? 0 : '16px' }}
            >
              <TableData
                list={data.list}
                columns={data.columns}
                totalRow={data.sum_columns}
                order={order}
                setOrder={setOrder}
                page={page}
                rowsPerPage={rowsPerPage}
                totalEntries={+data.total_entries}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actionsCount={1}
                translationColumnsPrefix="columns_for_display.table.generate_report."
                stickyHeader={true}
                detailModalTitle="date_for_report"
                showDetailOnMobile={true}
                startRowNum={data.start_row_num}
                endRowNum={data.end_row_num}
              />
            </Box>
          </>
        )}
      </Box>

      <MuiModal
        open={showDownloadModal}
        closeOnOutside={false}
        setToggleModal={setShowDownloadModal}
      >
        {!!filterData && !!filter && <DownloadReport
          order={order}
          columns={columns}
          downloadOnThisPage={false}
          downloadData={downloadData}
          encodingTypes={filterData.encoding_types}
          separatorsForFormat={filterData.separators_for_format}
          filter={filter}
          setToggleModal={setShowDownloadModal}
          setDownload={setDownload}
          clearDownloadState={clearDownloadState}
        />}
      </MuiModal>
    </>
  );
};

export default DesktopReport;
