//core
import React, { useEffect, useState, useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";
import {darken, rgbToHex} from "@mui/material/styles";
import { Box, ClickAwayListener, TableCell, TableRow, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDoubleTap } from 'use-double-tap';
import {v4} from "uuid";

//theme
import {Theme} from "@mui/material/styles/createTheme";

//components
import Text from "../Text";
import Integer from "../Integer";
import Name from "../Name";
import ObjArray from "../ObjArray";
import CountriesArray from "../CountriesArray";
import ArrayOfStrings from "../ArrayOfStrings";
import Status from "../Status";
import PartnerStatus from "../PartnerStatus";
import Link from "../Link";
import DateTime from "../DateTime";
import DateTimeRange from "../DateTimeRange";
import TranslationText from "../TranslationText";
import FloatFormat from "../FloatFormat";
import DateRange from "../DateRange";
import Preview from "../Preview";
import BadgeField from "../BadgeField";
import CheckedField from "../CheckedField";
import HideSwitcher from "../../../../admin/Creatives/components/TableActions/HideSwicher";
import HideSwitcherV2 from "../../../../admin/CreativesV2/components/TableActions/HideSwicherV2";
import Adjustment from "../Adjustment";
import Switcher from "../../../../affiliate/s2s/components/TableActions/switcher";
import EmailLink from "../EmailLink";
import TooltipText from "../TooltipText";
import SelectedFinReportRow from "../SelectedFinReportRow";
import Contact from "../Contact";
import Manager from "../Manager";
import MasterAffiliate from "../MasterAffiliate";
import DepartmentTeam from "../DepartmentTeam";
import Condition from "../Condition";
import FinReportStatus from "../FinReportStatus";
import CommunicationStatus from "../../../../admin/Partners/components/TableActions/CommunicationStatus";
import CommunicationDate from "../../../../admin/Partners/components/TableActions/CommunicationDate";
import TrafficStatus from "../../../../admin/Partners/components/TableActions/TrafficStatus";
import DetailFinReportRowComment from "../../../../admin/Reports/Finance_report/components/Details/components/Table/components/DetailFinReportRowComment";
import Boolean from "../Boolean";
import Details from "./components/Details";
import MuiModal from "../../../../UI/MuiModal";
import Progress from "../Progress";
import BillingTooltipOverlay from "../BillingTooltipOverlay";
import BillingFormat from "../BillingFormat";
import IntegerWithZero from "../IntegerWithZero";
import SelectedBrandsDealsRow from "../SelectedBrandsDealsRow";
import PaymentDescription from "../PaymentDescription";
import TextWithBtn from "../TextWithBtn";
import PaymentWallet from "../PaymentWallet";
import LogsItem from "../../../../admin/Reports/Finance_report/components/Details/PaymentDetails/components/Logs/components/logsItem";
import AdjustmentReason from "../AdjustmentReason";
import SelectDealRow
  from "../../../../admin/Partners/components/SelectDeal/components/SelectDealRow";

//hooks
import useEffectOnce from "../../../../../customHooks/useEffectOnce";

//types
import {itemColumnsData, itemListData} from "../../index";
import AdjustmentStatus from "../AdjustmentStatus";
import {getSymbolFromCode} from "currency-code-symbol-map";

const useStyles = makeStyles<Theme>((theme) => ({
  tableCell: {
    padding: '5px 16px',
    height: 40,
    color: 'inherit',
    borderBottom: `1px solid ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
  },
  tableCellFreeze: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    backgroundColor: rgbToHex(darken(theme.palette.background.paper, 0.017)),
    boxShadow: `0px 2px 3px ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
    borderLeft: `1px solid ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
    textAlign: 'left',
    zIndex: 1,
    '&:hover': {
      backgroundColor: rgbToHex(darken(theme.palette.background.paper, 0.036)),
    }
  },
  tableCellTotal: {
    padding: '5px 16px',
    height: 40,
    color: 'inherit',
    backgroundColor: rgbToHex(darken(theme.palette.background.paper, 0.036)),
  },
  tableRow: {
    '&:hover': {
      //backgroundColor: `${darken(theme.palette.background.paper, 0.036)}!important`,
      "&.Mui-selected": {
        backgroundColor: `${darken(theme.palette.selected.main, 0.03)}!important`,
        '& .tableCellFreezeHover': {
          backgroundColor: `${darken(theme.palette.selected.main, 0.03)}!important`,
        }
      }
    },
    '&:hover .tableCellFreezeHover': {
      //backgroundColor: rgbToHex(darken(theme.palette.background.paper, 0.036)),
      //backgroundColor: `${darken(theme.palette.background.paper, 0.036)}!important`,
    },
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.selected.main}`,
      '&:hover': {
        //backgroundColor: `${darken(theme.palette.selected.main, 0.03)}!important`,
      },
      "& .tableCellFreezeHover": {
        backgroundColor: theme.palette.selected.main,
      },
      "&:hover .tableCellFreezeHover": {
        //backgroundColor: theme.palette.selected.main,
      }
    }
  },
  sticky: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    right: 0,
    backgroundColor: rgbToHex(darken(theme.palette.background.paper, 0.017)),
    boxShadow: `0px 2px 3px ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
    borderLeft: `1px solid ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
    textAlign: 'right',
    '&:hover': {
      backgroundColor: rgbToHex(darken(theme.palette.background.paper, 0.036)),
    },
    '&.tableCellCenter': {
      textAlign: 'center!important',
    }
  },
}));

interface ITableRows {
  row: itemListData;
  columns: itemColumnsData[];
  ActionsComponent?: any;
  key?: any;
  total?: boolean;
  freezeColumns?: string[];
  tooltipRow?: boolean;
  dataTooltipRow?: string[];
  tooltipExceptions: string[];
  translationColumnsPrefix: string;
  translationTooltipsPrefix?: string;
  disabled: boolean;
  detailModalTitle?: string;
  showDetailOnMobile?: boolean;
  actionsCount?: number;
  columnCurrency?: any;
}

const TableRowItem = (props: ITableRows) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');

  const [selectedRow, setSelectedRow] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const [freezeColumn, setFreezeColumn] = useState<number[]>([]);
  const [widthFreezeColumns, setWidthFreezeColumns] = useState<any>(0);

  const [showRowDetail, setShowRowDetail] = useState<boolean>(false);

  const {
    row,
    columns,
    ActionsComponent,
    total = false,
    freezeColumns,
    tooltipRow = false,
    dataTooltipRow,
    tooltipExceptions,
    translationColumnsPrefix,
    disabled = false,
    detailModalTitle,
    showDetailOnMobile,
    actionsCount = 1,
    translationTooltipsPrefix = '',
    columnCurrency = null,
  } = props;

  useEffectOnce(() => {
    if (!!columns && !!freezeColumns) {
      columns.forEach((column) => {
        if (freezeColumns?.includes(column.name)) {
          setFreezeColumn((prevState:any) => {
            return [
              ...prevState,
              document?.getElementById(`${styles.tableCellFreeze + '-' + column.name}`)?.offsetWidth
            ]
          });
        }
      })
    }
  })

  useEffect(() => {
    if (!!freezeColumns && !!freezeColumn) {
      const widthArr = freezeColumn.reduce((acc:any, item:number, index:number) => {
        const width = acc[index] + item;
        acc.push(width);
        return acc;
      },[0]);

      setWidthFreezeColumns(widthArr);
    }
  },[freezeColumn])

  const handleTooltipClose = (e:any) => {
    if (tooltipRow) {
      e.stopPropagation();
      setOpen(false);
    }
  };

  const handleTooltipOpen = (e: any) => {
    if (tooltipRow) {
      setOpen(!open);
    }
  };

  const checkException = (e: any, column: string) => {
    tooltipExceptions?.includes(column) && handleTooltipClose(e);
  }

  const tooltipList = dataTooltipRow?.map((item:any) => {
    if (item === "payoutInfo") {
      return <Box key={v4()} sx={{mb: 1, display: "flex"}}>
        <Typography variant="body2" sx={{marginRight: 2}}>{t(`${translationColumnsPrefix}affiliateId`)} : {row["affiliateId"]}</Typography>
        <Typography variant="body2" sx={{marginRight: 1}}>{row["currency"]} - {row["brand"]}</Typography>
      </Box>
    } else {
      return <Box key={v4()} sx={{mb: 1, display: "flex"}}>
        <Typography variant="body2" sx={{marginRight: 1}}>{t(`${translationColumnsPrefix}${item}`).replace('{currency}', (!!columnCurrency && !!columnCurrency.convert_currency && (!!getSymbolFromCode(columnCurrency.convert_currency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.convert_currency.id.toUpperCase()) : columnCurrency.convert_currency.id)) || '$')} : {row[item]}</Typography>
      </Box>
    }
  });

  useEffect(() => {
    return () => {
      setWidthFreezeColumns(0);
      setFreezeColumn([]);
    }
  },[])

  const onDoubleClick = useDoubleTap((event: any) => {
    showDetailOnMobile ? setShowRowDetail(true) : handleTooltipOpen(event);
  });

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          onClose={handleTooltipClose}
          open={open}
          placement="bottom"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          followCursor
          title={
            <Box>
              {tooltipList}
            </Box>
          }
        >
          <TableRow
            className={styles.tableRow}
            selected={selectedRow}
            hover
            role="checkbox"
            tabIndex={-1}
            {...(
              mobile && showDetailOnMobile ? {...onDoubleClick} : {onClick: handleTooltipOpen}
            )}
          >
            {
              columns.map((column, index) => {
                return (
                  <TableCell
                    key={column.name}
                    className={`${styles.tableCell} ${freezeColumns?.includes(column.name) ? `${styles.tableCellFreeze} tableCellFreezeHover` : ''}`}
                    id={`${freezeColumns?.includes(column.name) ? `${styles.tableCellFreeze + '-' + column.name}` : ''}`}
                    align="left"
                    sx={{
                      left: `${freezeColumns?.includes(column.name) && !!freezeColumn  ? `${widthFreezeColumns[index]}px` : '0px'}`
                    }}
                    onClick={
                      freezeColumns?.includes(column.name) && !!freezeColumn
                        ? handleTooltipClose
                        : (e) => checkException(e, column.name)
                    }
                    onPointerMove={
                      freezeColumns?.includes(column.name) && !!freezeColumn
                        ? handleTooltipClose
                        : (e) => checkException(e, column.name)
                    }
                  >
                    { total && index === 0
                      ? (
                        <>{t("common.components.table.total")}</>
                      ) : (<>
                        { column.type === "integer" && <Integer value={row[column.name]} /> }
                        { column.type === "text" && <Text value={row[column.name]} whiteSpace /> }
                        { column.type === "boolean" && <Boolean value={row[column.name]} /> }
                        { column.type === "string" && <Text value={row[column.name]} whiteSpace /> }
                        { column.type === "trackerName" && <TooltipText value={row[column.name]} width={280} maxWidth={280}/> }
                        { column.type === "webhookPayload" && <TooltipText value={row[column.name]} width={350} maxWidth={350}/> }
                        { column.type === "trackerDealName" && <TooltipText value={row[column.name]} width={250} maxWidth={250}/> }
                        { column.type === "S2sName" && <TooltipText value={row[column.name]} width={340} maxWidth={340}/> }
                        { column.type === "invitationLinksName" && <TooltipText value={row[column.name]} width={340} maxWidth={340}/> }
                        { column.type === "S2sUrl" && <TooltipText value={row[column.name]} width={300} maxWidth={300}/> }
                        { column.type === "S2sTrackers" && <ArrayOfStrings value={row[column.name]} maxWidth={200} /> }
                        { column.type === "name" && <Name value={row[column.name]} /> }
                        { column.type === "badge" && <BadgeField value={row[column.name]} /> }
                        { column.type === "toggleCreativeHideField" && <HideSwitcher creative={row} value={row[column.name]} /> }
                        { column.type === "toggleCreativeHideFieldV2" && <HideSwitcherV2 creative={row} value={row[column.name]} /> }
                        { column.type === "date" && <DateTime value={row[column.name]} /> }
                        { column.type === "datetime" && <DateTimeRange value={row[column.name]} /> }
                        { column.type === "fulldatetime" && <DateTimeRange value={row[column.name]} fullDate /> }
                        { column.type === "float" && <FloatFormat value={row[column.name]} /> }
                        { column.type === "highlightedFloat" && <FloatFormat value={row[column.name]} color={'#EC4A00'} /> }
                        { column.type === "dateRange" && <DateRange value={row[column.name]} /> }
                        { column.type === "status" && <Status value={row[column.name]} /> }
                        { column.type === "detailsFinReportStatus" && <FinReportStatus value={row[column.name]} /> }
                        { column.type === "partnerStatus" && <PartnerStatus value={row[column.name]} isBlocked={row.is_blocked} /> }
                        { column.type === "affiliateStatus" && <Status value={row[column.name]} /> }
                        { column.type === "link" && <Link value={row[column.name]} /> }
                        { column.type === "arrayOfStrings" && <ArrayOfStrings value={row[column.name]} maxWidth={140} /> }
                        { column.type === "mediumArrayOfStrings" && <ArrayOfStrings value={row[column.name]} maxWidth={240} /> }
                        { column.type === "geoArray" && <ObjArray prefix="common.components.creative.list_geolocales." value={row[column.name]} maxWidth={84} /> }
                        { column.type === "countriesArray" && <CountriesArray prefix="common.components.creative.list_geolocales." value={row[column.name]} maxWidth={230} /> }
                        { column.type === "brandsArray" && <ObjArray value={row[column.name]} maxWidth={84} /> }
                        { column.type === "stringArray" && <ArrayOfStrings value={row[column.name]} maxWidth={150} /> }
                        { column.type === "languageArray" && <ObjArray prefix="common.components.creative.list_locales." value={row[column.name]} maxWidth={84} /> }
                        { column.type === "productString" && <TranslationText prefix="common.components.creative.list_product." value={row[column.name]} /> }
                        { column.type === "conceptString" && <TranslationText whiteSpace prefix="common.components.creative.list_concept." value={row[column.name]} /> }
                        { column.type === "preview" && <Preview value={row[column.name]} /> }
                        { column.type === "screen" && <Preview value={{ url: row[column.name] }} /> }
                        { column.type === "adjustment" && <Adjustment value={row} /> }
                        { column.type === "toggleWebhookActive" && <Switcher webhook={row} value={row[column.name]} /> }
                        { column.type === "mail" && <EmailLink value={row[column.name]} maxWidth={150}/> }
                        { column.type === "emailWithConfirmation" && <EmailLink value={row[column.name]} confirmed={row.is_email_confirmed} showConfirmedIcon maxWidth={150}/> }
                        { column.type === "tooltipText" && <TooltipText value={row[column.name]} maxWidth={150}/> }
                        { column.type === "finAffId" && <SelectedFinReportRow setSelectedRow={setSelectedRow} data={row} value={row[column.name]} /> }
                        { column.type === "contact" && <Contact type={row[column.name]} value={row.contact_value} /> }
                        { column.type === "trafficUrl" && <Link value={row[column.name]} width={180} /> }
                        { column.type === "manager" && <Manager username={row.manager_username} id={row.manager_id} width={120} maxWidth={120} /> }
                        { column.type === "managerTeam" && <DepartmentTeam team={row.manager_team} /> }
                        { column.type === "partnersCommunicationStatus" && <CommunicationStatus data={row} extraSmall={true} /> }
                        { column.type === "partnersTrafficStatus" && <TrafficStatus data={row} extraSmall={true} /> }
                        { column.type === "partnersCommunicationDate" && <CommunicationDate data={row} /> }
                        { column.type === "condition" && <Condition name={row.condition_name} id={row.condition_id} /> }
                        { column.type === "masterAffiliate" && <MasterAffiliate name={row.master_name} id={row.master_id} width={120} maxWidth={120} /> }
                        { column.type === "shortString" && <TooltipText value={row[column.name]} width={160} maxWidth={160}/> }
                        { column.type === "mediumString" && <TooltipText value={row[column.name]} width={280} maxWidth={280}/> }
                        { column.type === "notes" && <TooltipText value={row[column.name]} width={240} maxWidth={240}/> }
                        { column.type === "shortUsername" && <TooltipText value={row[column.name]} width={120} maxWidth={120}/> }
                        { column.type === "communicationHistoryStatus" && <TranslationText value={row[column.name]} prefix={'common.components.communication_status.'}/> }
                        { column.type === "createdBy" && <Manager username={row.created_by.username} id={row.created_by.id} /> }
                        { column.type === "updatedBy" && <Manager username={row.updated_by.username} id={row.updated_by.id} /> }
                        { column.type === "created_by" && <Manager username={row.created_by.username} id={row.created_by.id} /> }
                        { column.type === "updated_by" && <Manager username={row.updated_by.username} id={row.updated_by.id} /> }
                        { column.type === "invitationManager" && <MasterAffiliate name={row.invitation_manager} id={row.invitation_manager_id} width={120} maxWidth={120} /> }
                        { column.type === "token" && <Text value={row[column.name]} whiteSpace={true} /> }
                        { column.type === "detailFinReportComment" && !total && <DetailFinReportRowComment data={row} /> }
                        { column.type === "creative_group" && <MasterAffiliate name={row.creative_name} id={row.creative_id} width={200} maxWidth={200} />}
                        { column.type === "tracker_group" && <MasterAffiliate name={row.tracker_description} id={row.tracker_id} width={200} maxWidth={200} />}
                        { column.type === "affiliate_group" && <MasterAffiliate name={row.affiliate_name} id={row.affiliate_id} width={200} maxWidth={200} />}
                        { column.type === "tracker_aasm_state" && <TranslationText value={row[column.name]} prefix={'common.components.trackers_aasm_state.'}/> }
                        { column.type === "type_of_transaction" && <TranslationText value={row[column.name]} prefix={'common.components.type_of_transaction.'}/> }
                        { column.type === "creative_type" && <TranslationText value={row[column.name]} prefix={'admin.creatives.form.format.options.'}/> }
                        { column.type === "useAsTrafficBack" && <CheckedField value={row[column.name]} /> }
                        { column.type === "progress" && <Progress value={row[column.name]} /> }
                        { column.type === "registeredByManager" && <MasterAffiliate name={row.registered_by_manager} id={row.registered_by_manager_id} width={120} maxWidth={120} /> }
                        { column.type === "tooltipValue" && <BillingTooltipOverlay value={row[column.name]} tooltipText={row['tooltip_value']} width={100} maxWidth={100}/> }
                        { column.type === "billing_format" && <BillingFormat value={row[column.name]} item={row} column_name={column.name} percentageValue={row['percentage_price']}/> }
                        { column.type === "int_with_zero" && <IntegerWithZero value={row[column.name]}/> }
                        { column.type === "dealsSelectBrand" && <SelectedBrandsDealsRow setSelectedRow={setSelectedRow} data={row} value={row[column.name]} /> }
                        { column.type === "shortUrl" && <Link value={row[column.name]} width={180} /> }
                        { column.type === "geoArrayShort" && <ObjArray prefix="common.components.creative.list_geolocales." value={row[column.name]} maxWidth={200} /> }
                        { column.type === "stringArrayShort" && <ArrayOfStrings value={row[column.name]} maxWidth={180} /> }
                        { column.type === "paymentHistoryDescription" && <PaymentDescription data={row} /> }
                        { column.type === "objArrayMd" && <ObjArray value={row[column.name]} maxWidth={200} /> }
                        { column.type === "billing_string_with_btn" && <TextWithBtn value={row[column.name]} whiteSpace /> }
                        { column.type === "stringWithPrefix" && <TranslationText value={row[column.name]} prefix={column.prefix}/> }
                        { column.type === "paymentMethodValue" && <PaymentWallet data={row[column.name]} /> }
                        { column.type === "AdjustmentReason" && <AdjustmentReason value={row[column.name]} bold={true} prefix="admin.details_finance_report.adjustments.options." /> }
                        { column.type === "AdjustmentCreatedBy" && <AdjustmentReason value={row[column.name]} /> }
                        { column.type === "AdjustmentStatus" && <AdjustmentStatus value={row[column.name]} /> }
                        { column.type === "select_deal_row" && <SelectDealRow value={row[column.name]} data={row} /> }
                        { column.type === "logsItem" && <LogsItem data={row['context']} action={row[column.name]} /> }
                      </>)
                    }
                  </TableCell>
                )
              })
            }
            {!!ActionsComponent &&
              <TableCell
                onClick={handleTooltipClose}
                className={`${styles.tableCell} ${styles.sticky} ${actionsCount === 1 ? 'tableCellCenter' : ''}`}
                align="left"
              >
                {!!ActionsComponent && <ActionsComponent id={row.id} data={{ ...row, disabled: disabled }} />}
              </TableCell>
            }
          </TableRow>
        </Tooltip>
      </ClickAwayListener>
      {
        showDetailOnMobile && (
          <MuiModal
            open={showRowDetail}
            closeOnOutside={false}
            setToggleModal={setShowRowDetail}
          >
            {
              <Details
                setToggleModal={setShowRowDetail}
                row={row}
                columns={columns}
                detailModalTitle={detailModalTitle}
                translationColumnsPrefix={translationColumnsPrefix}
                translationTooltipsPrefix={translationTooltipsPrefix}
              />
            }
          </MuiModal>
        )
      }
    </>
  );
};

export default React.memo(TableRowItem, arePropsEqual);
