//core
import React, {useEffect, useState} from "react";
import { Box } from "@mui/material";

//styles
import { useStyles } from "./styles";
import Field from "./Field";

type Data = {
  [key: string]: string;
}
export interface IDataItem {
  [key: string]: string;
}

export interface ISelectedData {
  name: string;
  data: Data;
  prefix: string;
}

interface ISavedFieldsWrapperProps {
  data: Data;
  exceptions: string[];
  fieldsLabelPrefix: string;
  fieldsPrefix?: IDataItem;
}

const fieldsPrefixDefault: IDataItem = {
  affiliateIds: "",
  managerIds: "",
  departmentIds: "",
  statuses: "admin.details_finance_report.payment_statuses.",
  currencies: "",
  brands: "",
};


const SavedFieldsWrapper: React.FC<ISavedFieldsWrapperProps> = ({
  data,
  exceptions,
  fieldsLabelPrefix,
  fieldsPrefix = fieldsPrefixDefault,
}): JSX.Element => {
  const styles = useStyles();

  const [selectedData, setSelectedData] = useState<ISelectedData[] | null>(null);

  useEffect(() => {
    const newData = Object.keys(data).reduce((acc: ISelectedData[], item): ISelectedData[] => {
      // @ts-ignore
      if (Array.isArray(data[item]) && data[item].length !== 0 || (data[item] != '') && !(data[item] === null) && !exceptions.includes(item)) {
        acc.push({
          name: item,
          // @ts-ignore
          data: Array.isArray(data[item]) ? data[item] : data[item],
          prefix: `${fieldsPrefix.hasOwnProperty(`${item}`) ? fieldsPrefix[item] : ''}`,
        })
      }

      return acc;
    }, []);

    setSelectedData(newData);
  }, [data]);

  return (
    <>
      {!!selectedData && (
        <Box className={styles.block}>
          {selectedData.map((item) => (
            <Field
              key={item.name}
              data={item}
              exceptions={exceptions}
              titlePrefix={fieldsLabelPrefix}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default SavedFieldsWrapper;
